import { Button } from '../Button';
import { Col } from '../Col';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Row } from '../Row';
import { uiClassName } from '../util';
import { Select } from './fields/Select';
import { FormGroup } from './FormGroup';
import { LocaleFormHelper, LocaleFormProps } from './LocaleForm';

export const LocaleFormB = ({ singleColumn, ...otherProps }: LocaleFormProps) => (
  <LocaleFormHelper {...otherProps} singleColumn={singleColumn}>
    {({ fieldsHelper }) => (
      <>
        <Div className={uiClassName({ bs5: 'fw-semi-bold text-uppercase small' })} marginBottom={4} textAlign="center">
          {t('misc.locale.box.title')}
        </Div>
        <Row>
          <Col size={12}>
            <Div marginBottom={!singleColumn ? 4 : undefined}>
              <FormGroup {...fieldsHelper.locale.formGroup} label={undefined}>
                <Select {...fieldsHelper.locale.select} className={uiClassName({ bs5: 'text-capitalize' })} />
              </FormGroup>
            </Div>
          </Col>
        </Row>
        <Div display="grid">
          <Button {...fieldsHelper.buttons.submit} />
        </Div>
      </>
    )}
  </LocaleFormHelper>
);
