import { SendEventRequestApiDto, api } from "@b2x/storefront-api-js-client/src";
import { v4 as uuidv4 } from "uuid";
import { checkCookie, getCookie, removeCookie } from "./utils";

const init = () => {
  /*
  baim_client
    cookie che non scade mai (scade tra 100 anni) e che identifica il client, ovvero il browser.
  baim_session
    cookie con la scadenza di default dei cookie (sessione), serve a differenziare differenti sessioni dello stesso client.
    Se dallo stesso PC mi collego oggi e domani, avrò stesso baim_client, ma 2 diversi baim_session
  baim_user
    cookie generato alla login e cancellato alla logout, identifica un cliente.
    su questo ho diversi dubbi: se faccio login > logout > login il secondo baim_user sarà diverso dal primo... va bene?
  */
  checkCookie("client", uuidv4(), 365);
  checkCookie("session", uuidv4(), undefined);
};

const sendEvent = ({
  event,
  parameters,
  success,
  eventId,
}: Pick<
  SendEventRequestApiDto,
  "event" | "parameters" | "success" | "eventId"
>) => {
  api.events.send({
    event: event,
    parameters: parameters,
    client: getCookie("client"),
    session: getCookie("session"),
    user: getCookie("user"),
    url: window.location.href,
    success: success,
    eventId: eventId,
    userAgent: window.navigator.userAgent,
  });
};

const updateUser = (username?: string) => {
  if (username) {
    checkCookie("user", username, 365);
  } else {
    removeCookie("user");
  }
};

export const baim = {
  init,
  sendEvent,
  updateUser,
};

(window as any).baim = baim;
