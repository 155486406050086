import { ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';

import { Image } from '../Image';
import { uiClassName } from '../util';
import { useStoreLocatorContext } from './StoreLocatorContext';

export interface ShopThumbnailProps {
  shop: ShopApiDto;
}

export const ShopThumbnail = ({ shop }: ShopThumbnailProps) => {
  const { getShopThumbnailSrc } = useStoreLocatorContext();

  return (
    <>
      {getShopThumbnailSrc && (
        <div className={uiClassName({ bs5: 'shop-thumbnail rounded-2' })}>
          <Image fluid src={getShopThumbnailSrc(shop)} style={{ maxWidth: 40 }} width={40} />
        </div>
      )}
    </>
  );
};
