import { FreeShippingThresholdContentType } from '../contentTypes';
import { PropsWithCustomComponent, VariantsController } from '../VariantsController';
import { CartPageA } from './CartPageA';

export interface CartPageProps extends FreeShippingThresholdContentType {
  summaryTopInfo?: React.ReactElement;
}

export type CartPageVariants = 'A';

const CartPageController = (props: PropsWithCustomComponent<CartPageProps>) => (
  <VariantsController<CartPageProps, CartPageVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: CartPageA },
      name: 'CartPage',
    }}
  />
);
export { CartPageController as CartPage };
