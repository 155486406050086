import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { SliderContentCSection } from '../contentTypes';
import { Slider } from './Slider';

export interface SliderContentCProps extends SliderContentCSection {}

export const SliderContentC = ({ itemList, subtitle, title }: SliderContentCProps) => {
  return (
    <div className="steps-category-slider">
      <div className="product-category-slider my-5">
        {(title || subtitle) && (
          <Container>
            <b2x.Row className="mb-4">
              <b2x.Col className="text-center" size={12}>
                {title && <h2 className="text-blue mb-3">{b2x.formatHtml(title)}</h2>}
                {subtitle && <p className="mb-3">{b2x.formatHtml(subtitle)}</p>}
              </b2x.Col>
            </b2x.Row>
          </Container>
        )}
        {itemList && (
          <div className="container-xxl pe-0 px-lg-3">
            <b2x.EqualHeight>
              <Slider
                loop
                responsive={{
                  lg: {
                    slidesPerView: 4,
                  },
                  md: {
                    slidesPerView: 2.15,
                  },
                }}
                slidesPerView={1.15}
                spaceBetween={30}
              >
                {itemList.map((item, index) => (
                  <SwiperSlide key={item.contentSectionId}>
                    <div className="category-slide position-relative text-center">
                      <b2x.ImageFromContent {...item.img} className="mb-3 w-100" fluid />
                      <div className="px-3">
                        {item.category && (
                          <b2x.EqualHeightElement name="sliderCCategory">
                            <p className="small text-uppercase text-blue">{item.category}</p>
                          </b2x.EqualHeightElement>
                        )}
                        {item.title && (
                          <b2x.EqualHeightElement name="sliderCTitle">
                            <h5 className="mb-3">{item.title}</h5>
                          </b2x.EqualHeightElement>
                        )}
                        {item.description && <p>{b2x.formatHtml(item.description)}</p>}
                        {item.cta && (
                          <b2x.DeprecatedCta className="fw-bold text-decoration-none" cta={item.cta}>
                            {item.cta.label}
                          </b2x.DeprecatedCta>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Slider>
            </b2x.EqualHeight>
          </div>
        )}
      </div>
    </div>
  );
};
