import { Container } from '../Container';
import { renderUI } from '../util';

export interface SpacingProps {}

export const Spacing = (props: SpacingProps) => {
  return renderUI({
    bs5: (
      <Container>
        <div className="d-flex flex-column flex-sm-row gap-4">
          {[0, 1, 2, 3, 4, 5].map((size) => (
            <div className={`bg-primary flex-grow-1 py-3 px-${size}`} key={size}>
              <div className="bg-white text-center py-2">px-{size}</div>
            </div>
          ))}
        </div>
      </Container>
    ),
  });
};
