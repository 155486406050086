import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import React from 'react';

import { Button } from './Button';
import { appConfig } from './config';
import { EqualHeightElement } from './EqualHeightElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { ImageFormat } from './interfaces';
import { Link } from './router/Link';
import { usePrice } from './usePrice';
import { formatCurrency, formatHtml, renderUI } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';
import { WishlistButtonHelper } from './WishlistButtonHelper';

export interface ProductTileBasicInfoProps {
  handleLinkClick(): void;
  imageFormat?: ImageFormat | undefined;
  product: ProductApiDto;
  productsPerRow?: number;
  sku?: SkuApiDto;
}

const ProductTileBasicInfo = ({
  handleLinkClick,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: ProductTileBasicInfoProps) => {
  const { crossedOutPrice, discountPercentageDecimal, isPriceInRange, price } = usePrice(product, sku?.id);

  return renderUI({
    bs5: (
      <div className="product-tile-basic-info">
        <div className="product-tile-image-container">
          <Link onClick={handleLinkClick} style={{ display: 'block' }} to={product.url}>
            <Image
              alt={product.name}
              aspectRatio={appConfig.productImageAspectRatio}
              fluid
              format={imageFormat}
              src={product.image?.src}
            />
          </Link>
          {appConfig.enableWishlist && (
            <WishlistButtonHelper product={product} sku={sku}>
              {({ handleWishlistButtonClick, inWishlist }) => (
                <Button
                  className=""
                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 30 }}
                  onClick={handleWishlistButtonClick}
                  size="large"
                  type="button"
                  variant="blank"
                />
              )}
            </WishlistButtonHelper>
          )}
          {discountPercentageDecimal && <div className="discount-percentage">{`-${discountPercentageDecimal}%`}</div>}
        </div>
        <EqualHeightElement name={`product-title-row-${productsPerRow}`}>
          <h6>
            <Link className="text-decoration-none text-black" onClick={handleLinkClick} to={product.url}>
              {formatHtml(product.name)}
            </Link>
          </h6>
        </EqualHeightElement>
        <div className="product-tile-price">
          {crossedOutPrice && (
            <span className="text-decoration-line-through me-2">
              {t(isPriceInRange ? 'misc.fromPrice' : 'misc.price', { value: formatCurrency(crossedOutPrice) })}
            </span>
          )}
          <strong className={classnames({ 'text-promo': crossedOutPrice })}>
            {t(isPriceInRange ? 'misc.fromPrice' : 'misc.price', { value: formatCurrency(price) })}
          </strong>
        </div>
      </div>
    ),
  });
};

export type ProductTileBasicInfoVariants = '';

const ProductTileBasicInfoController = React.memo((props: PropsWithCustomComponent<ProductTileBasicInfoProps>) => (
  <VariantsController<ProductTileBasicInfoProps, ProductTileBasicInfoVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ProductTileBasicInfo,
      name: 'ProductTileBasicInfo',
    }}
  />
));
ProductTileBasicInfoController.displayName = 'ProductTileBasicInfoController';

export { ProductTileBasicInfoController as ProductTileBasicInfo };
