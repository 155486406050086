export const en = {
  form: {
    addToCartForm: {
      skus: {
        label: 'Size',
        placeholder: 'Select a size',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telephone' },
    },
  },
  misc: {
    backToHomeBlog: 'Back to AmioAgio Magazine home',
    doubleOptionAccess: 'Log in or register now',
    moreArticles: 'More articles',
    noItemFound: 'No item found',
    storeName: 'Hartmann',
  },
  product: {
    promo: 'Promo',
  },
  welcome: 'Welcome to Hartmann',
};
