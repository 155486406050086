import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { SliderContentBSection } from '../contentTypes';
import { Slider } from './Slider';

export interface SliderContentBProps extends SliderContentBSection {}

export const SliderContentB = ({ articleList, heading }: SliderContentBProps) => {
  return (
    <div className="slider-content-b my-5">
      <div className="container-xxl">
        {(heading?.title || heading?.imageTitle) && (
          <div className="row">
            <div className="col-12 mb-3 mb-lg-5">
              <div className="hstack gap-3 align-self-center justify-content-center ">
                {heading.title && <h3 className="h2 text-blue mb-0">{b2x.formatHtml(heading.title)}</h3>}
                {heading.imageTitle && <b2x.ImageFromContent {...heading.imageTitle} fluid />}
              </div>
              {heading.content && <div className="text-center mt-2">{b2x.formatHtml(heading.content)}</div>}
            </div>
          </div>
        )}
        {articleList && (
          <div className="row">
            <div className="col-12 pe-0 pe-xl-3">
              <b2x.EqualHeight>
                <Slider
                  loop
                  responsive={{
                    lg: {
                      slidesPerView: 3.2,
                    },
                    md: {
                      slidesPerView: 2.2,
                    },
                    xl: {
                      slidesPerView: 4,
                    },
                  }}
                  slidesPerView={1.2}
                  spaceBetween={20}
                >
                  {articleList.map((item, index) => (
                    <SwiperSlide key={item.contentSectionId}>
                      <div className="slide-item text-center">
                        <b2x.EqualHeightElement name="image">
                          {item.cta ? (
                            <b2x.DeprecatedCta cta={item.cta}>
                              <b2x.ImageFromContent {...item.img} className="mb-3 rounded" fluid />
                            </b2x.DeprecatedCta>
                          ) : (
                            <b2x.ImageFromContent {...item.img} className="mb-3 rounded" fluid />
                          )}
                        </b2x.EqualHeightElement>
                        <div className="content swiper-no-swiping">
                          {item.category && (
                            <div className="category small text-uppercase mb-2 text-blue">
                              {b2x.formatHtml(item.category)}
                            </div>
                          )}
                          {item.title && (
                            <b2x.EqualHeightElement name="title">
                              <h6 className="fw-bold title mb-2">{b2x.formatHtml(item.title)}</h6>
                            </b2x.EqualHeightElement>
                          )}

                          {item.content && (
                            <b2x.EqualHeightElement name="content">
                              <div>{b2x.formatHtml(item.content)}</div>
                            </b2x.EqualHeightElement>
                          )}

                          {item.cta && item.cta.label && (
                            <div className="py-2">
                              <b2x.DeprecatedCta
                                className={classnames('px-0 small text-decoration-none')}
                                cta={item.cta}
                              >
                                {b2x.formatHtml(item.cta.label)}
                                <b2x.Icon className="ps-1" name="small-arrow-right" size={16} />
                              </b2x.DeprecatedCta>
                            </div>
                          )}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Slider>
              </b2x.EqualHeight>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
