import {
  AddAssembledToCartRequestApiDto,
  AddCouponToCartRequestApiDto,
  AddToCartRequestApiDto,
  // eslint-disable-next-line no-restricted-imports
  api,
  CartSkuApiDto,
  ChangeCartSkuRequestApiDto,
  GiftCardRequestApiDto,
  MoveCartSkuToWishlistRequestApiDto,
  ProductApiDto,
  SetAddressesToCartRequestApiDto,
  SetAddressToCartRequestApiDto,
  SetEmailOnCartRequestApiDto,
  SetPaymentMethodOnCartRequestApiDto,
  SetShippingProfileOnCartRequestApiDto,
  SkuApiDto,
} from '@b2x/storefront-api-js-client/src';
import { GetCartOptions } from '@b2x/storefront-api-js-client/src/cart';
import React from 'react';

import { analytics } from '../analytics/analytics';
import { useAppStaticContext } from '../AppContext';
import { appConfig } from '../config';
import { ApiRequestConfig, useApiRequest } from './useApiRequest';
import { useCustomerApi } from './useCustomerApi';
import { useSessionApi } from './useSessionApi';

export const useCartApi = () => {
  const { apiRequest } = useApiRequest();
  const { getSession } = useSessionApi();
  const { setSession } = useAppStaticContext();

  const getCart = React.useCallback(
    (options?: GetCartOptions, config?: ApiRequestConfig) =>
      apiRequest(api.cart.get(options), { silent: false, ...config }),
    [apiRequest]
  );

  const updateSessionCart = React.useCallback(
    (config: ApiRequestConfig) =>
      getSession(
        {
          ...appConfig.api?.sessionOptions,
          populate: {
            cart: appConfig.api?.sessionOptions?.populate?.cart,
          },
        },
        config
      ).then((response) => {
        setSession((prevState) => (prevState ? { ...prevState, cart: response.data.cart } : undefined));
        return response;
      }),
    [getSession, setSession]
  );

  const addSkus = React.useCallback(
    (data: AddToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.add(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const addSku = React.useCallback(
    async (
      {
        giftCard,
        product,
        quantity,
        sku,
      }: { giftCard?: GiftCardRequestApiDto; product: ProductApiDto; quantity: number; sku: SkuApiDto },
      config?: ApiRequestConfig
    ) => {
      if (sku.price === undefined) {
        throw new Error('API - addSku - missing sku.price');
      }
      const response = await apiRequest(
        api.cart.add({
          skus: [
            {
              giftCard: giftCard,
              id: sku.id,
              price: sku.price.value,
              quantity: quantity,
            },
          ],
        }),
        { silent: false, ...config }
      );
      await updateSessionCart({ silent: false });
      return response;
    },
    [apiRequest, updateSessionCart]
  );

  const addAssembledSku = React.useCallback(
    async (
       data: AddAssembledToCartRequestApiDto,
      config?: ApiRequestConfig
    ) => {
      // if (sku.price === undefined) {
      //   throw new Error('API - addSku - missing sku.price');
      // }
      const response = await apiRequest(
        api.cart.addAssembled(data),
        { silent: false, ...config }
      );
      await updateSessionCart({ silent: false });
      return response;
    },
    [apiRequest, updateSessionCart]
  );

  const changeSkuQuantity = React.useCallback(
    (data: ChangeCartSkuRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.changeQuantity(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const removeSku = React.useCallback(
    ({ cartSku }: { cartSku: CartSkuApiDto }, config?: ApiRequestConfig) =>
      apiRequest(api.cart.remove(cartSku.id), { silent: false, ...config }).then((response) => {
        analytics.events.removeFromCart(response.eventId, cartSku);
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const getCoupons = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.cart.getCoupons(), { ...config }),
    [apiRequest]
  );

  const applyCoupon = React.useCallback(
    (data: AddCouponToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.applyCoupon(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const removeCoupon = React.useCallback(
    (config?: ApiRequestConfig) =>
      apiRequest(api.cart.removeCoupon(), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const getShippingProfiles = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.cart.getShippingProfiles(), { ...config }),
    [apiRequest]
  );

  const setShippingProfile = React.useCallback(
    (data: SetShippingProfileOnCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setShippingProfile(data), { silent: false, ...config }).then((response) =>
        updateSessionCart({ silent: false }).then(() => {
          return response;
        })
      ),
    [apiRequest, updateSessionCart]
  );

  const getPaymentMethods = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.cart.getPaymentMethods(), { ...config }),
    [apiRequest]
  );

  const setPaymentMethod = React.useCallback(
    (data: SetPaymentMethodOnCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setPaymentMethod(data), { silent: false, ...config }).then((response) =>
        updateSessionCart({ silent: false }).then(() => {
          return response;
        })
      ),
    [apiRequest, updateSessionCart]
  );

  const setEmail = React.useCallback(
    (data: SetEmailOnCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setEmail(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setDefaultCustomerData = React.useCallback(
    (config?: ApiRequestConfig) =>
      apiRequest(api.cart.setDefaultCustomerData(), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setShippingAddress = React.useCallback(
    (data: SetAddressToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setShippingAddress(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setBillingAddress = React.useCallback(
    (data: SetAddressToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setBillingAddress(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const setAddresses = React.useCallback(
    (data: SetAddressesToCartRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.setAddresses(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const { updateSessionWishlist } = useCustomerApi();

  const moveToWishlist = React.useCallback(
    (data: MoveCartSkuToWishlistRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.cart.moveToWishlist(data), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        updateSessionWishlist();
        return response;
      }),
    [apiRequest, updateSessionCart, updateSessionWishlist]
  );

  const applyGiftCard = React.useCallback(
    (code: string, config?: ApiRequestConfig) =>
      apiRequest(api.cart.applyGiftCard(code), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  const removeGiftCard = React.useCallback(
    (code: string, config?: ApiRequestConfig) =>
      apiRequest(api.cart.removeGiftCard(code), { silent: false, ...config }).then((response) => {
        updateSessionCart({ silent: false });
        return response;
      }),
    [apiRequest, updateSessionCart]
  );

  return {
    addAssembledSku,
    addSku,
    addSkus,
    applyCoupon,
    applyGiftCard,
    changeSkuQuantity,
    getCart,
    getCoupons,
    getPaymentMethods,
    getShippingProfiles,
    moveToWishlist,
    removeCoupon,
    removeGiftCard,
    removeSku,
    setAddresses,
    setBillingAddress,
    setDefaultCustomerData,
    setEmail,
    setPaymentMethod,
    setShippingAddress,
    setShippingProfile,
    updateSessionCart,
  };
};
