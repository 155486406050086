import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { HeaderBannerContentSection } from './contentTypes';

export interface HeaderLandingProps extends HeaderBannerContentSection {
  className?: string;
  textColSize?: b2x.ResponsiveColSize;
}

export const HeaderLanding = ({
  backgroundColor,
  className,
  colorPretitle,
  colorSubtitle,
  colorTitle,
  img,
  mobileCopyPosition,
  pretitle,
  subtitle,
  textAlignment,
  textColSize,
  title,
}: HeaderLandingProps) => {
  return (
    <div
      className={classnames(
        'position-relative d-flex flex-wrap justify-content-center ',
        className,
        `bg-${backgroundColor}`
      )}
    >
      {mobileCopyPosition === 'bottom' && (
        <div className={classnames('container-xxl d-block d-lg-none text-center my-3 order-2')}>
          <div className="d-flex justify-content-center flex-column">
            {title && <h1 className={classnames(`text-${colorTitle}`)}>{b2x.formatHtml(title)}</h1>}
            {subtitle && (
              <h4 className={classnames(`text-${colorSubtitle}`, 'fw-normal')}>{b2x.formatHtml(subtitle)}</h4>
            )}
          </div>
        </div>
      )}
      {img && <b2x.ImageFromContent {...img} className="w-100 order-1" fluid />}
      {mobileCopyPosition !== 'bottom' ? (
        <div
          className={classnames(
            'w-100',
            img?.src?.xs ? 'position-absolute top-50 start-50 translate-middle w-100' : 'py-5'
          )}
        >
          <div className="container-xxl">
            <div
              className={classnames('d-flex justify-content-center flex-column', {
                'text-center': textAlignment === 'center',
                'text-end pe-3': textAlignment === 'right',
                'text-start ps-3': textAlignment === 'left',
              })}
            >
              <b2x.Row className={classnames({ 'justify-content-center': textAlignment === 'center' })}>
                <b2x.Col size={textColSize}>
                  {pretitle && <h6 className={classnames(`text-${colorPretitle}`)}>{b2x.formatHtml(pretitle)}</h6>}
                  {title && <h1 className={classnames(`text-${colorTitle}`)}>{b2x.formatHtml(title)}</h1>}
                  {subtitle && (
                    <h4 className={classnames(`text-${colorSubtitle}`, 'fw-normal')}>{b2x.formatHtml(subtitle)}</h4>
                  )}
                </b2x.Col>
              </b2x.Row>
            </div>
          </div>
        </div>
      ) : (
        <div className={classnames('position-absolute top-50 start-50 translate-middle w-100 d-none d-lg-block')}>
          <div className="container-xxl">
            <b2x.Row className={classnames({ 'justify-content-center': textAlignment === 'center' })}>
              <b2x.Col className={classnames(`text-${textAlignment}`)} size={textColSize}>
                {pretitle && <h6 className={classnames(`text-${colorPretitle}`)}>{b2x.formatHtml(pretitle)}</h6>}
                {title && <h1 className={classnames(`text-${colorTitle}`)}>{b2x.formatHtml(title)}</h1>}
                {subtitle && (
                  <h4 className={classnames(`text-${colorSubtitle}`, 'fw-normal')}>{b2x.formatHtml(subtitle)}</h4>
                )}
              </b2x.Col>
            </b2x.Row>
          </div>
        </div>
      )}
    </div>
  );
};

HeaderLanding.defaultProps = {
  textColSize: 12,
};
