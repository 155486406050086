import { Button } from '../Button';
import { Col } from '../Col';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Link } from '../router/Link';
import { Row } from '../Row';
import { PasswordInput, TextInput } from './fields/Input';
import { FormGroup } from './FormGroup';
import { LoginFormHelper, LoginFormProps } from './LoginForm';

export const LoginFormA = ({ ...otherProps }: LoginFormProps) => (
  <LoginFormHelper {...otherProps}>
    {({ closeModal, fieldsHelper, forgotPasswordPagePath }) => (
      <>
        <Div marginBottom={4}>
          <FormGroup {...fieldsHelper.username.formGroup} label={fieldsHelper.username.formGroup.label}>
            <TextInput {...fieldsHelper.username.textInput} placeholder={undefined} />
          </FormGroup>
          <FormGroup {...fieldsHelper.password.formGroup} label={fieldsHelper.password.formGroup.label}>
            <PasswordInput {...fieldsHelper.password.passwordInput} placeholder={undefined} />
          </FormGroup>
        </Div>
        {fieldsHelper.buttons.cancel ? (
          <Row>
            <Col size={6}>
              <Div display="grid">
                <Button {...fieldsHelper.buttons.cancel} variant="outline-primary" />
              </Div>
            </Col>
            <Col size={6}>
              <Div display="grid">
                <Button {...fieldsHelper.buttons.submit} />
              </Div>
            </Col>
          </Row>
        ) : (
          <Div display="grid">
            <Button {...fieldsHelper.buttons.submit} />
          </Div>
        )}
        <Div marginTop={3}>
          <small className="text-secondary fw-light">
            <Link className="text-muted" onClick={closeModal} to={forgotPasswordPagePath}>
              {t('account.passwordForgotten')}
            </Link>
          </small>
        </Div>
      </>
    )}
  </LoginFormHelper>
);
