import { AddressApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { Alert } from '../../../Alert';
//import { useAppContext } from '../../../AppContext';
import { useCheckoutContext } from '../../../CheckoutContext';
import { CartAddressesForm } from '../../../form/CartAddressesForm';
import { CartAddressFromAddressBookForm } from '../../../form/CartAddressFromAddressBookForm';
import { CustomerAddressForm } from '../../../form/CustomerAddressForm';
import { Div } from '../../../HTMLElement';
import { t } from '../../../i18n/i18n';
import { InvoiceSummary } from '../../../InvoiceSummary';
import { formatAddress, formatHtml, renderUI } from '../../../util';
import { CheckoutAddressStepProps } from '../CheckoutAddressStep';

export const CheckoutAddressStepA = ({ additionalContent, content, onEdit, type }: CheckoutAddressStepProps) => {
  const { addressBook, cart, sameAddresses } = useCheckoutContext();

  const [showAddressForm, setShowAddressForm] = React.useState<boolean>(false);
  const [addressFormData, setAddressFormData] = React.useState<AddressApiDto>();

  const handleNewAddressButtonClick = React.useCallback(() => {
    setAddressFormData(undefined);
    setShowAddressForm(true);
  }, []);

  const handleEditAddressButtonClick = React.useCallback((address: AddressApiDto) => {
    setAddressFormData(address);
    setShowAddressForm(true);
  }, []);

  //const { session } = useAppContext();

  return renderUI({
    bs5: (
      <>
        {cart && (
          <>
            {onEdit ? (
              <>
                {content?.onEdit?.alerts?.map((alert) => (
                  <Alert
                    className="extra-small py-2"
                    key={alert.contentSectionId}
                    {...alert}
                    variant={
                      alert.type === 'info'
                        ? 'info'
                        : alert.type === 'success'
                        ? 'success'
                        : alert.type === 'warning'
                        ? 'warning'
                        : alert.type === 'error'
                        ? 'error'
                        : undefined
                    }
                  >
                    {formatHtml(alert.message)}
                  </Alert>
                ))}
                {additionalContent?.onEdit?.alerts?.map((alert, index) => (
                  <Alert
                    className="extra-small py-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`checkout-address-${type}-alert-on-edit-${index}`}
                    {...alert}
                  />
                ))}
                {cart.guest ? (
                  <CartAddressesForm
                    billingAddress={cart.billingAddress}
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    copyType={type === 'shipping' ? 'to' : type === 'billing' ? 'from' : undefined}
                    shippingAddress={cart.shippingAddress}
                    type={type}
                  />
                ) : !showAddressForm ? (
                  addressBook && (
                    <CartAddressFromAddressBookForm
                      addresses={addressBook}
                      currentAddress={
                        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                        type === 'shipping'
                          ? cart.shippingAddress
                          : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                          type === 'billing'
                          ? cart.billingAddress
                          : undefined
                      }
                      onEditAddressButtonClick={handleEditAddressButtonClick}
                      onNewAddressButtonClick={handleNewAddressButtonClick}
                      type={type}
                    />
                  )
                ) : (
                  <>
                    <p className="fw-bold">
                      {addressFormData ? t('form.addressForm.editAddressTitle') : t('form.addressForm.newAddressTitle')}
                    </p>
                    <CustomerAddressForm
                      address={addressFormData}
                      duringCheckout
                      // eslint-disable-next-line react/jsx-no-bind
                      onCancel={() => {
                        setAddressFormData(undefined);
                        setShowAddressForm(false);
                      }}
                      // eslint-disable-next-line react/jsx-no-bind
                      onSuccess={() => {
                        setShowAddressForm(false);
                      }}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                {content?.onClosed?.alerts?.map((alert) => (
                  <Alert
                    className="extra-small py-2"
                    key={alert.contentSectionId}
                    {...alert}
                    variant={
                      alert.type === 'info'
                        ? 'info'
                        : alert.type === 'success'
                        ? 'success'
                        : alert.type === 'warning'
                        ? 'warning'
                        : alert.type === 'error'
                        ? 'error'
                        : undefined
                    }
                  >
                    {formatHtml(alert.message)}
                  </Alert>
                ))}
                {additionalContent?.onClosed?.alerts?.map((alert, index) => (
                  <Alert
                    className="extra-small py-2"
                    // eslint-disable-next-line react/no-array-index-key
                    key={`checkout-address-${type}-alert-on-closed-${index}`}
                    {...alert}
                  />
                ))}
                {type === 'shipping' ? (
                  cart.shippingAddress && formatAddress(cart.shippingAddress, { withCountry: true, withName: true })
                ) : // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                type === 'billing' ? (
                  <>
                    {sameAddresses ? (
                      t('checkout.step.billingAddress.subTitle')
                    ) : (
                      <div>
                        {cart.billingAddress &&
                          formatAddress(cart.billingAddress, { withCountry: true, withName: true })}
                      </div>
                    )}
                    <Div marginTop={2}>
                      {cart.invoice && cart.billingAddress ? (
                        <div>
                          <div>{t('checkout.invoice.requested')}</div>
                          <Div marginTop={2}>
                            <InvoiceSummary address={cart.billingAddress} />
                          </Div>
                        </div>
                      ) : (
                        <div>{t('checkout.invoice.notRequested')}</div>
                      )}
                    </Div>
                  </>
                ) : undefined}
              </>
            )}
          </>
        )}
      </>
    ),
  });
};
