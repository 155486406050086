import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

export interface SectionProps {
  children?: React.ReactNode;
  className?: string;
  content?: string;
  noPaddingX?: boolean;
  textAlignment?: b2x.ResponsiveTextAlign;
  title?: string;
}
export const Section = ({
  children,
  className,
  content,
  noPaddingX,
  textAlignment = 'center',
  title,
}: SectionProps) => {
  return (
    <section className={classNames('my-5', className)}>
      <div className={classNames('container-xxl', { 'px-0': noPaddingX })}>
        {(title || content) && (
          <div className={classNames('mb-5', b2x.buildTextAlignClassNames(textAlignment), { 'px-3': noPaddingX })}>
            {title && <h2 className="text-blue mb-3">{b2x.formatHtml(title)}</h2>}
            {content && <div>{b2x.formatHtml(content)}</div>}
          </div>
        )}
        {children && <div className="section-content">{children}</div>}
      </div>
    </section>
  );
};
