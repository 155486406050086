import classnames from 'classnames';

import { Button, ButtonSize } from '../Button';
import { Container } from '../Container';
import { renderUI } from '../util';

export interface ButtonsProps {
  backgrounds: Array<string>;
  buttonClassName?: string;
  disableOutline?: boolean;
  iconsOrLabels: Array<
    'onlyLabel' | 'onlyIcon' | 'IconLeftAndLabel' | 'IconRightAndLabel' | 'IconLeftAndRightAndLabel'
  >;
  sizes: Array<ButtonSize>;
  variants: Array<string>;
}

export const Buttons = ({
  backgrounds,
  buttonClassName,
  disableOutline,
  iconsOrLabels,
  sizes,
  variants,
}: ButtonsProps) => {
  return renderUI({
    bs5: (
      <Container>
        {iconsOrLabels.map((iconOrLabel) =>
          backgrounds.map((background) => (
            <div className={`p-2 bg-${background}`} key={`${iconOrLabel}-${background}`}>
              <div className="mb-n2">
                {['', 'outline-'].map(
                  (prefix) =>
                    (prefix !== 'outline-' || !disableOutline) && (
                      <div key={`${iconOrLabel}-${background}-${prefix}`}>
                        {sizes.map((size) => (
                          <div className="" key={`${iconOrLabel}-${background}-${prefix}-${size}`}>
                            {variants.map((variant) => (
                              <Button
                                className={classnames('me-2 mb-2', buttonClassName)}
                                iconEnd={
                                  iconOrLabel === 'IconRightAndLabel' ||
                                  iconOrLabel === 'IconLeftAndRightAndLabel' ||
                                  iconOrLabel === 'onlyIcon'
                                    ? { name: 'cart' }
                                    : undefined
                                }
                                iconStart={
                                  iconOrLabel === 'IconLeftAndLabel' || iconOrLabel === 'IconLeftAndRightAndLabel'
                                    ? { name: 'cart' }
                                    : undefined
                                }
                                key={`${iconOrLabel}-${background}-${prefix}-${size}-${variant}`}
                                label={iconOrLabel !== 'onlyIcon' ? variant : undefined}
                                size={size}
                                title={`${prefix}${variant}`}
                                variant={`${prefix}${variant}`}
                              />
                            ))}
                          </div>
                        ))}
                      </div>
                    )
                )}
              </div>
            </div>
          ))
        )}
      </Container>
    ),
  });
};
