import { GiftCardApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCartApi } from '../api/useCartApi';
import { useGiftCardsApi } from '../api/useGiftCardsApi';
import { Button } from '../Button';
import { Col } from '../Col';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { Row } from '../Row';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { GiftCardFormA } from './GiftCardFormA';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface GiftCardFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  onBalanceCheckSuccess?(giftCard: GiftCardApiDto): void;
}

interface FormValues {
  code: formikString;
  submitter: 'balanceCheck' | undefined;
}

export type GiftCardFormValues = FormValues;

type ValidationSchema = {
  code: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    balanceCheck: FormButtonProps;
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  code: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const GiftCardFormHelper = ({
  children,
  className,
  initialValues,
  onBalanceCheckSuccess,
  onCancel,
  onSuccess,
  ...otherProps
}: GiftCardFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      code: getInitialString(),
      submitter: undefined,
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      code: yup.string().required(),
    }),
    []
  );

  const { getGiftCardByCode } = useGiftCardsApi();
  const { applyGiftCard } = useCartApi();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      if (values.submitter === 'balanceCheck') {
        return getGiftCardByCode(values.code, { populate: { balance: { movements: true } } }).then(
          (response) => onBalanceCheckSuccess && onBalanceCheckSuccess(response.data)
        );
      } else {
        return applyGiftCard(values.code).then(() => {
          formikHelpers.resetForm();
        });
      }
    },
    [applyGiftCard, getGiftCardByCode, onBalanceCheckSuccess]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('gift-card-form', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            balanceCheck: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.giftCardForm.buttons.balanceCheck.label'),
              onClick: () => {
                formik.setFieldValue('submitter', 'balanceCheck');
                formik.submitForm();
              },
              // onClick: handleBalanceCheckButtonClick,
              type: 'button',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
            cancel: onCancel
              ? {
                  label: t('form.giftCardForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.giftCardForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.giftCardForm.buttons.submit.label'),
              onClick: () => {
                formik.setFieldValue('submitter', undefined);
              },
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          code: {
            formGroup: { label: t('form.giftCardForm.code.label'), names: ['code'] },
            textInput: {
              name: 'code',
              placeholder: t('form.giftCardForm.code.placeholder'),
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <Row>
            <Col size={12}>
              <FormGroup {...fieldsHelper.code.formGroup}>
                <TextInput {...fieldsHelper.code.textInput} />
              </FormGroup>
              <Button {...fieldsHelper.buttons.balanceCheck} />
              <Button {...fieldsHelper.buttons.submit} />
            </Col>
          </Row>
        );
      }}
    </HelpedForm>
  );
};

export type GiftCardFormVariants = 'A';

const GiftCardFormController = (props: PropsWithCustomComponentWithoutChildren<GiftCardFormProps>) => (
  <VariantsController<GiftCardFormProps, GiftCardFormVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: GiftCardFormA },
      defaultComponent: GiftCardFormHelper,
      name: 'GiftCardForm',
    }}
  />
);
export { GiftCardFormController as GiftCardForm };
