import classnames from 'classnames';
import React from 'react';

import { useSessionApi } from '../api/useSessionApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { Icon } from '../Icon';
import { PageWithSubpageContext } from '../PageWithSubpageContext';
import { PageWithSubpageHeader } from '../PageWithSubpageHeader';
import { NavLink, Route, Routes } from '../router/router';
import { useMenu } from '../useMenu';
import { usePageByCode } from '../usePageByCode';
import { renderUI } from '../util';
import { AccountPageProps } from './AccountPage';
import { AccountCouponsSubpage } from './accountSubpages/AccountCouponsSubpage';
import { AccountCreditCardsSubpage } from './accountSubpages/AccountCreditCardsSubpage';
import { AccountHelpSubpage } from './accountSubpages/AccountHelpSubpage';
import { AccountInfoSubpage } from './accountSubpages/AccountInfoSubpage';
import { AccountLoyaltySubpage } from './accountSubpages/AccountLoyaltySubpage';
import { AccountOrdersSubpage } from './accountSubpages/AccountOrdersSubpage';
import { AccountPrivacySubpage } from './accountSubpages/AccountPrivacySubpage';
import { AccountTaxBenefitsSubpage } from './accountSubpages/AccountTaxBenefitsSubpage';
import { AccountWishlistSubpage } from './accountSubpages/AccountWishlistSubpage';

export const AccountPageA = ({ enableReceiverColumn }: AccountPageProps) => {
  const { session } = useAppContext();
  const { logout } = useSessionApi();

  const accountMenu = useMenu('MENU_ACCOUNT');
  const page = usePageByCode('SITE_CCN_AREA', { populate: { children: true } });

  const menuItems = React.useMemo<Array<AccountMenuItem>>(() => {
    const result: Array<AccountMenuItem> = accountMenu
      ? accountMenu.children[0].children.map((child) => ({
          //icon: child.iconName && isIconName(child.iconName) ? child.iconName : 'plus',
          icon: child.iconName ? child.iconName : 'plus',
          label: child.label ?? '',
          link: child.link ?? '',
          scrollTop: false,
        }))
      : [];

    result.push({
      icon: appConfig.icons?.logout,
      label: t('account.logout'),
      onClick: () => {
        logout();
      },
      scrollTop: false,
    });
    return result;
  }, [accountMenu, logout]);

  return (
    <>
      {accountMenu && session?.customer?.name && (
        <PageWithSubpageContext
          pageSubtitle={t('account.welcomeMessage', { name: session.customer.name })}
          pageTitle={accountMenu.label}
        >
          <PageWithSubpageHeader />
          {renderUI({
            bs5: (
              <div className="container-xxl">
                <div className="row">
                  <div className="d-none d-lg-block col-lg-3">
                    <AccountMenu items={menuItems} />
                  </div>
                  <div className="col-lg-9">
                    {/* <Outlet /> */}
                    <Routes>
                      {page?.children?.map((child) => (
                        <Route
                          element={
                            child.code === 'SITE_CCN_COUPONS' ? (
                              <AccountCouponsSubpage />
                            ) : child.code === 'SITE_CCN_CREDIT_CARDS' ? (
                              <AccountCreditCardsSubpage />
                            ) : child.code === 'SITE_CCN_HELP' ? (
                              <AccountHelpSubpage />
                            ) : child.code === 'SITE_CCN_INFO' ? (
                              <AccountInfoSubpage />
                            ) : child.code === 'SITE_CCN_ORDERS' ? (
                              <AccountOrdersSubpage enableReceiverColumn={enableReceiverColumn} />
                            ) : child.code === 'SITE_CCN_PRIVACY' ? (
                              <AccountPrivacySubpage />
                            ) : child.code === 'SITE_CCN_WISHLIST' ? (
                              <AccountWishlistSubpage />
                            ) : child.code === 'SITE_CCN_TAX_BENEFITS' ? (
                              <AccountTaxBenefitsSubpage />
                            ) : child.code === 'SITE_CCN_LOYALTY' ? (
                              <AccountLoyaltySubpage />
                            ) : (
                              <></>
                            )
                          }
                          key={child.code}
                          path={child.path}
                        />
                      ))}
                    </Routes>
                  </div>
                </div>
              </div>
            ),
          })}
        </PageWithSubpageContext>
      )}
    </>
  );
};

interface AccountMenuProps {
  items: Array<AccountMenuItem>;
}

interface AccountMenuItem {
  icon?: string;
  label: string;
  link?: string;
  onClick?(): void;
  scrollTop?: boolean;
}

const AccountMenu = ({ items }: AccountMenuProps) => {
  return renderUI({
    bs5: (
      <div className="menu-account">
        <div className="list-group">
          {items.map(({ icon, label, link, onClick, scrollTop }) =>
            link ? (
              <NavLink
                className={classnames(
                  'text-reset text-decoration-none border-0 small list-group-item list-group-item-action hstack gap-5 py-3 px-3 px-xl-5 mb-1'
                )}
                key={label}
                scrollTop={scrollTop}
                silent
                to={link}
              >
                {icon && <Icon name={icon} size={25} />}
                {label}
              </NavLink>
            ) : (
              <Button
                className={classnames(
                  'text-reset text-decoration-none border-0 small list-group-item list-group-item-action hstack gap-5 py-3 px-3 px-xl-5 mb-1'
                )}
                iconStart={icon ? { name: icon, size: 25 } : undefined}
                justifyContent="start"
                key={label}
                label={label}
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => {
                  onClick && onClick();
                }}
              />
            )
          )}
        </div>
      </div>
    ),
  });
};
