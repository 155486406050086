import { Resource } from '../i18n';

export const es: Resource = {
  account: {
    coupons: {
      active: 'Activo',
      code: 'Código',
      description: 'Descripción',
      empty: 'Ningún vale de descuento',
      expired: 'Caducado',
      expiryDate: 'Fecha de caducidad',
      numberOfUses: 'Número de usos',
      status: 'Estado',
    },
    create: 'Crea tu cuenta',
    creditCards: {
      addCreditcard: 'Añade tarjeta de crédito o débito',
      noCardRegistered: 'No hay ninguna tarjeta de crédito registrada',
    },
    edit: 'Modificar',
    emptyWishlist: 'No se añadió ningún producto a la lista de deseos',
    info: {
      additionalInformation: {
        children: {
          birthdate: 'Fecha de nacimiento',
          name: 'Nombre',
          sex: 'Sexo',
          surname: 'Apellidos',
          title: 'Niño',
        },
        document: {
          authorityDescription: 'Descripción autoridad',
          authorityType: 'Tipo autoridad',
          districtName: 'Nombre distrito',
          districtType: 'Tipo distrito',
          documentNumber: 'Número de documento',
          documentType: 'Tipo de documento',
          releaseDate: 'Fecha de emisión',
          title: 'Documento',
        },
        other: 'Otro',
        title: 'Datos adicionales',
      },
      birthdate: 'Fecha de nacimiento',
      businessType: {
        label: 'Tipo de actividad',
        options: {
          COMPANY: 'Empresa',
          PROPRIETORSHIP: 'Empresa individual/profesional',
        },
      },
      company: 'Razón social',
      editPassword: 'Cambiar contraseña',
      email: 'Correo electrónico',
      loginData: 'Datos de inicio de sesión',
      name: 'Nombre',
      pec: 'Dirección correo electrónico certificado',
      personalAddresses: 'Direcciones',
      personalData: 'Datos personales',
      phone: 'Móvil',
      profile: {
        label: 'Perfil',
        options: {
          BUSINESS: 'Trabajo',
          PRIVATE: 'Privado',
        },
      },
      secondaryPhone: 'Otro teléfono',
      sex: {
        options: {
          F: 'Mujer',
          M: 'Hombre',
          O: 'No especificado',
        },
        title: 'Sexo',
      },
      surname: 'Apellidos',
      taxCode: 'Código fiscal',
      title: 'Datos de facturación',
      uniqueCode: 'Código SDI',
      vatNumber: 'Número de IVA',
    },
    insertCredentials: 'Si estás registrado, introduce tu correo electrónico y contraseña',
    login: 'Entrar',
    logout: 'Salir',
    loyalty: {
      pointsBalance: 'tu saldo de puntos',
      pointsTable: {
        awardsLabel: 'Pulsar',
        couponLabel: 'Código de descuento',
        footerText: 'Utiliza el código de descuento en la fase de compra',
        pointsLabel: 'Puntos',
        ptLabel: 'pt',
      },
      shopping: 'empieza a comprar',
    },
    newCustomer: '¿Eres un cliente nuevo?',
    orders: {
      details: {
        closeOrderDetails: 'Cerrar',
        code: 'Código',
        color: 'Color:',
        date: 'Fecha',
        invoiceDownload: 'Descargar factura',
        invoiceNotReady: 'La factura aún no está preparada',
        paymentAddress: 'Pago',
        price: 'Precio',
        product: 'Producto',
        quantity: 'Cantidad:',
        receiverShipping: 'Destinatario',
        review: 'Resumen',
        shippingAddress: 'Envío',
        showOrderDetails: 'Ver detalle del pedido',
        size: 'Talla:',
        status: 'Estado',
        total: 'Total',
        totalAmount: 'total por pagar: ',
        totalProducts: 'total de productos',
        totalShipping: 'envío:',
        trackingCode: 'Seguimiento del pedido',
        unitPrice: 'Precio por unidad',
      },
      noOrders: 'Actualmente no hay pedidos',
      status: {
        arrived: 'Entregado',
        cancelled: 'Cancelado',
        preparing: 'En preparación',
        readyToShipping: 'Listo para enviarse',
        shipping: 'Enviado',
        waitPayment: 'A la espera del pago',
        waitingAcceptance: 'En aceptación',
      },
      trackOrdersState: 'Sigue el estado de tus pedidos',
    },
    passwordForgotten: '¿Olvidaste tu contraseña?',
    register: 'Registrados',
    sectionDisabled: 'Sección deshabilitada',
    taxBenefits: {
      alerts: {
        danger: 'Los documentos no han sido aprobados',
        success: 'Usuario habilitado',
        warning: 'Los documentos están en fase de aprobación',
      },
    },
    userRegistered: '¿Eres un cliente registrado?',
    welcomeMessage: 'Hola, {{name}}: descubre tu área personal',
    wishlist: 'Incluir en la lista de deseos',
  },
  alert: {
    CART_DIFFERENT_SKU_PRICE: '',
    CART_MINIMUM_COST: 'No se alcanzó el importe mínimo del carrito',
    CART_MIN_CAR_SELLER_VIOLATED: '',
    CART_REGION_NOT_REACHABLE_EXTRA_FRESH: '',
    GUEST_CHECKOUT_NOT_ALLOWED: '',
    INVOICE_MANDATORY_MISSING_DATA: 'Se ha solicitado la factura, pero faltan los datos de facturación',
    MISSING_BILLING_ADDRESS: 'Falta la dirección de facturación',
    MISSING_EMAIL: 'Falta introducir el correo electrónico',
    MISSING_PAYMENT_METHOD: 'Falta seleccionar la forma de pago',
    MISSING_SHIPPING_ADDRESS: 'Falta la dirección de envío',
    MISSING_SHIPPING_PROFILE: 'Falta seleccionar el perfil de envío',
    UNSOLICITED_INVOICE: 'Atención, no se ha solicitado la emisión de la factura para este pedido',
    WAREHOUSEITEM_NOT_IN_STOCK: '',
    WAREHOUSESKU_NOT_AVAILABLE: 'Producto no disponible',
  },
  api: {
    error: {
      AddingSkuNotAllowed: 'No es posible comprar este producto.',
      AddingSkuNotSellable: 'El producto que se está intentando añadir no se puede vender.',
      CouponAlreadyUsedException: 'Cupón ya utilizado',
      CouponCartRangeException: 'El cupón no es aplicable a este carrito',
      CouponInvalidException: 'El código introducido no corresponde a ningún cupón y no es aplicable a este carrito',
      CouponLoggedGuestException: 'Para usar el cupón, accede o regístrate en el sitio',
      CustomerCannotSetFeedbackException:
        'No hemos encontrado entre tus compras ningún pedido que contenga este producto',
      CustomerEmailAlreadyExistException: 'Correo electrónico ya utilizado',
      CustomerEmailNotValid: 'Correo electrónico no válido',
      CustomerEmailNotVerifiedException:
        'Para poder continuar, es necesario confirmar el registro mediante el enlace que se encuentra en el correo electrónico recibido después del registro.',
      CustomerNotFoundException:
        'La dirección introducida no consta en nuestros sistemas. Comprueba que es correcta y vuelve a intentarlo.',
      CustomerUsernameAlreadyExistException: 'Nombre de usuario ya utilizado',
      DataReferenceException: 'Error interno',
      DepositPaymentCardNoRemainingCreditException: 'No queda crédito',
      DepositPaymentCardNotFoundException: 'Código no reconocido',
      DepositPaymentCardNotPermittedOnCartException: 'No es posible usar la tarjeta regalo para comprar otra',
      EJBException: 'Error interno',
      LoginException: 'Nombre de usuario o contraseña no válidos',
      LoginUserNotFoundException: 'Correo electrónico no encontrado',
      SystemException: 'Error interno',
      TaxCodeCheckDigitException:
        'El código fiscal introducido no es válido. Comprueba el valor introducido y vuelve a intentarlo.',
      TaxCodeLenghtException:
        'El código fiscal introducido no es válido. Comprueba el valor introducido y vuelve a intentarlo.',
      VatNumberCheckDigitException:
        'El número de IVA introducido no es válido. Comprueba el valor introducido y vuelve a intentarlo.',
      VatNumberLenghtException:
        'El número de IVA introducido no es válido. Comprueba el valor introducido y vuelve a intentarlo.',
      title: 'Error',
      usernotlogged: 'sesión expirada',
    },
  },
  bankAccountDetails: {
    bankName: 'Banco',
    bicSwiftCode: 'BIC/SWIFT',
    ibanCode: 'IBAN',
    ownerName: 'Beneficiario',
  },
  cart: {
    empty: {
      description: 'Empieza a buscar algo',
      title: 'Tu carrito está vacío',
    },
    loyaltyPointsEarned: 'Tu carrito puede hacerte ganar <strong>{{loyaltyPointsEarned}} puntos fidelidad </strong>',
    modal: {
      shippingCountry: {
        alert: '¡Atención! Si cambias de país, se vaciará tu carrito',
        title: '¿Quieres cambiar de país de envío?',
      },
    },
    paymentMethods: {
      title: 'Formas de pago disponibles',
    },
    productTile: {
      composedOf: 'Compuesto por',
      removeItem: 'Eliminar artículo',
      sendToWishlist: 'Incluir en la lista de deseos',
    },
    proocedToCart: 'Ir al carrito',
    quantity: 'Cantidad',
    summary: {
      additional: {
        cashOnDelivery: 'Pago en el momento de la entrega',
        title: 'Suplementos',
      },
      continueShopping: 'continúa comprando',
      coupons: {
        removeCoupon: 'Eliminar',
        title: 'Introducir un código de descuento (opcional)',
      },
      freeShipping: 'Gratis',
      freeShippingFrom: 'Envíos gratuitos a partir de {{freeShippingFromAmount}}',
      giftCard: 'Tarjetas regalo',
      proocedToCheckout: 'Continuar',
      shippingCountry: {
        edit: 'Cambiar',
        title: 'País de envío',
      },
      shippingProfiles: {
        title: 'Envío',
      },
      subtotal: 'Subtotal',
      title: 'Tu pedido',
      toFreeShipping: 'Faltan <strong>{{toFreeShippingAmount}}</strong> para el envío gratuito',
      toFreeShippingComplete: 'Has conseguido el <b>envío gratuito</b>',
      totalBillingCost: 'Total (IVA incluido)',
      totalCost: 'Total por pagar',
      totalDiscount: 'Descuentos',
      totalProductsCost: 'Coste de productos',
    },
    title: 'Mi carrito',
  },
  checkout: {
    backToCart: 'Volver al carrito',
    billing: {
      addNewAddress: 'Añadir nueva dirección',
    },
    cancel: 'Anular',
    cannotStartPayment: 'Vaya, algo ha fallado en tu carrito. Comprueba los artículos para proceder',
    edit: 'Modificar',
    invoice: {
      notRequested: 'No has pedido factura',
      requested: 'Has pedido factura',
    },
    shipping: {
      addNewAddress: 'Añadir nueva dirección',
    },
    step: {
      billingAddress: {
        subTitle: 'Coincide con la de entrega',
        title: 'Dirección de pago',
      },
      email: {
        alert: {
          buttons: {
            label: '',
          },
          title: '',
        },
        insertCredentials: 'Introduce tus credenciales y completa el acceso',
        login: 'Entrar',
        orderAs: 'Estás haciendo el pedido como',
        subTitle1: ' Completa tu pedido más rápidamente.',
        subTitle2: 'Accede con tu cuenta',
        title: 'Correo electrónico',
      },
      paymentMethod: {
        subTitle: 'Elige la forma de pago',
        title: 'Forma de pago',
      },
      shippingAddress: {
        title: 'Dirección de entrega',
      },
    },
    summary: {
      additional: {
        cashOnDelivery: 'Pago en el momento de la entrega',
        title: 'Suplementos',
      },
      cartItem: {
        edit: 'Modificar',
        title: 'Estás comprando',
      },
      coupon: {
        add: 'Añadir',
        edit: 'Modificar',
        title: 'Cupones',
      },
      giftCard: {
        add: 'Añadir',
        edit: 'Modificar',
        title: 'Tarjeta regalo',
      },
      shipping: {
        title: 'Envío',
      },
      shippingCountry: {
        title: 'País de envío',
      },
      subtotal: 'Subtotal',
      title: 'Tu pedido',
      totalBillingCost: 'Total (IVA incluido)',
      totalCost: 'Total por pagar',
      totalProductsCost: 'Total de productos',
    },
    thankYou: {
      cashOnDelivery: {
        amountToPayOnDelivery: 'Importe por pagar en el momento de la entrega',
      },
    },
  },
  contactUs: {
    customerOrder: {
      label: '¿Para qué pedido necesitas ayuda?',
      placeholder: 'Selecciona tu pedido',
    },
    notSatisfied: {
      label: '¿No has encontrado la respuesta que buscabas? ¡Contáctanos!',
    },
    satisfied: {
      label: '¿Has encontrado la respuesta que buscabas?',
      no: 'no',
      yes: 'sí',
    },
    subject: {
      label: '¿Qué información necesitas?',
      placeholder: 'Selecciona una opción',
    },
  },
  customerCare: {
    contact: 'Contáctanos',
    help: '¿Te podemos ayudar?',
    select: {
      label: '¿Qué información necesitas?',
      optionLabel: 'Selecciona una opción',
    },
    service: 'Consulta el área Atención al cliente para una ayuda fácil y rápida',
  },
  error: {
    pageNotFound: 'Vaya. No hemos encontrado la página que buscabas',
  },
  fixedPagesName: {
    account: 'account',
    cart: 'cart',
    'change-password': 'change-password',
    checkout: 'checkout',
    error: 'error',
    'forgot-password': 'forgot-password',
    home: 'home',
    login: 'login',
    newsletter: 'newsletter',
    'order-ko': 'order-ko',
    'order-ok': 'order-ok',
    register: 'register',
    search: 'search',
    sitemap: 'sitemap',
    template: 'template',
    test: 'test',
    'unsubscribe-newsletter': 'unsubscribe-newsletter',
    whois: 'whois',
  },
  footer: {
    paymentsMethod: 'Formas de pago',
  },
  form: {
    addToCartForm: {
      buttons: {
        availabilityEmailNotification: {
          addLongLabel: 'Avísame cuando esté disponible',
          addShortLabel: 'Avísame',
          remove: 'Eliminar el aviso',
        },
        cancel: 'Anular',
        reset: 'Reset',
        submit: 'Añadir al carrito',
        submitButNotAvailable: 'No disponible',
      },
      giftCard: {
        message: {
          label: 'Mensaje',
          placeholder: '',
        },
        receiverEmail: {
          label: 'Correo electrónico del destinatario',
          placeholder: '',
        },
        receiverName: {
          label: 'Nombre del destinatario',
          placeholder: '',
        },
        senderName: {
          label: 'Tu nombre',
          placeholder: '',
        },
        value: {
          placeholder: 'Selecciona una opción',
        },
      },
      modal: {
        title: '',
      },
      productVariants: 'Formato',
      quantity: {
        label: 'Cantidad',
        placeholder: 'Selecciona una de las opciones',
      },
      skuAttributes: {
        Gift_card_valore: {
          placeholder: 'Selecciona el valor',
        },
      },
      skus: {
        label: 'SKU',
        placeholder: 'Selecciona un SKU',
      },
      success: {
        body: 'Producto añadido al carrito',
        buttons: {
          continueShopping: 'Continúa comprando',
          goToCart: 'Ir al carrito',
        },
        title: 'Producto añadido al carrito',
      },
    },
    addressForm: {
      account: {
        defaultBilling: {
          label: 'Configurar como dirección de pago predefinida',
        },
        defaultShipping: {
          label: 'Configurar como dirección de envío predefinida',
        },
      },
      address: {
        addressLine1: {
          label: 'Dirección',
          placeholder: '',
        },
        addressLine2: {
          label: 'Número, piso, etc.',
          placeholder: '',
        },
        addressLine3: {
          label: '',
          placeholder: '',
        },
        alternativePhone: {
          label: 'Teléfono alternativo',
          placeholder: '',
        },
        city: {
          label: 'Ciudad',
          placeholder: '',
        },
        civicNumber: {
          label: 'Número',
          placeholder: '',
        },
        country: {
          label: 'País',
          placeholder: '',
        },
        latitude: {
          label: 'Latitud',
          placeholder: '',
        },
        longitude: {
          label: 'Longitud',
          placeholder: '',
        },
        middleName: {
          label: 'Segundo nombre',
          placeholder: '',
        },
        name: {
          label: 'Nombre',
          placeholder: '',
        },
        phone: {
          label: 'Teléfono',
          placeholder: '',
        },
        province: {
          label: 'Provincia',
        },
        region: {
          label: 'Región',
          placeholder: '',
        },
        shippingToAlert: 'Estás enviando a {{locale}}, vuelve atrás para cambiar el país de envío.',
        surname: {
          label: 'Apellidos',
          placeholder: '',
        },
        zipCode: {
          label: 'Código postal',
        },
      },
      buttons: {
        cancel: {
          label: 'Anular',
        },
        edit: {
          label: 'Modificar',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Añadir',
        },
      },
      copyFromBilling: {
        label: 'Usar la misma dirección de pago',
      },
      copyFromShipping: {
        label: 'Usar la misma dirección de envío',
      },
      copyToBilling: {
        label: 'Configurar también como dirección de pago',
      },
      copyToShipping: {
        label: 'Configurar también como dirección de envío',
      },
      editAddressTitle: 'Modificar dirección',
      invoice: {
        data: {
          company: {
            label: 'Razón social',
            placeholder: '',
          },
          pec: {
            label: 'Correo electrónico certificado',
            placeholder: '',
          },
          taxCode: {
            label: 'Código fiscal',
            placeholder: '',
          },
          uniqueCode: {
            label: 'Código SDI',
            placeholder: '',
          },
          vatNumber: {
            label: 'Número de IVA',
            placeholder: '',
          },
        },
        request: {
          label: '¿Quieres factura?',
        },
        type: {
          label: 'Tipo de cliente',
          options: {
            company: 'Empresa',
            freelance: 'Empresa individual/profesional',
            privatePerson: 'Privado',
          },
        },
      },
      modal: {
        title: '',
      },
      newAddressTitle: 'Introduce una nueva dirección',
    },
    cartAddressFromAddressBookForm: {
      addresses: {
        editAddressButton: {
          label: 'Modificar dirección',
        },
      },
      buttons: {
        cancel: {
          label: 'Anular',
        },
        newAddress: {
          label: 'Añadir nueva dirección',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Confirmar',
        },
      },
      modal: {
        title: '',
      },
    },
    cartEmailForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          checkoutLabel: 'Modificar correo electrónico',
          whoIsLabel: 'Seguir con el pedido',
        },
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico',
      },
      modal: {
        title: '',
      },
      privacy: {
        label: 'Doy mi consentimiento al tratamiento de los datos personales.',
      },
    },
    cartGenericCouponForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Aplicar',
        },
      },
      code: {
        label: '',
        placeholder: 'Introducir código',
      },
      modal: {
        title: '',
      },
    },
    cartPaymentMethodsForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Confirmar',
        },
      },
      modal: {
        title: '',
      },
    },
    cartPersonalCouponsForm: {
      modal: {
        title: '',
      },
      noCoupon: '',
    },
    changePasswordByTokenForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Enviar solicitud',
        },
      },
      confirmNewPassword: {
        label: 'Confirmar nueva contraseña',
        placeholder: 'Confirmar nueva contraseña',
      },
      modal: {
        title: '',
      },
      newPassword: {
        label: 'Nueva contraseña',
        placeholder: 'Nueva contraseña',
      },
      success: {
        body: 'El procedimiento de cambio de contraseña ha finalizado satisfactoriamente',
        title: 'Confirmar cambio de contraseña',
      },
    },
    changePasswordForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Enviar solicitud',
        },
      },
      confirmNewPassword: {
        label: 'Confirmar nueva contraseña',
        placeholder: 'Confirmar nueva contraseña',
      },
      currentPassword: {
        label: 'Contraseña actual',
        placeholder: 'Contraseña actual',
      },
      modal: {
        title: 'Cambio de contraseña',
      },
      newPassword: {
        label: 'Nueva contraseña',
        placeholder: 'Nueva contraseña',
      },
      success: {
        body: 'El procedimiento de cambio de contraseña ha finalizado satisfactoriamente',
        title: 'Confirmar cambio de contraseña',
      },
    },
    consentsForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Guardar',
        },
      },
      marketing: {
        label: 'Aceptación marketing',
        radios: {
          false: 'No',
          true: 'Sí',
        },
      },
      modal: {
        title: '',
      },
      newsletter: {
        label: 'Aceptación newsletter',
        radios: {
          false: 'No',
          true: 'Sí',
        },
      },
      profiling: {
        label: 'Aceptación creación de perfil',
        radios: {
          false: 'No',
          true: 'Sí',
        },
      },
    },
    customerCareForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Enviar',
        },
      },
      email: {
        label: 'Correo electrónico',
        placeholder: '',
      },
      message: {
        label: 'Mensaje',
        placeholder: '',
      },
      modal: {
        title: '',
      },
      name: {
        label: 'Nombre',
        placeholder: '',
      },
      phoneNumber: {
        label: 'Teléfono',
        placeholder: '',
      },
      privacy: {
        label: 'Declaro haber leído y aceptado la hoja informativa de privacidad',
        placeholder: '',
      },
      success: {
        body: 'Mensaje enviado correctamente',
        title: 'Enviar mensaje',
      },
      surname: {
        label: 'Apellidos',
        placeholder: '',
      },
    },
    customerForm: {
      birthdate: {
        errors: {
          minAge: 'Introduce una fecha de nacimiento válida',
          minAgeWithDate: '',
          minAgeWithDate_one: 'Debes haber cumplido al menos {{count}} año',
          minAgeWithDate_other: 'Debes haber cumplido al menos {{count}} años',
        },
        label: 'Fecha de nacimiento',
      },
      businessType: {
        label: 'Cómo está registrada tu actividad',
        options: {
          company: 'Empresa',
          proprietorship: 'Empresa individual/profesional',
        },
        placeholder: 'Selecciona una opción',
      },
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          registrationLabel: 'Registrados',
          updateLabel: 'Actualizar',
          updateRequiredFieldsLabel: 'Completa el perfil',
        },
      },
      children: {
        birthdate: {
          errors: {
            minAge: 'Introduce una fecha de nacimiento válida',
            minAgeWithDate: '',
            minAgeWithDate_one: 'Debes haber cumplido al menos {{count}} año',
            minAgeWithDate_other: 'Debes haber cumplido al menos {{count}} años',
          },
          label: 'Fecha de nacimiento',
          placeholder: 'Fecha de nacimiento',
        },
        buttons: {
          add: {
            label: 'Añadir niño',
          },
          remove: {
            label: 'Eliminar niño',
          },
        },
        formGroup: {
          label: 'Niños (mín: {{min}} máx: {{max}} actuales: {{current}})',
        },
        name: {
          label: 'Nombre',
          placeholder: 'Nombre',
        },
        sex: {
          label: 'Sexo',
          options: {
            female: 'Mujer',
            male: 'Hombre',
            other: 'Prefiero no especificarlo',
          },
          placeholder: 'Selecciona una opción',
        },
        surname: {
          label: 'Apellidos',
          placeholder: 'Apellidos',
        },
      },
      company: {
        label: 'Razón social',
        placeholder: 'Razón social',
      },
      document: {
        authorityDescription: {
          label: 'Descripción de la autoridad',
          placeholder: 'Descripción de la autoridad',
        },
        authorityType: {
          label: 'Tipo de autoridad',
          options: {
            consulate: 'Consulado',
            financeOffice: 'Oficina de Finanzas',
            foreignState: 'Estado extranjero',
            motorization: 'Jefatura de Tráfico',
            municipality: 'Ayuntamiento',
            other: 'Otro',
            prefecture: 'Prefectura',
            unknown: 'Desconocido',
          },
          placeholder: 'Tipo de autoridad',
        },
        districtName: {
          label: 'Nombre distrito',
          placeholder: 'Nombre distrito',
        },
        districtType: {
          label: 'Tipo distrito',
          placeholder: 'Tipo distrito',
        },
        documentNumber: {
          label: 'Número de documento',
          placeholder: 'Número de documento',
        },
        documentType: {
          label: 'Tipo de documento',
          options: {
            drivingLicense: 'Permiso de conducir',
            identityCard: 'Documento de identidad',
            passport: 'Pasaporte',
          },
          placeholder: 'Tipo de documento',
        },
        label: 'Documento',
        releaseDate: {
          label: 'Fecha de emisión',
          placeholder: 'Fecha de emisión',
        },
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico',
      },
      enableBusinessData: {
        formGroup: {
          label: '¿Deseas añadir los datos de tu actividad?',
        },
        options: {
          no: 'No',
          yes: 'Sí',
        },
      },
      enableChildren: {
        formGroup: {
          label: '¿Deseas añadir niños?',
        },
        options: {
          no: 'No',
          yes: 'Sí',
        },
      },
      enableDocument: {
        formGroup: {
          label: '¿Deseas añadir un documento?',
        },
        options: {
          no: 'No',
          yes: 'Sí',
        },
      },
      marketingConsent: {
        label: 'Marketing',
      },
      modal: {
        title: 'Datos personales',
      },
      name: {
        label: 'Nombre',
        placeholder: 'Nombre',
      },
      newsletterConsent: {
        label: 'Newsletter',
      },
      password: {
        label: 'Contraseña',
        placeholder: 'Contraseña',
      },
      pec: {
        label: 'Dirección correo electrónico certificado',
        placeholder: 'Dirección correo electrónico certificado',
      },
      phoneNumbers: {
        buttons: {
          add: {
            label: 'Añadir teléfono',
          },
          remove: {
            label: 'Eliminar teléfono',
          },
        },
        label: 'Teléfono',
        placeholder: 'Teléfono',
      },
      privacyConsent: {
        label: 'Privacidad',
      },
      profile: {
        label: 'Perfil',
        options: {
          business: 'Trabajo',
          private: 'Privado',
        },
        placeholder: 'Selecciona una opción',
      },
      profilingConsent: {
        label: 'Creación de perfil',
      },
      sex: {
        label: 'Sexo',
        options: {
          female: 'Mujer',
          male: 'Hombre',
          other: 'Prefiero no especificarlo',
        },
        placeholder: 'Selecciona una opción',
      },
      surname: {
        label: 'Apellidos',
        placeholder: 'Apellidos',
      },
      taxCode: {
        label: 'Código fiscal',
        placeholder: 'Código fiscal',
      },
      uniqueCode: {
        label: 'Código SDI',
        placeholder: 'Código SDI',
      },
      vatNumber: {
        label: 'Número de IVA',
        placeholder: 'Número de IVA',
      },
    },
    emailToCartForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Seguir con el pedido',
        },
      },
      modal: {
        title: '',
      },
    },
    exampleForm: {
      modal: {
        title: '',
      },
    },
    forgotPasswordForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Enviar solicitud',
        },
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico',
      },
      modal: {
        title: '',
      },
      success: {
        body: 'Operación finalizada satisfactoriamente.\n\nTe hemos enviado un correo electrónico con las instrucciones que debes seguir.',
        title: 'Solicitud de restablecimiento de contraseña',
      },
    },
    giftCardForm: {
      buttons: {
        balanceCheck: {
          label: 'Comprobar saldo',
        },
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Utilizar',
        },
      },
      code: {
        label: 'Tarjeta regalo',
        placeholder: 'Introduce el número de la tarjeta regalo',
      },
    },
    localeForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Ir',
        },
      },
      locale: {
        label: 'Idioma',
      },
      modal: {
        title: '',
      },
      shippingCountry: {
        label: 'País',
      },
    },
    loginForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Inicio de sesión',
        },
      },
      modal: {
        title: '',
      },
      password: {
        label: 'Contraseña',
        placeholder: 'Contraseña',
      },
      username: {
        label: 'Correo electrónico',
        placeholder: 'Correo electrónico',
      },
    },
    newsletterForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Inscribirse',
        },
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'Introduce tu dirección de correo electrónico',
      },
      modal: {
        title: '',
      },
      name: {
        label: 'Nombre',
        placeholder: 'Introduce tu nombre',
      },
      newsletterConsent: {
        innerLabel: '',
        label: '',
      },
      success: {
        body: '¡Enhorabuena! Desde ahora formas parte de la newsletter.',
        buttons: {
          ok: {
            label: 'Ok',
          },
        },
        title: 'Inscripción a la newsletter realizada satisfactoriamente',
      },
      surname: {
        label: 'Apellidos',
        placeholder: 'Introduce tus apellidos',
      },
    },
    orderGuestForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Buscar',
        },
      },
      email: {
        label: 'Correo electrónico',
        placeholder: 'correo electrónico',
      },
      modal: {
        title: '',
      },
      orderCode: {
        label: 'Código de pedido',
        placeholder: 'código de pedido',
      },
    },
    restartPaymentForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Pagar ahora',
        },
      },
      checkbox: {
        label: '',
      },
      modal: {
        title: '',
      },
    },
    reviewForm: {
      buttons: {
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Dejar una reseña',
        },
      },
      error: {
        body: 'No hemos encontrado entre tus compras ningún pedido que contenga este producto',
        title: 'Lo sentimos',
      },
      message: {
        label: 'Añade un comentario',
        placeholder: 'Escribe tu reseña',
      },
      modal: {
        title: '¡Dinos lo que piensas!',
      },
      rating: {
        label: '¿Cuánto te ha gustado <strong>{{productName}}</strong>?',
      },
      success: {
        body: 'Tu reseña será publicada después de haber sido revisada por nuestro servicio de atención al cliente.',
        title: '¡Gracias por tus comentarios!',
      },
    },
    shopSearchForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Buscar',
        },
      },
    },
    simpleSearchForm: {
      text: {
        label: 'Buscar',
        placeholder: 'Buscar',
      },
    },
    startPaymentForm: {
      buttons: {
        cancel: {
          label: 'Anular',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Comprar',
        },
      },
      checkbox: {
        label: '',
      },
      modal: {
        title: '',
      },
    },
    taxBenefitsForm: {
      buttons: {
        browseFile: 'Adjuntar documento',
        cancel: 'Anular',
        reset: {
          label: 'Reset',
        },
        submit: 'Guardar documentación',
      },
      documentValidation: {
        approved: 'Documento convalidado.',
        awating: 'Documentos en fase de aprobación.',
        rejected: 'Documento rechazado. Cargar un nuevo documento válido.',
      },
      documentation: {
        label: 'Descargar la documentación',
      },
      taxCode: {
        label: 'Código fiscal',
        placeholder: 'Código fiscal',
      },
    },
    updateCustomerRequiredFieldsForm: {
      modal: {
        title: '',
      },
    },
  },
  header: {
    cartSummary: '',
    cartSummary_one: 'Resumen del pedido ({{count}} artículo)',
    cartSummary_other: 'Resumen del pedido ({{count}} artículos)',
    freeShippingFrom: 'Envíos gratuitos a {{locale}} desde {{from}}',
    sendTo: 'País: {{country}} | {{locale}}',
  },
  i18nTest: 'Esto es una prueba de b2x-react',
  misc: {
    addAddress: 'Añadir dirección',
    addToCart: 'Añadir',
    amount: 'Importe',
    availabilityEmailNotification: 'Avísame',
    availabilityNotification: 'Te avisaremos cuando esté disponible',
    back: 'Atrás',
    backToHome: 'Volver a inicio',
    bestPrice: {
      info: 'info',
      modal: {
        lowestPrice: 'Precio más bajo de los últimos 30 días',
        priceToday: 'Cuánto cuesta este artículo hoy',
        title: 'Historial del precio del artículo',
      },
      priceLabel: 'Último precio más bajo:',
    },
    categories: 'Categorías',
    changeCountryMessage: '¡Atención! Si cambias de país, se vaciará tu carrito',
    code: 'Código',
    customerForm: {
      registrationSuccessful: {
        modal: {
          body: 'Gracias por suscribirse', //da revisionare
          title: 'Inscripción completada', //da revisionare
        },
      },
    },
    customerService: 'Asistencia a clientes',
    directions: 'Indicaciones',
    discoverMore: 'descubre más',
    discoverOurCollection: 'Descubre la selección',
    discoverOurProducts: 'Descubre nuestros productos',
    doubleOptIn: {
      body: 'Tu dirección de correo electrónico ha sido validada satisfactoriamente. Inicia sesión para empezar a navegar.',
      title: 'Cuenta verificada',
    },
    fileInput: {
      label: 'Elegir archivo',
      placeholder: 'Ningún archivo seleccionado',
      uploading: 'uploading...',
    },
    filter: 'Filtrar',
    filterAndOrderBy: 'Filtrar y ordenar por',
    filterBy: 'Filtrar por',
    filterYourSearchResults: 'Filtra los resultados de tu búsqueda',
    followUs: 'Síguenos',
    fromPrice: 'desde {{value}}',
    giftCard: {
      balance: {
        activation: 'Activación',
        expiration: 'Vencimiento',
        title: 'Tarjeta regalo detalle',
        value: 'Saldo',
      },
      label: 'Tarjeta regalo',
      message: 'Visualizar el mensaje',
      receiver: {
        label: 'Para:',
        mail: 'Correo electrónico:',
      },
      sender: {
        label: 'De:',
        mail: 'Correo electrónico:',
      },
      value: 'Valor:',
    },
    giftProduct: 'Obsequio',
    gratis: 'Gratis',
    invalid_form_values: 'Comprueba todos los campos antes de continuar',
    invoice: {
      company: 'Razón social',
      pec: 'Correo electrónico certificado',
      taxCode: 'Código fiscal',
      uniqueCode: 'Código SDI',
      vatNumber: 'Número de IVA',
    },
    locale: {
      box: {
        title: 'Selecciona país e idioma',
      },
    },
    newProductSticker: 'New',
    newsletterTitle: 'Newsletter',
    orderBy: 'Ordena por',
    orderCode: 'Código de pedido',
    passwordInput: {
      hide: 'ocultar',
      show: 'mostrar',
    },
    payments: 'Pagos',
    preferences: 'Podría interesarte',
    price: '{{value}}',
    pricePerUnit: '{{price}} por {{measurementUnit}}',
    productNotAvailable: 'No disponible',
    readMore: 'Leer más',
    register: 'Regístrate ya',
    relatedProducts: 'También podría interesarte',
    removeFilters: 'Eliminar todos',
    searchItemResult: '',
    searchItemResult_one: '{{count}} resultado',
    searchItemResult_other: '{{count}} resultados',
    shipment: 'Envíos y devoluciones',
    showLess: 'Cerrar',
    showMore: 'Leer todo',
    sizeGuide: 'guía de tallas',
    storeName: '',
    time: {
      days: '',
      days_one: 'Día',
      days_other: 'Días',
      hours: '',
      hours_one: 'Hora',
      hours_other: 'Horas',
      minutes: '',
      minutes_one: 'Minuto',
      minutes_other: 'Minutos',
      seconds: '',
      seconds_one: 'Segundo',
      seconds_other: 'Segundos',
    },
    unsubscribeNewsletterByToken: {
      pending: 'Te estamos dando de baja de la newsletter...',
      success: 'Baja de la newsletter realizada satisfactoriamente',
      title: 'Newsletter',
    },
    vat: '(IVA al {{value}} %)',
    viewAll: 'Ver todos',
    youSearchedFor: 'Has buscado:',
  },
  modal: {
    geolocation: {
      content: '{{storeName}} quiere acceder a tu posición para aprovechar mejor el servicio.',
      title: 'Geolocalización {{storeName}}',
    },
  },
  paymentMethod: {
    ALIAS: 'Pago mediante Alias',
    ANTICIPATO_BONIFICO: 'Adelanto con transferencia bancaria',
    ANTICIPATO_CC: 'Adelanto con tarjetas de crédito',
    B2X_DIRECT: '',
    BANCOMAT_PAY: 'BANCOMAT Pay',
    BANK_TRANSFER: 'Transferencia bancaria',
    BNL: 'Tarjetas de crédito mediante BNL',
    CALL_CENTER: 'Call center',
    CASH: 'CASH',
    CASH_ON_DELIVERY: 'Contra reembolso',
    CMCIC: '',
    EASYPAY_CC: '',
    EASYPAY_MB: '',
    EASYPAY_MBWAY: '',
    ECPAY: '',
    FATTURA_30: 'Factura a 30 días',
    FATTURA_60: 'Factura a 60 días',
    FIDELITY_CARD: '',
    GESTPAY: '',
    GESTPAY_MYBANK: '',
    GIFT_CARD: 'Tarjeta regalo',
    KEYCLIENT: 'Tarjeta de crédito',
    KLARNA: '',
    MULTIBANCO: '',
    MYBANK: '',
    NOT_NECESSARY_PAYMENT: 'Pago no necesario',
    NOT_SETTED_PAYMENT: 'Pago no configurado',
    PAYPAL: 'PayPal',
    PAY_ON_PHYSICAL_SHOP: '',
    POS: 'Tarjeta de crédito con POS in situ',
    POSTE_BPIOL: 'Poste BPIOL',
    POSTE_BPOL: 'Poste BPOL',
    POSTE_CC: 'Tarjeta de crédito mediante Poste',
    POSTE_PAY: 'PostePay',
    RIBA60: 'RiBa 60',
    RIBA_30: 'RiBa 30',
    RIMESSA_DIRETTA: 'Envío directo',
    SATISPAY: 'Satispay',
    SCALAPAY: 'Pago mediante Scalapay',
    SETEFI: 'Tarjeta de crédito',
    SETEFI_MASTERPASS: 'Masterpass',
    THIRD_PARTIES: 'PostePay',
    WANNME_BT: '',
    WANNME_CC: '',
    WANNME_PAY7: '',
    WANNME_RECURRENT: '',
    WORLDLINE_EASY_CHECKOUT: 'Tarjeta de débito/crédito',
  },
  product: {
    caloriesPer100g: 'Calorías por 100 g',
    code: 'Código de producto',
    cookingMinutes: 'Minutos - tiempo de cocción',
    description: 'Descripción',
    details: 'Detalles del producto',
    dryingTime: 'Horas de secado certificadas',
    features: 'Características',
    freeShippingFrom: 'Envíos gratuitos a partir de {{freeShippingFromAmount}}',
    minutesOfCookingAlDente: 'Minutos - al dente',
    promo: 'Promoción',
    relatedProduct: {
      title: 'También te podría gustar',
    },
    selectSize: 'Selecciona una talla',
    share: 'Compartir',
    size: 'Formato',
    sizeGuide: 'Guía de tallas',
    temporary: {
      shipping: 'Entrega rápida y devolución fácil en menos de 30 días.',
      sizeGuide: '¿No sabes qué talla tienes?',
    },
    usageAndMaintenance: 'Uso y mantenimiento',
  },
  reviews: {
    buttons: {
      reviewForFirst: 'Sé el primero en escribir una reseña sobre este producto',
      showMore: 'Mostrar otras',
      signIn: 'Entrar',
      signUp: 'Registrados',
      writeReview: 'Dejar una reseña',
    },
    messages: {
      customer: 'Comparte tu experiencia sobre este producto.',
      guest: 'Accede o regístrate para dejar una reseña.',
      withReviwes: '¿Ya has probado este producto?',
      withoutReviews:
        '¿Ya has probado este producto? Sé el primer el compartir tu opinión y en decirnos lo que piensas.',
    },
    misc: {
      emptyList: 'No hay reseñas',
      filterBy: 'Filtrar por',
      review: '',
      review_one: '{{count}} reseña',
      review_other: '{{count}} reseñas',
      star: '',
      star_one: 'Estrella',
      star_other: 'Estrellas',
      writeReview: 'Dejar una reseña',
    },
    review: {
      notVerified: 'Reseña no verificada',
      purchasedOn: 'Comprado: {{date}}',
      reviewedOn: 'Escrita el: {{date}}',
    },
    titles: {
      withReviwes: 'Todas las reseñas',
      withoutReviews: 'Dejar una reseña',
    },
  },
  sharingModal: {
    methods: {
      clipboard: {
        label: 'Copiar enlace',
        success: 'Enlace copiado en el portapapeles',
      },
      email: {
        label: 'Correo electrónico',
      },
      facebook: {
        label: 'Facebook',
      },
      messenger: {
        label: 'Messenger',
      },
      whatsApp: {
        label: 'WhatsApp',
      },
      x: {
        label: 'X',
      },
    },
    title: 'Compartir',
  },
  sortingOption: {
    default: 'Relevancia',
    id: {
      asc: '',
      desc: '',
    },
    name: {
      asc: '',
      desc: '',
    },
    price: {
      asc: 'Precio bajo',
      desc: 'Precio alto',
    },
    priority: {
      asc: '',
      desc: '',
    },
  },
  storeLocator: {
    filterPanel: {
      selectProduct: 'Seleccionar el producto',
    },
    listingPanel: {
      loading: 'loading...',
      shopsCount: '',
      shopsCount_one: '{{count}} punto de venta',
      shopsCount_other: '{{count}} puntos de venta',
    },
    modal: {
      desktopPhone: {
        content: 'Para más información sobre {{name}}, póngase en contacto con {{phoneNumber}}',
        title: 'Ponte en contacto con el punto de venta',
      },
      gallery: {
        title: 'Foto punto de venta',
      },
      geolocationError: {
        content: 'Geolocalización negada',
        title: 'Imposible centrar el mapa, geolocalización negada',
      },
    },
    shopDetails: {
      closed: 'Cerrado',
      cta: {
        call: 'Llamar',
        directions: 'Indicaciones de situación',
      },
      description: 'Descripción',
      openingDays: {
        FRIDAY: 'Viernes',
        MONDAY: 'Lunes',
        SATURDAY: 'Sábado',
        SUNDAY: 'Domingo',
        THURSDAY: 'Jueves',
        TUESDAY: 'Martes',
        WEDNESDAY: 'Miércoles',
      },
      socialNetwork: 'Red social',
    },
    shopTile: {
      CLOSED: 'Cerrado',
      OPEN: 'Abierto',
      closesAtDayTime: 'Cierra el {{day}} a las {{time}}',
      closesAtTime: 'Cierra a las {{time}}',
      copyLink: 'Copiar enlace',
      linkCopied: 'Enlace copiado en el portapapeles',
      opensAtDayTime: 'Abre el {{day}} a las {{time}}',
      opensAtTime: 'Abre a las {{time}}',
      share: 'Compartir',
    },
  },
  topSuggestionsBox: {
    categories: 'Categorías',
    popularSearches: 'Búsquedas populares',
    recentSearches: 'Búsquedas recientes',
    showAllProducts: 'Descubre todos nuestros productos',
  },
  updateCustomerRequiredFieldsPage: {
    subtitle: 'Completa tus datos y empieza a comprar en la tienda',
    title: 'Hola',
    titleWithName: 'Hola, {{name}}:',
  },
  wishlist: {
    modal: {
      availabilityNotificationDisabled: 'No te volveremos a avisar de la disponibilidad del producto.',
      availabilityNotificationEnabled: 'Te avisaremos en cuanto el producto vuelva a estar disponible.',
      buttons: {
        continueShopping: {
          label: 'Continúa comprando',
        },
        goToWishlist: {
          label: 'Ir a la lista de deseos',
        },
      },
      productAdded: 'El producto seleccionado ha sido añadido a la lista de deseos.',
      productRemoved: 'El producto seleccionado ha sido eliminado de la lista de deseos.',
      title: 'Actualización de la lista de deseos',
    },
  },
  yup: {
    array: {
      length: 'Debe contener {{length}} artículos',
      max: 'Debe contener un número menor o igual a {{max}} artículos',
      min: 'Debe contener al menos {{min}} artículos',
    },
    boolean: {
      isValue: 'Debe ser {{value}}',
    },
    custom: {
      passwordConfirmationNotMatch: 'La contraseña confirmada no es correcta',
    },
    date: {
      max: 'Debe ser anterior a {{máx}}',
      min: 'Debe ser posterior a {{min}}',
    },
    mixed: {
      default: 'Campo no válido',
      defined: 'Campo no definido',
      notOneOf: 'No debe ser uno de los siguientes valores: {{values}}',
      notType: 'Debe ser de tipo `{{type}}`, pero el valor final era: {{value}}',
      oneOf: 'Debe ser uno de los siguientes valores: {{values}}',
      required: 'Campo obligatorio',
    },
    number: {
      integer: 'Debe ser un entero',
      lessThan: 'Debe ser menor que {{less}}',
      max: 'Debe ser menor o igual a {{max}}',
      min: 'Debe ser mayor o igual a {{min}}',
      moreThan: 'Debe ser mayor que {{more}}',
      negative: 'Debe ser un número negativo',
      positive: 'Debe ser un número positivo',
    },
    object: {
      noUnknown: 'El campo tiene claves no especificadas: {{unknown}}',
    },
    string: {
      email: 'Formato de correo electrónico no válido',
      length: 'Debe tener exactamente {{length}} caracteres',
      lowercase: 'Debe ser una secuencia en minúsculas',
      matches: 'Debe coincidir con: "{{regex}}"',
      max: 'Debe tener como máximo {{max}} caracteres',
      min: 'Debe tener como mínimo {{min}} caracteres',
      trim: 'No debe contener espacios',
      uppercase: 'Debe ser una secuencia en mayúsculas',
      url: 'Debe ser una URL válida',
      uuid: 'Debe ser un UUID válido',
    },
  },
};
