import { ContentApiDto, CustomerApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { formatHtml, fromCustomerApiDtoToCustomerRequestApiDto } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Radios, RadiosProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBooleanAsString,
  getFormikBooleanAsStringValue,
  getInitialBooleanAsString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface ConsentsFormProps
  extends BaseHelpedFormProps<
    FormValues,
    FieldsHelper,
    ValidationSchemaSelector,
    { content?: ContentApiDto<ConsentsContentType> }
  > {
  customer: CustomerApiDto;
}

interface FormValues {
  marketing: formikBooleanAsString;
  newsletter: formikBooleanAsString;
  profiling: formikBooleanAsString;
}

type ValidationSchema = {
  marketing: yup.StringSchema;
  newsletter: yup.StringSchema;
  profiling: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  marketing: { formGroup: FormGroupProps; radios: RadiosProps };
  newsletter: { formGroup: FormGroupProps; radios: RadiosProps };
  profiling: { formGroup: FormGroupProps; radios: RadiosProps };
}

export const ConsentsFormHelper = ({
  children,
  className,
  customer,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: ConsentsFormProps) => {
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      marketing: getInitialBooleanAsString(customer.marketingConsent),
      newsletter: getInitialBooleanAsString(customer.newsletterConsent),
      profiling: getInitialBooleanAsString(customer.profilingConsent),
      ...initialValues,
    }),
    [customer.marketingConsent, customer.newsletterConsent, customer.profilingConsent, initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      marketing: yup.string().required(),
      newsletter: yup.string().required(),
      profiling: yup.string().required(),
    }),
    []
  );

  const { updateCustomer } = useCustomerApi();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      updateCustomer({
        ...fromCustomerApiDtoToCustomerRequestApiDto(customer),
        marketingConsent: getFormikBooleanAsStringValue(values.marketing),
        newsletterConsent: getFormikBooleanAsStringValue(values.newsletter),
        profilingConsent: getFormikBooleanAsStringValue(values.profiling),
      }).then(() => {
        onSuccess && onSuccess();
      }),
    [customer, onSuccess, updateCustomer]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('ConsentsForm', className)}
      enableReinitialize
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.consentsForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.consentsForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          marketing: {
            formGroup: {
              label: formatHtml(consentsContent?.body.marketing?.label),
              names: ['marketing'],
              omitForAttribute: true,
            },
            radios: {
              radios: [
                {
                  id: 'marketing-true',
                  inline: true,
                  label: t('form.consentsForm.marketing.radios.true'),
                  name: 'marketing',
                  value: 'true',
                },
                {
                  id: 'marketing-false',
                  inline: true,
                  label: t('form.consentsForm.marketing.radios.false'),
                  name: 'marketing',
                  value: 'false',
                },
              ],
            },
          },
          newsletter: {
            formGroup: {
              label: formatHtml(consentsContent?.body.newsletter?.label),
              names: ['newsletter'],
              omitForAttribute: true,
            },
            radios: {
              radios: [
                {
                  id: 'newsletter-true',
                  inline: true,
                  label: t('form.consentsForm.newsletter.radios.true'),
                  name: 'newsletter',
                  value: 'true',
                },
                {
                  id: 'newsletter-false',
                  inline: true,
                  label: t('form.consentsForm.newsletter.radios.false'),
                  name: 'newsletter',
                  value: 'false',
                },
              ],
            },
          },
          profiling: {
            formGroup: {
              label: formatHtml(consentsContent?.body.profiling?.label),
              names: ['profiling'],
              omitForAttribute: true,
            },
            radios: {
              radios: [
                {
                  id: 'profiling-true',
                  inline: true,
                  label: t('form.consentsForm.profiling.radios.true'),
                  name: 'profiling',
                  value: 'true',
                },
                {
                  id: 'profiling-false',
                  inline: true,
                  label: t('form.consentsForm.profiling.radios.false'),
                  name: 'profiling',
                  value: 'false',
                },
              ],
            },
          },
        };
        return children ? (
          children({ closeModal, content: consentsContent, fieldsHelper, formik, insideModal })
        ) : (
          <>
            {consentsContent?.body.marketing?.enabled && (
              <FormGroup {...fieldsHelper.marketing.formGroup}>
                <Radios {...fieldsHelper.marketing.radios} />
              </FormGroup>
            )}
            {consentsContent?.body.profiling?.enabled && (
              <FormGroup {...fieldsHelper.profiling.formGroup}>
                <Radios {...fieldsHelper.profiling.radios} />
              </FormGroup>
            )}
            {consentsContent?.body.newsletter?.enabled && (
              <FormGroup {...fieldsHelper.newsletter.formGroup}>
                <Radios {...fieldsHelper.newsletter.radios} />
              </FormGroup>
            )}
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type ConsentsFormVariants = '';

const ConsentsFormController = (props: PropsWithCustomComponentWithoutChildren<ConsentsFormProps>) => (
  <VariantsController<ConsentsFormProps, ConsentsFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ConsentsFormHelper,
      name: 'ConsentsForm',
    }}
  />
);
export { ConsentsFormController as ConsentsForm };
