import { useAppContext } from './AppContext';
import { Button } from './Button';
import { CartOffcanvasProps } from './CartOffcanvas';
import { CartPacks } from './CartPacks';
import { appConfig } from './config';
import { Container } from './Container';
import { FreeShippingThreshold } from './FreeShippingThreshold';
import { Div, H3, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { InstallmentPaymentBanner } from './InstallmentPaymentBanner';
import { OffcanvasBody } from './Offcanvas';
import { Link } from './router/Link';
import { formatCurrency, formatHtml, renderUI } from './util';

export const CartOffcanvasA = ({ close, footerMessage, freeShippingThreshold, title }: CartOffcanvasProps) => {
  const { session } = useAppContext();

  return renderUI({
    bs5: (
      <>
        <Div className={'offcanvas-header-wrapper'} uiClassName={{ bs5: 'p-3 pb-0 px-md-5' }}>
          <Div className={'offcanvas-header'} uiClassName={{ bs5: 'p-0 pb-3 border-bottom' }}>
            <H3 className="offcanvas-title">
              {title ?? t('cart.title')}
              {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
                <Span dynamicMargin={{ start: 2 }} uiClassName={{ bs5: 'align-middle text-primary h6' }}>
                  ({session.cart.itemsNumber})
                </Span>
              )}
            </H3>
            <Button
              aria-label="Close"
              data-bs-dismiss="offcanvas"
              iconEnd={appConfig.icons ? { name: appConfig.icons.close } : undefined}
              type="button"
              variant="blank"
            />
          </Div>
        </Div>
        <OffcanvasBody className="p-3 px-md-5">
          {session?.cart?.itemsNumber && session.cart.itemsNumber > 0 ? (
            <>
              {freeShippingThreshold?.enabled && (
                <Container className="g-0">
                  <Div marginBottom={3} uiClassName={{ bs5: 'border-bottom' }}>
                    <FreeShippingThreshold
                      cart={session.cart}
                      enableContinueShopping={freeShippingThreshold.enableContinueShopping}
                      freeShippingThresholdCriterion={freeShippingThreshold.freeShippingThresholdCriterion}
                      padding={0}
                    />
                  </Div>
                </Container>
              )}
              <Container className="g-0">{session.cart.packs && <CartPacks packs={session.cart.packs} />}</Container>
              {session.cart.productsCost && (
                <Div
                  className="cart-offcanvas-total"
                  display={'flex'}
                  justifyContent={'spaceBetween'}
                  paddingY={3}
                  uiClassName={{ bs5: 'border-top' }}
                >
                  <strong>{t('cart.summary.totalCost')}</strong>
                  <strong>{formatCurrency(session.cart.productsCost)}</strong>
                </Div>
              )}
              <Div display={'grid'} paddingY={3}>
                <Link className="btn btn-primary" onClick={close} to="/cart">
                  {t('cart.proocedToCart')}
                </Link>
                {appConfig.installmentPaymentBanner && (
                  <Div marginTop={3}>
                    <InstallmentPaymentBanner amount={session.cart.productsCost} />
                  </Div>
                )}
              </Div>
              {session.cart.shippingProfile?.freeThreshold !== undefined &&
                session.cart.shippingProfile.freeThreshold > 0 && (
                  <Div className="cart-offcanvas-to-free-shipping" paddingTop={2} textAlign={'center'}>
                    <strong>
                      {t('cart.summary.freeShippingFrom', {
                        freeShippingFromAmount: formatCurrency(session.cart.shippingProfile.freeThreshold),
                      })}
                    </strong>
                  </Div>
                )}
              {footerMessage && <Div className="footer-message">{formatHtml(footerMessage)}</Div>}
            </>
          ) : (
            t('cart.empty.title')
          )}
        </OffcanvasBody>
      </>
    ),
  });
};
