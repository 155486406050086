import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Container } from './Container';
import { ArticleBoxLandingTemplateAContentType } from './contentTypes';

interface ArticleBoxProps extends ArticleBoxLandingTemplateAContentType {}

export const ArticleBox = ({ backgroundColor, cta, description, img, title }: ArticleBoxProps) => {
  return (
    <div className={classNames('m-3 px-lg-5 px-3 py-5', `bg-${backgroundColor}`)}>
      <Container>
        <b2x.Row className="px-lg-5 px-0">
          <b2x.Col className="text-center m-auto" size={{ lg: 6, xs: 12 }}>
            <b2x.ImageFromContent {...img} fluid />
          </b2x.Col>
          <b2x.Col className="text-center m-auto" size={{ lg: 6, xs: 12 }}>
            {title && <h2 className="mt-lg-0 mt-4 text-blue">{title}</h2>}
            {description && <p className="mt-4">{b2x.formatHtml(description)}</p>}
            {cta && (
              <b2x.DeprecatedCta className="btn btn-outline-primary mt-4" cta={cta}>
                {cta.label}
              </b2x.DeprecatedCta>
            )}
          </b2x.Col>
        </b2x.Row>
      </Container>
    </div>
  );
};
