import { GiftCardApiDto } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { Button } from '../Button';
import { Col } from '../Col';
import { Div } from '../HTMLElement';
import { t } from '../i18n/i18n';
import { Row } from '../Row';
import { Table } from '../Table';
import { TableBody } from '../TableBody';
import { TableHead } from '../TableHead';
import { useModals } from '../useModals';
import { formatCurrency, formatDate, renderUI } from '../util';
import { TextInput } from './fields/Input';
import { FormGroup } from './FormGroup';
import { GiftCardFormHelper, GiftCardFormProps } from './GiftCardForm';

export const GiftCardFormA = (props: GiftCardFormProps) => {
  const { showModal } = useModals();
  const handleBalanceCheckSuccess = React.useCallback(
    (giftCard: GiftCardApiDto) => {
      showModal({
        children: <GiftCardModal giftCard={giftCard} />,
        size: 'large',
        title: t('misc.giftCard.balance.title'),
      });
    },
    [showModal]
  );
  return renderUI({
    bs5: (
      <GiftCardFormHelper {...props} onBalanceCheckSuccess={handleBalanceCheckSuccess}>
        {({ fieldsHelper }) => (
          <Row cols={1}>
            <Col>
              <div className="d-block form-label">{t('form.giftCardForm.code.label')}</div>
              <Div marginBottom={2}>
                <FormGroup {...fieldsHelper.code.formGroup} label={undefined} noMarginBottom>
                  <TextInput {...fieldsHelper.code.textInput} />
                </FormGroup>
              </Div>
            </Col>
            <Col className="d-flex justify-content-end">
              <Button {...fieldsHelper.buttons.balanceCheck} className="fw-semi-bold extra-small p-0" variant="blank" />
              <div className="align-self-center opacity-100 vr mx-3"></div>
              <Button {...fieldsHelper.buttons.submit} className="fw-semi-bold extra-small" variant="blank" />
            </Col>
          </Row>
        )}
      </GiftCardFormHelper>
    ),
  });
};

interface GiftCardModalProps {
  giftCard: GiftCardApiDto;
}

const GiftCardModal = ({ giftCard }: GiftCardModalProps) => {
  return renderUI({
    bs5: (
      <div className="table-responsive">
        <Table>
          <TableHead>
            <tr>
              <th>{t('misc.giftCard.label')}</th>
              <th>{t('misc.giftCard.balance.value')}</th>
              <th>{t('misc.giftCard.balance.activation')}</th>
            </tr>
          </TableHead>
          <TableBody>
            <tr>
              <td>{giftCard.code}</td>
              <td>{giftCard.balance && formatCurrency(giftCard.balance.value)}</td>
              <td>{giftCard.activationDate && formatDate(giftCard.activationDate, 'L')}</td>
            </tr>
          </TableBody>
        </Table>
      </div>
    ),
  });
};
