import { appConfig } from '../config';
import { addScript, environment, slugify } from '../util';
import { AnalyticsEventsProps, InitCommonProps } from './analytics';
import {
  Event,
  EventParameters,
  fromProductSkusToItems,
  fromProductSkuToItem,
  fromProductSkuWithQuantityToItem,
  fromProductsSkusToItems,
} from './googleAnalytics4';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/ban-types
    dataLayer: Array<string | Object>;
  }
}

let initialized = false;
let debug = false;

interface InitProps extends InitCommonProps {
  id: string;
}
export type GoogleTagManagerInitProps = InitProps;

const init = (props: InitProps): void => {
  if (!initialized) {
    debug = props.debug;
    initialized = true;
    install(props.id);
  }
};

const install = (id: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.dataLayer = window.dataLayer || [];

  // Inizio gestione valori di default per Google Consent Mode v2
  if (appConfig.consentMode) {
    gtag('consent', 'default', {
      ad_personalization: 'denied',
      ad_storage: 'denied',
      ad_user_data: 'denied',
      analytics_storage: 'denied',
      functionality_storage: 'denied',
      personalization_storage: 'denied',
      security_storage: 'granted',
      wait_for_update: 500,
    });
    gtag('set', 'ads_data_redaction', true);
    gtag('set', 'url_passthrough', true);
  }
  // Fine gestione valori di default per Google consent Mode v2

  addScript({
    id: 'gtm',
    text: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${id}');
    `,
  });
};

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, func-style
function gtag(...args: Array<unknown>) {
  if (initialized) {
    // eslint-disable-next-line prefer-rest-params
    window.dataLayer.push(arguments);
    log(args);
  } else {
    log('Blocked because not initialized.', args);
  }
}

const sendEvent = (event: Event, parameters?: EventParameters) => {
  if (environment === 'PRODUCTION') {
    if (initialized) {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        ecommerce: parameters,
        event: event,
      });
      log(event, parameters);
    } else {
      log('Blocked because not initialized.', event, parameters);
    }
  } else {
    log('Blocked because not production environment.', event, parameters);
  }
};

const log = (message?: unknown, ...optionalParams: Array<unknown>) => {
  if (debug) {
    console.log('analytics', 'gtm:', message, ...optionalParams);
  }
};

export const googleTagManager: {
  events: AnalyticsEventsProps;
  init(props: InitProps): void;
} = {
  events: {
    addPaymentInfo: (props) => {
      sendEvent('add_payment_info', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        payment_type: props.paymentType,
        value: props.totalCost,
      });
    },
    addShippingInfo: (props) => {
      sendEvent('add_shipping_info', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        shipping_tier: props.shippingProfile,
        value: props.totalCost,
      });
    },
    addToCart: (props) => {
      sendEvent('add_to_cart', {
        currency: 'EUR',
        items: [fromProductSkuWithQuantityToItem(props.product)],
        value: props.product.sku.price.value,
      });
    },
    addToWishlist: (props) => {
      sendEvent('add_to_wishlist', {
        currency: 'EUR',
        items: [fromProductSkuToItem(props.product)],
        value: props.product.sku.price.value,
      });
    },
    checkoutInit: (props) => {
      sendEvent('begin_checkout', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalCost,
      });
    },
    completeRegistration: (props) => {
      sendEvent('sign_up', { method: props.origin });
    },
    contact: (props) => {
      // Assente in gtm
    },
    customizeProduct: (props) => {
      // Assente in gtm
    },
    donate: (props) => {
      // Assente in gtm
    },
    exception: (props) => {
      // Assente in gtm
    },
    findLocation: (props) => {
      // Assente in gtm
    },
    lead: (props) => {
      sendEvent('generate_lead', {
        // currency: '',
        // value: 0,
      });
    },
    login: (props) => {
      sendEvent('login', {
        method: props.method,
      });
    },
    pageView: (props) => {
      // Assente in gtm
    },
    purchase: (props) => {
      sendEvent('purchase', {
        coupon: props.coupon?.code,
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        shipping: props.shippingCost,
        tax: props.taxCost,
        transaction_id: props.orderCode,
        value: props.totalCost,
      });
    },
    removeFromCart: (props) => {
      sendEvent('remove_from_cart', {
        currency: 'EUR',
        items: [fromProductSkuWithQuantityToItem(props.product)],
        value: props.product.sku.price.value,
      });
    },
    schedule: () => {
      // Assente in gtm
    },
    search: (props) => {
      sendEvent('search', {
        search_term: props.simpleSearch,
      });
    },
    selectProduct: (props) => {
      sendEvent('select_item', {
        item_list_id: slugify(props.listing.name),
        item_list_name: props.listing.name,
        items: fromProductSkusToItems(props.product),
      });
    },
    startPayment: (props) => {
      // Assente in gtm
    },
    submitApplication: () => {
      // Assente in gtm
    },
    viewCart: (props) => {
      sendEvent('view_cart', {
        currency: 'EUR',
        items: props.products.map((product) => fromProductSkuWithQuantityToItem(product)),
        value: props.totalCost,
      });
    },
    viewProduct: (props) => {
      sendEvent('view_item', {
        currency: 'EUR',
        items: fromProductSkusToItems(props.product),
        value: props.product.fromPrice.value,
      });
    },
    viewProducts: (props) => {
      sendEvent('view_item_list', {
        items: fromProductsSkusToItems(props.products),
        // item_list_id: '',
        // item_list_name: '',
      });
    },
  },
  init: (props) => {
    init(props);
  },
};
