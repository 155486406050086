import classnames from 'classnames';
import React from 'react';

import {
  Border,
  FontWeight,
  Margin,
  Padding,
  ResponsiveAlignContent,
  ResponsiveAlignItems,
  ResponsiveAlignSelf,
  ResponsiveDisplay,
  ResponsiveFlexDirection,
  ResponsiveFlexWrap,
  ResponsiveGap,
  ResponsiveJustifyContent,
  ResponsiveMarginSize,
  ResponsivePaddingSize,
  ResponsiveTextAlign,
  UiClassName,
} from './interfaces';
import {
  buildAlignContentClassNames,
  buildAlignItemsClassNames,
  buildAlignSelfClassNames,
  buildDisplayClassNames,
  buildDynamicSpacingClassNames,
  buildFlexDirectionClassNames,
  buildFlexWrapClassNames,
  buildFontWeightClassNames,
  buildGapClassNames,
  buildJustifyContentClassNames,
  buildSpacingClassNames,
  buildTextAlignClassNames,
  renderUI,
} from './util';

export interface HTMLElementProps {
  alignContent?: ResponsiveAlignContent;
  alignItems?: ResponsiveAlignItems;
  alignSelf?: ResponsiveAlignSelf;
  background?: string;
  border?: Border;
  children?: React.ReactNode;
  className?: string;
  display?: ResponsiveDisplay;
  dynamicMargin?: Margin;
  dynamicPadding?: Padding;
  flexDirection?: ResponsiveFlexDirection;
  flexWrap?: ResponsiveFlexWrap;
  fontWeight?: FontWeight;
  gap?: ResponsiveGap;
  id?: string;
  innerRef?: React.Ref<HTMLElement>;
  justifyContent?: ResponsiveJustifyContent;
  margin?: ResponsiveMarginSize;
  marginBottom?: ResponsiveMarginSize;
  marginEnd?: ResponsiveMarginSize;
  marginStart?: ResponsiveMarginSize;
  marginTop?: ResponsiveMarginSize;
  marginX?: ResponsiveMarginSize;
  marginY?: ResponsiveMarginSize;
  padding?: ResponsivePaddingSize;
  paddingBottom?: ResponsivePaddingSize;
  paddingEnd?: ResponsivePaddingSize;
  paddingStart?: ResponsivePaddingSize;
  paddingTop?: ResponsivePaddingSize;
  paddingX?: ResponsivePaddingSize;
  paddingY?: ResponsivePaddingSize;
  style?: React.CSSProperties;
  tag: keyof JSX.IntrinsicElements;
  textAlign?: ResponsiveTextAlign;
  uiClassName?: UiClassName;
}

export const HTMLElement = ({
  alignContent,
  alignItems,
  alignSelf,
  background,
  border,
  children,
  className,
  display,
  dynamicMargin,
  dynamicPadding,
  flexDirection,
  flexWrap,
  fontWeight,
  gap,
  id,
  innerRef,
  justifyContent,
  margin,
  marginBottom,
  marginEnd,
  marginStart,
  marginTop,
  marginX,
  marginY,
  padding,
  paddingBottom,
  paddingEnd,
  paddingStart,
  paddingTop,
  paddingX,
  paddingY,
  style,
  tag,
  textAlign,
  uiClassName,
  ...otherProps
}: HTMLElementProps) => {
  const asBackgroundColor = background?.startsWith('#') && (background.length === 7 || background.length === 4);

  return renderUI({
    bs5: React.createElement(
      tag,
      {
        className: classnames(
          className,
          display !== undefined && buildDisplayClassNames(display),
          fontWeight !== undefined && buildFontWeightClassNames(fontWeight),
          textAlign !== undefined && buildTextAlignClassNames(textAlign),
          padding !== undefined && buildSpacingClassNames('p', padding),
          paddingTop !== undefined && buildSpacingClassNames('p', paddingTop, 'top'),
          paddingBottom !== undefined && buildSpacingClassNames('p', paddingBottom, 'bottom'),
          paddingEnd !== undefined && buildSpacingClassNames('p', paddingEnd, 'end'),
          paddingStart !== undefined && buildSpacingClassNames('p', paddingStart, 'start'),
          paddingX !== undefined && buildSpacingClassNames('p', paddingX, 'horizontal'),
          paddingY !== undefined && buildSpacingClassNames('p', paddingY, 'vertical'),
          margin !== undefined && buildSpacingClassNames('m', margin),
          marginTop !== undefined && buildSpacingClassNames('m', marginTop, 'top'),
          marginBottom !== undefined && buildSpacingClassNames('m', marginBottom, 'bottom'),
          marginEnd !== undefined && buildSpacingClassNames('m', marginEnd, 'end'),
          marginStart !== undefined && buildSpacingClassNames('m', marginStart, 'start'),
          marginX !== undefined && buildSpacingClassNames('m', marginX, 'horizontal'),
          marginY !== undefined && buildSpacingClassNames('m', marginY, 'vertical'),
          dynamicMargin !== undefined && buildDynamicSpacingClassNames('m', dynamicMargin),
          dynamicPadding !== undefined && buildDynamicSpacingClassNames('p', dynamicPadding),
          justifyContent !== undefined && buildJustifyContentClassNames(justifyContent),
          alignContent !== undefined && buildAlignContentClassNames(alignContent),
          alignItems !== undefined && buildAlignItemsClassNames(alignItems),
          alignSelf !== undefined && buildAlignSelfClassNames(alignSelf),
          flexDirection !== undefined && buildFlexDirectionClassNames(flexDirection),
          flexWrap !== undefined && buildFlexWrapClassNames(flexWrap),
          gap !== undefined && buildGapClassNames(gap),
          uiClassName?.bs5 !== undefined && uiClassName.bs5,
          // Da migliorare la gestione del background
          { [`bg-${background}`]: !asBackgroundColor && background }
        ),
        id: id,
        ref: innerRef,
        style: {
          backgroundColor: asBackgroundColor ? background : undefined,
          border: border ? `${border.width}px ${border.style} ${border.color}` : undefined,
          ...style,
        },
        ...otherProps,
      },
      children
    ),
  });
};

export interface DivProps extends Omit<HTMLElementProps, 'tag'> {}
export const Div = ({ ...otherProps }: DivProps) => <HTMLElement tag={'div'} {...otherProps} />;

export interface SpanProps extends Omit<HTMLElementProps, 'tag'> {}
export const Span = ({ ...otherProps }: SpanProps) => <HTMLElement tag={'span'} {...otherProps} />;

export interface H1Props extends Omit<HTMLElementProps, 'tag'> {}
export const H1 = ({ ...otherProps }: H1Props) => <HTMLElement tag={'h1'} {...otherProps} />;

export interface H2Props extends Omit<HTMLElementProps, 'tag'> {}
export const H2 = ({ ...otherProps }: H2Props) => <HTMLElement tag={'h2'} {...otherProps} />;

export interface H3Props extends Omit<HTMLElementProps, 'tag'> {}
export const H3 = ({ ...otherProps }: H3Props) => <HTMLElement tag={'h3'} {...otherProps} />;

export interface H4Props extends Omit<HTMLElementProps, 'tag'> {}
export const H4 = ({ ...otherProps }: H4Props) => <HTMLElement tag={'h4'} {...otherProps} />;

export interface H5Props extends Omit<HTMLElementProps, 'tag'> {}
export const H5 = ({ ...otherProps }: H5Props) => <HTMLElement tag={'h5'} {...otherProps} />;

export interface H6Props extends Omit<HTMLElementProps, 'tag'> {}
export const H6 = ({ ...otherProps }: H6Props) => <HTMLElement tag={'h6'} {...otherProps} />;

export interface PProps extends Omit<HTMLElementProps, 'tag'> {}
export const P = ({ ...otherProps }: PProps) => <HTMLElement tag={'p'} {...otherProps} />;

export interface UlProps extends Omit<HTMLElementProps, 'tag'> {}
export const Ul = ({ ...otherProps }: UlProps) => <HTMLElement tag={'ul'} {...otherProps} />;

export interface OlProps extends Omit<HTMLElementProps, 'tag'> {}
export const Ol = ({ ...otherProps }: OlProps) => <HTMLElement tag={'ol'} {...otherProps} />;

export interface LiProps extends Omit<HTMLElementProps, 'tag'> {}
export const Li = ({ ...otherProps }: LiProps) => <HTMLElement tag={'li'} {...otherProps} />;

// export interface B2xButtonProps extends Omit<HTMLElementProps, 'tag'>, React.ButtonHTMLAttributes<HTMLButtonElement> {}
// export const B2xButton = ({ ...otherProps }: B2xButtonProps) => <HTMLElement tag={'button'} {...otherProps} />;
