// eslint-disable-next-line no-restricted-imports
import { api, InitPaymentRequestApiDto, RetryPaymentRequestApiDto } from '@b2x/storefront-api-js-client/src';
import { GetOrderOptions, SearchOrdersOptions } from '@b2x/storefront-api-js-client/src/orders';
import React from 'react';

import { ApiRequestConfig, useApiRequest } from './useApiRequest';
import { useCartApi } from './useCartApi';

export const useOrdersApi = () => {
  const { apiRequest } = useApiRequest();
  const { updateSessionCart } = useCartApi();

  const getOrder = React.useCallback(
    (id: string, options?: GetOrderOptions, config?: ApiRequestConfig) =>
      apiRequest(api.orders.get(id, options), { silent: false, ...config }),
    [apiRequest]
  );

  const searchOrders = React.useCallback(
    (options?: SearchOrdersOptions, config?: ApiRequestConfig) =>
      apiRequest(api.orders.search(options), { silent: false, ...config }),
    [apiRequest]
  );

  // const getOrderByCode = React.useCallback(
  //   (code: string, options?: GetOrderOptions, config?: ApiRequestConfig) =>
  //     apiRequest(api.orders.getByCode(code, options), { silent: false, ...config }),
  //   [apiRequest]
  // );

  const getOrderByCodeAndEmail = React.useCallback(
    (code: string, email: string, options?: GetOrderOptions, config?: ApiRequestConfig) =>
      apiRequest(api.orders.getByCodeAndEmail(code, email, options), { silent: false, ...config }),
    [apiRequest]
  );

  const getOrderByToken = React.useCallback(
    (token: string, options?: GetOrderOptions, config?: ApiRequestConfig) =>
      apiRequest(api.orders.getOrderByToken(token, options), { silent: false, ...config }),
    [apiRequest]
  );

  const initPayment = React.useCallback(
    (data: InitPaymentRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.orders.initPayment(data), { silent: false, ...config }).then((response) => {
        // Aggiorno la sessione solo se è direct, se no è inutile dato che esco momentaneamente dal sito
        // (anzi... vedrei la pagina aggiornarsi poco prima di abbandonarla)
        if (response.data.direct) {
          return updateSessionCart({ silent: false }).then(() => {
            return response;
          });
        } else {
          return response;
        }
      }),
    [apiRequest, updateSessionCart]
  );

  const retryPayment = React.useCallback(
    (data: RetryPaymentRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.orders.retryPayment(data), { silent: false, ...config }).then((response) => {
        // Aggiorno la sessione solo se è direct, se no è inutile dato che esco momentaneamente dal sito
        // (anzi... vedrei la pagina aggiornarsi poco prima di abbandonarla)
        if (response.data.direct) {
          return updateSessionCart({ silent: false }).then(() => {
            return response;
          });
        } else {
          return response;
        }
      }),
    [apiRequest, updateSessionCart]
  );

  const getPaymentMethods = React.useCallback(
    (code: string, config?: ApiRequestConfig) => apiRequest(api.orders.getPaymentMethods(code), { ...config }),
    [apiRequest]
  );

  const getInvoiceFile = React.useCallback(
    (code: string, config?: ApiRequestConfig) => apiRequest(api.orders.getInvoiceFile(code), { ...config }),
    [apiRequest]
  );

  return {
    getInvoiceFile,
    getOrder,
    getOrderByCodeAndEmail,
    getOrderByToken,
    getPaymentMethods,
    initPayment,
    retryPayment,
    searchOrders,
  };
};
