import './ArticleModal.scss';

import { b2x } from '@b2x/react/src';

import { Button } from './Button';
import { PopupArticlePageContentType } from './contentTypes';

interface ArticleModalProps extends b2x.ModalProps {}

const ArticleModal = ({ ...modalProps }: ArticleModalProps) => {
  const content = b2x.useContent<PopupArticlePageContentType>('POPUP_ARTICLE_PAGE_CONTENT');

  return (
    <b2x.Modal {...modalProps} className="article-modal">
      {({ close }) => (
        <>
          <div className="modal-header bg-primary mb-4 text-white px-3 py-2">
            <div className="w-100 d-flex flex-nowrap flex-row align-items-lg-center align-items-start justify-content-between">
              <div className="d-flex flex-column flex-lg-row">
                <div className="ps-xl-3 text-uppercase">
                  {content?.body.modalHeader?.leftText && b2x.formatHtml(content.body.modalHeader.leftText)}
                </div>
                <div className="cs-pd text-uppercase">
                  {content?.body.modalHeader?.centerText && b2x.formatHtml(content.body.modalHeader.centerText)}
                </div>
              </div>

              <div className="">
                <Button className="text-white" iconEnd={{ name: 'delete' }} onClick={close} variant="blank" />
              </div>
            </div>
          </div>
          <b2x.ModalBody>
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-3 d-none d-lg-block">
                  {content?.body.modalBody?.logo && (
                    <b2x.ImageFromContent
                      alt={content.body.modalBody.logo.alt}
                      className="ps-xl-3"
                      fluid
                      src={content.body.modalBody.logo.src}
                    />
                  )}
                </div>
                <div className="col-lg-9 col-md-12">
                  {content?.body.modalBody?.title && (
                    <h3 className="title text-blue mb-0">{b2x.formatHtml(content.body.modalBody.title)}</h3>
                  )}
                </div>
              </div>
              <div className="row mt-4 mt-lg-5">
                {content?.body.modalBody?.iconsList &&
                  content.body.modalBody.iconsList.length > 0 &&
                  content.body.modalBody.iconsList.map((iconItem, index) => (
                    <div className="col-lg-3 col-6 text-center mb-3" key={iconItem.contentSectionId}>
                      <b2x.router.Link className="text-decoration-none text-blue" closeModal to={iconItem.link}>
                        {iconItem.img?.src?.xs && (
                          <b2x.ImageFromContent alt={iconItem.img.alt} className="mb-3" fluid src={iconItem.img.src} />
                        )}
                        {iconItem.title && <h6 className="text-blue fw-bold">{b2x.formatHtml(iconItem.title)}</h6>}
                      </b2x.router.Link>
                    </div>
                  ))}
              </div>
            </div>
          </b2x.ModalBody>
          <div className="modal-footer mt-4 mt-lg-5">
            <div className="bg-gray-100 text-center pb-lg-5 pb-4 w-100 m-0">
              <b2x.CtaFromContent
                {...content?.body.modalFooter?.cta}
                ctaProps={{ button: { className: 'fw-bold btn-md lh-lg px-5' }, link: { closeModal: true } }}
                variant="primary"
              />
            </div>
          </div>
        </>
      )}
    </b2x.Modal>
  );
};

export const useArticleModal = (props: ArticleModalProps) => b2x.useStaticModal(ArticleModal, 'modal', props);
