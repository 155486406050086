import { OrderApiDto, PaymentMethodApiDto, PaymentMethodType } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useOrdersApi } from '../api/useOrdersApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { ResourceKeys, t } from '../i18n/i18n';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { addScript, getAbsoluteUrl } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { Radio, RadioProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikEnum,
  getFormikEnumValue,
  getInitialEnum,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface RestartPaymentFormProps
  extends Omit<BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector>, 'onSuccess'> {
  order: OrderApiDto;
}

interface FormValues {
  // checkbox: formikBoolean;
  paymentMethodType: formikEnum<PaymentMethodType>;
}

type ValidationSchema = {
  // checkbox: yup.BooleanSchema;
  paymentMethodType: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  // checkbox: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  paymentMethodType: {
    formFields: Array<{ paymentMethod: PaymentMethodApiDto; radio: RadioProps }>;
    formGroup: FormGroupProps;
  };
}

export const RestartPaymentFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  order,
  ...otherProps
}: RestartPaymentFormProps) => {
  const [paymentMethods, setPaymentMethods] = React.useState<Array<PaymentMethodApiDto>>([]);

  const { getPaymentMethods } = useOrdersApi();

  React.useEffect(() => {
    getPaymentMethods(order.code).then((response) => {
      setPaymentMethods(response.data);
    });
  }, [getPaymentMethods, order.code]);

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      // checkbox: getInitialBoolean(),
      paymentMethodType: getInitialEnum(order.paymentMethod?.type),
      ...initialValues,
    }),
    [initialValues, order.paymentMethod?.type]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      // checkbox: yup.boolean().required().oneOf([true]),
      paymentMethodType: yup.string().required(),
    }),
    []
  );

  const { retryPayment } = useOrdersApi();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      retryPayment({
        orderCode: order.code,
        paymentKoCallbackUrl: getAbsoluteUrl('/order-ko'),
        paymentMethodType: getFormikEnumValue(values.paymentMethodType),
        paymentOkCallbackUrl: getAbsoluteUrl('/order-ok'),
      }).then((response) => {
        if (response.data.ok) {
          // analytics.events.startPayment(response.eventId, cart); // Da capire se chiamare analytics in questo caso.
          if (!response.data.direct) {
            if (response.data.paymentURL) {
              window.location.replace(response.data.paymentURL);
            } else {
              if (response.data.paymentMethodType === 'WORLDLINE_EASY_CHECKOUT') {
                const endpoint = response.data.properties['endpoint'] as string | undefined;
                const licenseKey = response.data.properties['licenseKey'] as string | undefined;
                const paymentId = response.data.properties['paymentId'] as string | undefined;
                if (endpoint && licenseKey && paymentId) {
                  addScript({
                    id: 'axepta',
                    src: `${endpoint}/sdk/axepta-pg-redirect.js`,
                  }).then(() => {
                    const axeptaClient = new AxeptaSDKClient(endpoint, licenseKey);
                    axeptaClient.proceedToPayment(paymentId);
                  });
                }
              } else {
                alert(`payment method type: ${response.data.paymentMethodType} not supported`);
              }
            }
          } else {
            alert('direct payment not enabled');
          }
        } else {
          alert('error');
        }
      }),
    [order.code, retryPayment]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('RestartPaymentForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.restartPaymentForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.restartPaymentForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          // checkbox: {
          //   checkbox: { id: 'checkbox', label: t('form.restartPaymentForm.checkbox.label'), name: 'checkbox' },
          //   formGroup: { names: ['checkbox'], omitForAttribute: true },
          // },
          paymentMethodType: {
            formFields: paymentMethods.map((paymentMethod) => ({
              paymentMethod: paymentMethod,
              radio: {
                className: 'small',
                id: paymentMethod.id,
                label: t(`paymentMethod.${paymentMethod.type}` as ResourceKeys),
                name: 'paymentMethodType',
                value: paymentMethod.type,
              },
            })),
            formGroup: {
              names: ['paymentMethodType'],
              omitForAttribute: true,
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.paymentMethodType.formGroup}>
              {fieldsHelper.paymentMethodType.formFields.map(({ radio }) => (
                <Radio key={radio.id} {...radio} />
              ))}
            </FormGroup>
            {/* <FormGroup {...fieldsHelper.checkbox.formGroup}>
        <Checkbox {...fieldsHelper.checkbox.checkbox} />
      </FormGroup> */}
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type RestartPaymentFormVariants = '';

const RestartPaymentFormController = (props: PropsWithCustomComponentWithoutChildren<RestartPaymentFormProps>) => (
  <VariantsController<RestartPaymentFormProps, RestartPaymentFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: RestartPaymentFormHelper,
      name: 'RestartPaymentForm',
    }}
  />
);
export { RestartPaymentFormController as RestartPaymentForm };
