import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Button, ButtonVariant } from './Button';
import { CustomRadio } from './CustomRadio';
import { t } from './i18n/i18n';
import { IconName, IconSize } from './Icon';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = (props: ProductTileProps) => {
  return <b2x.ProductTile className={'card border-0 my-3'} {...props} />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = ({
  handleLinkClick,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: ProductTileBasicInfoProps) => {
  const { bestPriceDiscountPercentage, bestPriceValue, crossedOutPrice, discountPercentage, isPriceInRange, price } =
    b2x.usePrice(product, sku?.id);
  const absorbency = product.attributes?.find((attribute) => attribute.typeCode === 'HAR_Gocce');

  const selectedSku = React.useMemo(
    () => product.skus?.find((productSku) => productSku.id === sku?.id),
    [product.skus, sku]
  );

  return (
    <div className="product-tile-basic-info">
      <div className="product-tile-image-container position-relative mb-3">
        <b2x.router.Link onClick={handleLinkClick} style={{ display: 'block' }} to={product.url}>
          <b2x.Image
            alt={product.name}
            aspectRatio={b2x.appConfig.productImageAspectRatio}
            className="rounded"
            fluid
            format={imageFormat}
            src={selectedSku?.image ? selectedSku.image.src : product.image?.src}
          />
        </b2x.router.Link>
        {b2x.appConfig.enableWishlist && (
          <div className="wishlist-action-container position-absolute end-0 top-0 p-2 p-lg-3">
            <b2x.WishlistButtonHelper product={product} sku={sku}>
              {({ handleWishlistButtonClick, inWishlist }) => (
                <Button
                  className="text-blue"
                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 25 }}
                  onClick={handleWishlistButtonClick}
                  size="large"
                  type="button"
                  variant="blank"
                />
              )}
            </b2x.WishlistButtonHelper>
          </div>
        )}
        {discountPercentage && (
          <div className="discount-percentage position-absolute end-0 top-0 p-2">
            <span className="bg-promo text-white rounded-circle extra-small fw-semi-bold">{`-${b2x.formatFloatPercentage(
              discountPercentage
            )}%`}</span>
          </div>
        )}
        {product.tags?.includes('HARTMANN_NEW') && (
          <div className="new-product-sticker position-absolute top-0 start-0 pt-3">
            <span className="bg-green text-white text-lowercase extra-small lh-1 py-1 px-2">
              {t('misc.newProductSticker')}
            </span>
          </div>
        )}
      </div>
      <b2x.EqualHeightElement name={`product-title-row-${productsPerRow}`}>
        <div className="mb-2">
          <h6 className="fw-semi-bold m-0">
            <b2x.router.Link className="text-decoration-none text-blue" onClick={handleLinkClick} to={product.url}>
              {b2x.formatHtml(product.name)}
            </b2x.router.Link>
          </h6>
          {selectedSku && <h6 className="extra-small mb-0 text-gray-400 mt-1">{selectedSku.name}</h6>}
        </div>
        {absorbency?.value && (
          <div className="absorbency mb-2 lh-1 d-flex flex-wrap">
            <div className=" drop-container flex-wrap d-flex align-items-center justify-content-start">
              {[...Array(10)].map((e, i) => (
                <div
                  className={classnames('drop', {
                    'drop-active': absorbency.value && parseInt(absorbency.value) > i,
                  })}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`absorbency-${i}`}
                ></div>
              ))}
            </div>
            <div className="ps-2 extra-small text-gray-400">
              <span className="d-none d-lg-block">
                {absorbency.value} {absorbency.title}
              </span>
              <span className="d-block d-lg-none">
                {`${absorbency.value}${absorbency.title && absorbency.title.charAt(0).toLowerCase()}`}
              </span>
            </div>
          </div>
        )}
        <div className="product-tile-price-block">
          <b2x.PriceBlock
            classNames={{ specialPrice: 'text-promo' }}
            gap={2}
            hiddenDiscountPercentage
            priceHelper={{
              bestPriceDiscountPercentage: bestPriceDiscountPercentage,
              bestPriceValue: bestPriceValue,
              crossedOutPrice: crossedOutPrice,
              discountPercentage: discountPercentage,
              isPriceInRange: isPriceInRange,
              price: price,
            }}
          />
          {b2x.appConfig.enableBestPrice && (
            <div className="extra-small lh-1">
              <b2x.BestPrice
                priceHelper={{
                  bestPriceDiscountPercentage: bestPriceDiscountPercentage,
                  bestPriceValue: bestPriceValue,
                  crossedOutPrice: crossedOutPrice,
                  price: price,
                }}
              />
            </div>
          )}
        </div>
      </b2x.EqualHeightElement>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({ fieldsHelper, productsPerRow, selectedSku }: ProductTileExtraInfoProps) => {
  return (
    <div className="product-tile-extra-info mt-3">
      <b2x.EqualHeightElement name={`product-tile-extra-info-${productsPerRow}`}>
        <div className="d-flex justify-content-between">
          {fieldsHelper.skus.formFields.length > 1 && (
            <div>
              <b2x.FormGroup {...fieldsHelper.skus.formGroup} label={undefined} noMarginBottom>
                {fieldsHelper.skus.formFields.map(({ radio, sku }, index) => (
                  <b2x.Radio key={sku.id} {...radio} className="mb-2" inline>
                    <CustomRadio sku={sku} />
                  </b2x.Radio>
                ))}
              </b2x.FormGroup>
            </div>
          )}
        </div>
      </b2x.EqualHeightElement>
      <div className="d-grid mt-2">
        {/* <b2x.Button
          className="btn btn-primary"
          type={'submit'}
          {...fieldsHelper.buttons.submit}
          label={t('form.addToCartForm.buttons.submit')}
        /> */}
        <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
          fieldsHelper={fieldsHelper}
          selectedSku={selectedSku}
          submitButton={{ className: 'btn btn-primary' }}
        />
      </div>
    </div>
  );
};
