import './LandingLoyaltyPage.scss';

import { b2x } from '@b2x/react/src';

import { Accordion } from '../Accordion';
import { useAppStaticContext } from '../AppContext';
import { Box } from '../Box';
import { Button } from '../Button';
import { Container } from '../Container';
import { LandingLoyaltyPageContentType } from '../contentTypes';
import { HeaderLanding } from '../HeaderLanding';
import { t } from '../i18n/i18n';
import { RowLanding } from '../RowLanding';
import { SliderCardA } from '../slider/SliderCardA';
import { Page } from './Page';
export interface LandingLoyaltyPageProps {}

export const LandingLoyaltyPage = (props: LandingLoyaltyPageProps) => {
  const page = b2x.usePage<LandingLoyaltyPageContentType>();
  const body = page?.content?.body;
  const header = body?.headerBanner;
  const { session } = b2x.useAppContext();
  const { showAccountOffcanvas } = useAppStaticContext();

  return (
    <Page className="landing-loyalty-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <HeaderLanding {...header} textColSize={{ md: 10, xl: 8, xs: 12 }} />
      <Container>
        <RowLanding className="pt-0 pt-lg-5 pb-3 row-landing-loyalty" {...body?.firstRow} />
      </Container>
      {body?.secondRow?.bgColor && (
        <div style={{ backgroundColor: body.secondRow.bgColor }}>
          <Container>
            <b2x.Row className="align-items-center">
              <b2x.Col className="order-lg-1 order-2 text-center text-lg-end" size={{ lg: 6, md: 12, xs: 12 }}>
                {body.secondRow.image && (
                  <b2x.ImageFromContent className="mt-3 mt-lg-5" {...body.secondRow.image} fluid />
                )}
              </b2x.Col>
              <b2x.Col className="order-1 order-lg-2" size={{ lg: 6, md: 12, xs: 12 }}>
                {body.secondRow.title && (
                  <div className="text-lg-start py-3 py-lg-0 px-xl-5">
                    <h4 className="text-blue">{b2x.formatHtml(body.secondRow.title)}</h4>
                    <div>{b2x.formatHtml(body.secondRow.content)}</div>
                    {body.secondRow.cta?.label && session?.customer ? (
                      <div className="mt-3 d-grid d-lg-block">
                        <b2x.CtaFromContent {...body.secondRow.cta} />
                      </div>
                    ) : (
                      <div className="mt-3 d-grid d-lg-block">
                        <Button label={t('misc.doubleOptionAccess')} onClick={showAccountOffcanvas} variant="primary" />
                      </div>
                    )}
                  </div>
                )}
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      )}

      <Container>
        {body?.thirdRow?.title && (
          <b2x.Row className="justify-content-center mt-3">
            <b2x.Col size={{ lg: 8, xs: 12 }}>
              {body.thirdRow.title && (
                <h2 className="text-blue text-center mt-5 mb-3">{b2x.formatHtml(body.thirdRow.title)}</h2>
              )}
            </b2x.Col>
          </b2x.Row>
        )}
        {body?.thirdRow?.image && (
          <b2x.Row className="justify-content-center d-none d-lg-flex">
            <b2x.Col size={{ lg: 6, xs: 12, xxl: 5 }}>
              <b2x.ImageFromContent {...body.thirdRow.image} fluid />
            </b2x.Col>
          </b2x.Row>
        )}

        <SliderCardA {...page?.content?.body.thirdRow?.fidelitySection} />

        {body?.thirdRow?.cta && session?.customer ? (
          <div className="mt-3 mb-5 d-grid d-lg-block">
            <b2x.CtaFromContent {...body.thirdRow.cta} />
          </div>
        ) : (
          <div className="mt-3 mb-5 d-grid d-lg-block text-center">
            <Button label={t('misc.doubleOptionAccess')} onClick={showAccountOffcanvas} variant="primary" />
          </div>
        )}
      </Container>

      <div style={{ backgroundColor: body?.lastRow?.bgColor }}>
        {body?.lastRow?.title && <h2 className="text-blue text-center py-5">{b2x.formatHtml(body.lastRow.title)}</h2>}

        {body?.lastRow?.questions && (
          <Container>
            <b2x.Row className="justify-content-center pb-5">
              <b2x.Col size={{ lg: 8, xs: 12 }}>
                <Box>
                  <Accordion
                    accordionColClassName="p-0 px-lg-2"
                    className="accordion-faq-loyalty"
                    id="AccordionFaqLoyalty"
                    itemBodyClassName="pt-0"
                  >
                    {body.lastRow.questions.map(
                      (item, firstIndex) =>
                        item.question && (
                          <b2x.AccordionItem
                            id={`accordionItem-${firstIndex}`}
                            key={item.question}
                            title={`${firstIndex + 1} - ${item.question}`}
                          >
                            <div className="pt-2">{b2x.formatHtml(item.answer)}</div>
                          </b2x.AccordionItem>
                        )
                    )}
                  </Accordion>
                </Box>
              </b2x.Col>
            </b2x.Row>
          </Container>
        )}
      </div>
    </Page>
  );
};
