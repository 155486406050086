import {
  AddressApiDto,
  ContentApiDto,
  CountryApiDto,
  CustomerApiDto,
  CustomerRequestApiDto,
  InfoApiDto,
  LocaleApiDto,
  MenuApiDto,
  ShopApiDto,
  SkuApiDto,
} from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { getUserLocales } from 'get-user-locale';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import ReactJson, { ReactJsonViewProps } from 'react-json-view';
import { PartialDeep as _PartialDeep } from 'type-fest';
import { v4 as uuidv4 } from 'uuid';

import { appConfig, UI } from './config';
import { env } from './env';
import { t } from './i18n/i18n';
import {
  Breakpoint,
  FontWeight,
  ImageFormat,
  Margin,
  Padding,
  ResponsiveAlignContent,
  ResponsiveAlignItems,
  ResponsiveAlignSelf,
  ResponsiveDisplay,
  ResponsiveFlexDirection,
  ResponsiveFlexWrap,
  ResponsiveGap,
  ResponsiveJustifyContent,
  ResponsiveMarginSize,
  ResponsivePaddingSize,
  ResponsiveTextAlign,
  SpacingSides,
  UiClassName,
} from './interfaces';
import { Location } from './router/router';
import { staticVariables } from './staticVariables';
import { storage } from './storage';

export const formatDate = (date: Date, format = 'L') => moment(date).format(format);
export const parseDate = (date: string, format: string, locale?: string): Date => {
  const x = moment(date, format, locale);
  const y = x.toDate();
  return y;
  // return moment(date, format, locale).toDate();
};

export const formatDateForInput = (date?: Date) => (date ? formatDate(date, 'YYYY-MM-DD') : '');
export const parseDateFromInput = (date?: string): Date | undefined =>
  date ? parseDate(date, 'YYYY-MM-DD') : undefined;

export const parseDateFromContent = (date?: string): Date | undefined =>
  date ? parseDate(date, 'DD/MM/YYYY') : undefined;
export const formatDateFromContent = (string?: string, format?: string): string | undefined => {
  const date = parseDateFromContent(string);
  return date ? formatDate(date, format) : undefined;
};

export const parseDateTimeFromContent = (date?: string): Date | undefined =>
  date ? parseDate(date, 'DD/MMM/YYYY HH:mm:ss', 'it') : undefined;
export const formatDateTimeFromContent = (string?: string, format?: string): string | undefined => {
  const date = parseDateTimeFromContent(string);
  return date ? formatDate(date, format) : undefined;
};

// export const parseDateTimeFromContent = (date?: string): Date | undefined =>
// date ? parseDate(date, '???') : undefined;

export const formatDateTimeForInput = (date?: Date) => (date ? formatDate(date, 'YYYY-MM-DDTHH:mm') : '');
export const parseDateTimeFromInput = (date?: string): Date | undefined =>
  date ? parseDate(date, 'YYYY-MM-DDTHH:mm') : undefined;

export const renderUI = (props: Record<UI, React.ReactElement>) => {
  return props[appConfig.ui];
};

export const wait = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

export const prettyStringify = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  object?: object,
  options?: Partial<ReactJsonViewProps>
) => {
  const defaultOptions: Partial<ReactJsonViewProps> = {
    collapsed: false,
    displayDataTypes: false,
    displayObjectSize: false,
    enableClipboard: false,
    name: null,
    quotesOnKeys: false,
  };
  return <>{object && <ReactJson {...defaultOptions} collapsed={options?.collapsed} src={object} />}</>;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const stringify = (object?: object) => (
  <code>
    <pre>{JSON.stringify(object, undefined, 2)}</pre>
  </code>
);

/**
 * A helper to create a Context and Provider with no upfront default value, and
 * without having to check for undefined all the time.
 */
export const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  // no upfront default value, not having to check for undefined all the time.
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  // no upfront default value, but having to check for undefined all the time.
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

/**
 * A helper to create a Context and Provider with an initial value
 */
export const createContextWithInitialValue = <T,>(initialValue: T, displayName: string) => {
  const Context = React.createContext<T>(initialValue);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext] as const;
};

export type PartialDeep<T> = _PartialDeep<T>;

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...Array<0>];
export type Leaves<T, D extends number = 10> = [D] extends [never]
  ? never
  : // eslint-disable-next-line @typescript-eslint/ban-types
  T extends object
  ? { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T]
  : '';

export const createUUID = () => uuidv4();

export const formatHtml = (html?: string): React.ReactElement => {
  const parsedHtml = html ? ReactHtmlParser(html) : undefined;
  // let countBr = 0;
  // parsedHtml &&
  //   parsedHtml.forEach((element, elementIndex) => {
  //     if (element.type === 'span' || element.type === 'p') {
  //       if (element.props.style) {
  //         element.props.style.fontSize = '';
  //         parsedHtml.splice(elementIndex, 1, element);
  //       }
  //       countBr = 0;
  //     } else if (element.type === 'br') {
  //       if (countBr > 2) {
  //         parsedHtml.splice(elementIndex, 1);
  //       }
  //       countBr++;
  //       if (elementIndex === parsedHtml.length - 1) {
  //         parsedHtml.splice(parsedHtml.length - countBr, countBr);
  //       }
  //     } else {
  //       countBr = 0;
  //     }
  //   });
  return <>{parsedHtml}</>;
};

export const boldWordInText = (text: string, word?: string): string => {
  if (!word) {
    return text;
  }

  const regex = new RegExp(word, 'gi');
  return text.replace(regex, (match) => `<b>${match}</b>`);
};

export const formatCurrency = (
  price: number,
  options?: {
    labelIfZero?: string;
    showLabelIfZero?: boolean;
  }
): string => {
  return options?.showLabelIfZero && price === 0
    ? options.labelIfZero ?? t('misc.gratis')
    : new Intl.NumberFormat(staticVariables.locale?.code, {
        // currency: staticVariables.shippingCountry?.currencyCode,
        currency: 'EUR', // Da gestire, va preso da una fonte statica alimentata una volta per hard-refresh, in modo da lasciare formatCurrency accessibile staticamente.
        style: 'currency',
      }).format(price);
};

export const formatWeight = (weightInKilos: number): string => `${weightInKilos} kg`;

export const formatFloatPercentage = (percentage: number) => {
  return Math.round(percentage * 10000.0) / 100.0;
};

export const percentageOf = (x: number, y: number, precision: number): number =>
  Number(((x / y) * 100).toFixed(precision));

export interface FormatAddressOptions {
  withCountry?: boolean;
  withName?: boolean;
}

export const formatAddress = (address: AddressApiDto, options?: FormatAddressOptions) => {
  const { withCountry = false, withName = false } = options ?? {};
  return (
    <>
      {(address.name || address.surname) && withName && (
        <b>
          {address.name} {address.surname}
        </b>
      )}
      {address.addressLine1 && ` ${address.addressLine1}`}
      {address.civicNumber && ` ${address.civicNumber}`}
      {address.zipCode && `, ${address.zipCode}`}
      {address.city && `, ${address.city}`}
      {address.province && ` (${address.province.code})`}
      {address.country && withCountry && `, ${address.country.name}`}
    </>
  );
};

export const useStable = <T,>(object: T) => {
  return React.useRef(object).current;
};

export const useStable2 = <T,>(object: T, deps?: React.DependencyList): T => {
  const ref = React.useRef<T>(object);
  React.useEffect(() => {
    ref.current = object;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
  return ref.current;
};

// Massimo comun divisore -> greatest common divisor (gcd)
export const gcd = (a: number, b: number): number => {
  return b === 0 ? a : gcd(b, a % b);
};

export const getAspectRatio = (width: number, height: number) => {
  const w = Math.floor(width);
  const h = Math.floor(height);
  const r = gcd(w, h);

  return [w / r, h / r];
};

// Metodi deprecati in favore dell'unica variabile "environment"
// export const isDevelopmentEnvironment = () => env.NODE_ENV === 'development';
// export const isTestEnvironment = () => env.NODE_ENV === 'test';
// export const isProductionEnvironment = () => env.NODE_ENV === 'production';

/*
  LOCAL: quando gira in locale (localhost:3000)
  DEVELOPMENT: quando gira su glassfish (localhost:8080/store-qualcosa)
  TEST: quando gira in collaudo (store-qualcosa-test.b2x.it)
  PRODUCTION: quando gira in produzione (store-qualcosa.b2x.it)
*/
export const environment = env.REACT_APP_B2X_ENV;

export const isTypeFromValuesUnion = <T extends string>(value: string, values: ReadonlyArray<string>): value is T => {
  const result: boolean = values.includes(value as T);
  return result;
};

export const slugify = (string: string) => _.kebabCase(string);

export const calculatePaddingTopForAspectRatioHack = (aspectRatio: number): string => {
  return `${(1 / aspectRatio) * 100}%`;
};

export const getImageSrc = (path?: string, format?: ImageFormat): string => {
  if (path === undefined) {
    return '#';
  }
  let src = path;
  if (format) {
    src += `?imageFormat=${format}`;
  }
  return src;
};

export const getCssVariable = (name: string): string => {
  return getComputedStyle(document.body).getPropertyValue(`--${name}`);
};

export const getCssVariableAsNumber = (name: string): number => {
  return parseInt(getCssVariable(name));
};

export const getBreakpointValue = (breakpoint: Breakpoint): number => {
  return parseInt(getCssVariable(`breakpoint-${breakpoint}`));
};

export const elementsJoinerReducer = (separator: React.ReactElement | string) => {
  const reducer = (accumulator: React.ReactElement, currentValue: React.ReactElement) => (
    <>
      {accumulator}
      {separator}
      {currentValue}
    </>
  );
  return reducer;
};

export const rgbToHex = (red: number, green: number, blue: number) => {
  const colorToHex = (color: number) => {
    const hex = color.toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  return `#${colorToHex(red)}${colorToHex(green)}${colorToHex(blue)}`;
};

export const toHex = (value: unknown) => {
  if (typeof value === 'string') {
    if (value.startsWith('rgb(')) {
      // rgb(255, 255, 255)
      const stringToSplit = value.substring(4, value.length - 1);
      const rgbArray = stringToSplit
        .split(',')
        .map((color) => color.trim())
        .map((color) => parseInt(color));
      return rgbToHex(rgbArray[0], rgbArray[1], rgbArray[2]);
    }
  }
};

export const isValidHexColor = (string: string): boolean => {
  return string.match(/^#[0-9A-Fa-f]{6}$/) ? true : false;
};

export const getBreakpoints = (): Record<Breakpoint, number> => {
  return {
    lg: getBreakpointValue('lg'),
    md: getBreakpointValue('md'),
    sm: getBreakpointValue('sm'),
    xl: getBreakpointValue('xl'),
    xs: getBreakpointValue('xs'),
    xxl: getBreakpointValue('xxl'),
  };
};

export const getSortedBreakpoints = (): Array<[Breakpoint, number]> => {
  return Object.entries(getBreakpoints()).sort(([, a], [, b]) => {
    return a > b ? 1 : -1;
  }) as Array<[Breakpoint, number]>;
};

export const getCurrentBreakpoint = (): Breakpoint => {
  let breakpoint = 'xs';
  getSortedBreakpoints().forEach(([name, width]) => {
    if (window.matchMedia(`(min-width: ${width}px)`).matches) {
      breakpoint = name;
    }
  });
  return breakpoint as Breakpoint;
};

export const untilBreakpoint = (breakpoint: Breakpoint, currentBreakpoint: Breakpoint): boolean => {
  // Questa funzione torna false nel caso in cui il breakpoint corrente è maggiore di quello indicato.
  const breakpointList = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

  return breakpointList.indexOf(breakpoint) >= breakpointList.indexOf(currentBreakpoint);
};

export const scrollTop = () => {
  window.scrollTo({ top: 0 });
};

export const getSupportedLocale = (
  supportedLocales: Array<LocaleApiDto>,
  defaultLocale: LocaleApiDto
): LocaleApiDto => {
  // return supportedLocales.filter((supportedLocale) => supportedLocale.code === detectedLocale)[0];

  let supportedLocale: LocaleApiDto | undefined;

  // Per prima cosa provo a capirlo dalla path
  const token = window.location.pathname.split('/')[1];
  // Credo debba essere:
  // const token = window.location.pathname.split('/')[isWithContextPath() ? 2 : 1];
  // da controllare
  if (token !== '' && (token.length === 2 || (token.length === 5 && token.charAt(2) === '-'))) {
    const localeCodeFromPath = token.substring(0, 2);
    // Se c'è, vedo se è valido (supportato)
    if (localeCodeFromPath) {
      const localeFromPath = supportedLocales.find(
        (_supportedLocale) => _supportedLocale.code.toLowerCase() === localeCodeFromPath.toLowerCase()
      );
      // Se è supportato, ho finito.
      if (localeFromPath) {
        supportedLocale = localeFromPath;
      }
    }
  }

  // Se ancora non l'ho trovato, prendo quello eventualmente salvato nello storage
  if (!supportedLocale) {
    const storedLocaleCode = storage.getString('locale', true);
    // Se c'è, vedo se è valido (supportato)
    if (storedLocaleCode) {
      const storedLocale = supportedLocales.find(
        (_supportedLocale) => _supportedLocale.code.toLowerCase() === storedLocaleCode.toLowerCase()
      );
      // Se è supportato, ho finito.
      if (storedLocale) {
        supportedLocale = storedLocale;
      }
    }
  }

  // Se ancora non l'ho trovato, lo prendo dal locale del browser
  if (!supportedLocale) {
    const userLocales = getUserLocales();
    if (userLocales.length > 0) {
      userLocales.forEach((_userLocale) => {
        // Se non l'ho trovato nelle iterazioni precedenti...
        if (!supportedLocale) {
          let userLocale = _userLocale;
          if (_userLocale.length === 5) {
            userLocale = _userLocale.substring(0, 2);
          }
          const supportedUserLocale = supportedLocales.find(
            (_supportedLocale) => _supportedLocale.code.toLowerCase() === userLocale.toLowerCase()
          );
          if (supportedUserLocale) {
            supportedLocale = supportedUserLocale;
          }
        }
      });
    }
  }

  // Se ancora non l'ho trovato, prendo quello di default lato client
  if (!supportedLocale) {
    const defaultClientLocaleCode = appConfig.locale?.default;
    // Se c'è, vedo se è valido (supportato)
    if (defaultClientLocaleCode) {
      const defaultClientLocale = supportedLocales.find(
        (_supportedLocale) => _supportedLocale.code.toLowerCase() === defaultClientLocaleCode.toLowerCase()
      );
      // Se è supportato, ho finito.
      if (defaultClientLocale) {
        supportedLocale = defaultClientLocale;
      }
    }
  }

  // Se ancora non l'ho trovato, prendo quello di default su bcom
  if (!supportedLocale) {
    supportedLocale = defaultLocale;
  }

  return supportedLocale;
};

export const getSupportedShippingCountryFromPathOrUserLocales = (
  supportedShippingCountries: Array<CountryApiDto>,
  defaultShippingCountry: CountryApiDto,
  locale: LocaleApiDto
): CountryApiDto => {
  let supportedUserCountry: CountryApiDto | undefined;

  if (appConfig.shippingCountry?.forceDefault) {
    supportedUserCountry = defaultShippingCountry;
  } else {
    // Per prima cosa vedo se una country è stata forzata
    const forcedShippingCountryCode = appConfig.shippingCountry?.forced;
    // Se c'è, vedo se è valido (supportato)
    if (forcedShippingCountryCode) {
      const forcedShippingCountry = supportedShippingCountries.find(
        (supportedShippingCountry) =>
          supportedShippingCountry.code.toLowerCase() === forcedShippingCountryCode.toLowerCase()
      );
      // Se è supportato, ho finito.
      if (forcedShippingCountry) {
        supportedUserCountry = forcedShippingCountry;
      }
    }

    // Se non l'ho forzato, provo a capirlo dalla path
    if (!supportedUserCountry) {
      const token = window.location.pathname.split('/')[1];
      if (token !== '' && token.startsWith(locale.code)) {
        const shippingCountryCodeFromPath = token.substring(locale.code.length + 1, token.length);
        // Se c'è, vedo se è valido (supportato)
        if (shippingCountryCodeFromPath) {
          const shippingCountryFromPath = supportedShippingCountries.find(
            (supportedShippingCountry) =>
              supportedShippingCountry.code.toLowerCase() === shippingCountryCodeFromPath.toLowerCase()
          );
          // Se è supportato, ho finito.
          if (shippingCountryFromPath) {
            supportedUserCountry = shippingCountryFromPath;
          }
        }
      }
    }

    // Se non l'ho trovato usando il path, prendo quello eventualmente salvato nello storage
    if (!supportedUserCountry) {
      const storedShippingCountryCode = storage.getString('shippingCountry', true);
      // Se c'è, vedo se è valido (supportato)
      if (storedShippingCountryCode) {
        const storedShippingCountry = supportedShippingCountries.find(
          (supportedShippingCountry) =>
            supportedShippingCountry.code.toLowerCase() === storedShippingCountryCode.toLowerCase()
        );
        // Se è supportato, ho finito.
        if (storedShippingCountry) {
          supportedUserCountry = storedShippingCountry;
        }
      }
    }

    // Se non l'ho trovato usando lo storage, lo prendo dal locale del browser
    if (!supportedUserCountry) {
      const userLocales = getUserLocales();
      if (userLocales.length > 0) {
        const userLocales5Digits = userLocales.filter((userLocale) => userLocale.length === 5);
        if (userLocales5Digits.length > 0) {
          const userCountry = userLocales5Digits[0].substring(3, 5);
          const supportedUserCountries = supportedShippingCountries.filter(
            (supportedCountry) => supportedCountry.code.toLowerCase() === userCountry.toLowerCase()
          );
          if (supportedUserCountries.length > 0) {
            supportedUserCountry = supportedUserCountries[0];
          }
        }
      }
    }

    // Se non l'ho trovato usando il locale del browser, prendo quello di default
    if (!supportedUserCountry) {
      supportedUserCountry = defaultShippingCountry;
    }
  }

  return supportedUserCountry;
};

export const arrayIncludesItems = <T,>(array: Array<T>, items: Array<T>) => items.every((item) => array.includes(item));

export const typedContent = <T,>(
  content: ContentApiDto<unknown>,
  transform: (content: ContentApiDto<T>) => React.ReactElement
) => transform(content as ContentApiDto<T>);

export const getTypedContent = <T,>(content?: ContentApiDto<unknown>) => content as ContentApiDto<T> | undefined;

export const getTypedContents = <T,>(contents?: Array<ContentApiDto<unknown>>) =>
  contents as Array<ContentApiDto<T>> | undefined;

export const isFriendlySearch = (location: Location) => location.pathname !== '/search';

export const getRouterBasename = (locale?: LocaleApiDto, shippingCountry?: CountryApiDto) =>
  !appConfig.widgetMode && locale !== undefined && shippingCountry !== undefined
    ? `${env.PUBLIC_URL}/${locale.code}-${shippingCountry.code.toLocaleLowerCase()}`
    : env.PUBLIC_URL;

export const getAbsoluteUrl = (relativeUrl: string) => new URL(relativeUrl, document.baseURI).href;

export const isWithContextPath = () => env.PUBLIC_URL !== '';

export const buildDisplayClassNames = (display: ResponsiveDisplay) => {
  const responsiveDisplay = typeof display === 'object' ? display : undefined;
  return classnames(
    { [`d-${display}`]: typeof display !== 'object' },
    { [`d-${responsiveDisplay?.xs}`]: responsiveDisplay?.xs !== undefined },
    { [`d-sm-${responsiveDisplay?.sm}`]: responsiveDisplay?.sm !== undefined },
    { [`d-md-${responsiveDisplay?.md}`]: responsiveDisplay?.md !== undefined },
    { [`d-lg-${responsiveDisplay?.lg}`]: responsiveDisplay?.lg !== undefined },
    { [`d-xl-${responsiveDisplay?.xl}`]: responsiveDisplay?.xl !== undefined },
    { [`d-xxl-${responsiveDisplay?.xxl}`]: responsiveDisplay?.xxl !== undefined }
  );
};

export const buildSpacingClassNames = (
  property: 'm' | 'p',
  size: ResponsiveMarginSize | ResponsivePaddingSize,
  sides?: SpacingSides
) => {
  const responsiveSize = typeof size === 'object' ? size : undefined;

  const sizeConverter = (_size?: ResponsiveMarginSize | ResponsivePaddingSize) => {
    return typeof _size === 'number' && _size < 0 ? `n${_size * -1}` : _size;
  };

  const sideConverter = (_side?: string) => {
    let side = '';

    _side === 'top' && (side = 't');
    _side === 'bottom' && (side = 'b');
    _side === 'start' && (side = 's');
    _side === 'end' && (side = 'e');
    _side === 'horizontal' && (side = 'x');
    _side === 'vertical' && (side = 'y');

    return side;
  };

  return classnames(
    { [`${property}${sideConverter(sides)}-${sizeConverter(size)}`]: typeof size !== 'object' },
    {
      [`${property}${sideConverter(sides)}-${sizeConverter(responsiveSize?.xs)}`]: responsiveSize?.xs !== undefined,
    },
    {
      [`${property}${sideConverter(sides)}-sm-${sizeConverter(responsiveSize?.sm)}`]: responsiveSize?.sm !== undefined,
    },
    {
      [`${property}${sideConverter(sides)}-md-${sizeConverter(responsiveSize?.md)}`]: responsiveSize?.md !== undefined,
    },
    {
      [`${property}${sideConverter(sides)}-lg-${sizeConverter(responsiveSize?.lg)}`]: responsiveSize?.lg !== undefined,
    },
    {
      [`${property}${sideConverter(sides)}-xl-${sizeConverter(responsiveSize?.xl)}`]: responsiveSize?.xl !== undefined,
    },
    {
      [`${property}${sideConverter(sides)}-xxl-${sizeConverter(responsiveSize?.xxl)}`]:
        responsiveSize?.xxl !== undefined,
    }
  );
};

export const buildDynamicSpacingClassNames = (property: 'm' | 'p', dynamicSpacing: Margin | Padding) => {
  let spacingClassName = '';

  const sizeConverter = (_size?: ResponsiveMarginSize | ResponsivePaddingSize) => {
    return typeof _size === 'number' && _size < 0 ? `n${_size * -1}` : _size;
  };

  const sideConverter = (_side?: string) => {
    let side = '';

    _side === 'top' && (side = 't');
    _side === 'bottom' && (side = 'b');
    _side === 'start' && (side = 's');
    _side === 'end' && (side = 'e');
    _side === 'horizontal' && (side = 'x');
    _side === 'vertical' && (side = 'y');

    return side;
  };

  dynamicSpacing &&
    (typeof dynamicSpacing === 'object'
      ? Object.entries(dynamicSpacing).map((spacing) =>
          typeof spacing[1] === 'object'
            ? Object.entries(spacing[1]).map(
                (spacingNested) =>
                  (spacingClassName = `${spacingClassName} ${property}${sideConverter(spacing[0])}-${
                    spacingNested[0]
                  }-${sizeConverter(spacingNested[1])}`)
              )
            : (spacingClassName = `${spacingClassName} ${property}${sideConverter(spacing[0])}-${sizeConverter(
                spacing[1]
              )}`)
        )
      : (spacingClassName = property + dynamicSpacing));

  return spacingClassName;
};

export const buildFontWeightClassNames = (fontWeight: FontWeight) => {
  return classnames(
    { 'fw-thin': fontWeight === 100 },
    { 'fw-extra-light': fontWeight === 200 },
    { 'fw-light"': fontWeight === 300 },
    { 'fw-normal': fontWeight === 400 },
    { 'fw-medium': fontWeight === 500 },
    { 'fw-semi-bold': fontWeight === 600 },
    { 'fw-bold': fontWeight === 700 },
    { 'fw-extra-bold': fontWeight === 800 },
    { 'fw-black': fontWeight === 900 }
  );
};

export const buildTextAlignClassNames = (textAlign: ResponsiveTextAlign) => {
  const responsiveTextAlign = typeof textAlign === 'object' ? textAlign : undefined;
  return classnames(
    { [`text-${textAlign}`]: typeof textAlign !== 'object' },
    { [`text-${responsiveTextAlign?.xs}`]: responsiveTextAlign?.xs !== undefined },
    { [`text-sm-${responsiveTextAlign?.sm}`]: responsiveTextAlign?.sm !== undefined },
    { [`text-md-${responsiveTextAlign?.md}`]: responsiveTextAlign?.md !== undefined },
    { [`text-lg-${responsiveTextAlign?.lg}`]: responsiveTextAlign?.lg !== undefined },
    { [`text-xl-${responsiveTextAlign?.xl}`]: responsiveTextAlign?.xl !== undefined },
    { [`text-xxl-${responsiveTextAlign?.xxl}`]: responsiveTextAlign?.xxl !== undefined }
  );
};

export const buildJustifyContentClassNames = (justifyContent: ResponsiveJustifyContent) => {
  const responsiveJustifyContent = typeof justifyContent === 'object' ? justifyContent : undefined;

  const getBs5Suffix = (value: string) =>
    value === 'spaceAround'
      ? 'around'
      : value === 'spaceBetween'
      ? 'between'
      : value === 'spaceEvenly'
      ? 'evenly'
      : value;

  return classnames(
    typeof justifyContent !== 'object' && `justify-content-${getBs5Suffix(justifyContent)}`,
    responsiveJustifyContent?.xs !== undefined && `justify-content-${getBs5Suffix(responsiveJustifyContent.xs)}`,
    responsiveJustifyContent?.sm !== undefined && `justify-content-sm-${getBs5Suffix(responsiveJustifyContent.sm)}`,
    responsiveJustifyContent?.md !== undefined && `justify-content-md-${getBs5Suffix(responsiveJustifyContent.md)}`,
    responsiveJustifyContent?.lg !== undefined && `justify-content-lg-${getBs5Suffix(responsiveJustifyContent.lg)}`,
    responsiveJustifyContent?.xl !== undefined && `justify-content-xl-${getBs5Suffix(responsiveJustifyContent.xl)}`,
    responsiveJustifyContent?.xxl !== undefined && `justify-content-xxl-${getBs5Suffix(responsiveJustifyContent.xxl)}`
  );
};

export const buildGapClassNames = (gap: ResponsiveGap) => {
  //return classnames(`gap-${gap}`);
  const responsiveGap = typeof gap === 'object' ? gap : undefined;
  return classnames(
    { [`gap-${gap}`]: typeof gap !== 'object' },
    { [`gap-${responsiveGap?.xs}`]: responsiveGap?.xs !== undefined },
    { [`gap-sm-${responsiveGap?.sm}`]: responsiveGap?.sm !== undefined },
    { [`gap-md-${responsiveGap?.md}`]: responsiveGap?.md !== undefined },
    { [`gap-lg-${responsiveGap?.lg}`]: responsiveGap?.lg !== undefined },
    { [`gap-xl-${responsiveGap?.xl}`]: responsiveGap?.xl !== undefined },
    { [`gap-xxl-${responsiveGap?.xxl}`]: responsiveGap?.xxl !== undefined }
  );
};

export const buildAlignContentClassNames = (alignContent: ResponsiveAlignContent) => {
  const responsiveAlignContent = typeof alignContent === 'object' ? alignContent : undefined;
  return classnames(
    { [`align-content-${alignContent}`]: typeof alignContent !== 'object' },
    { [`align-content-${responsiveAlignContent?.xs}`]: responsiveAlignContent?.xs !== undefined },
    { [`align-content-sm-${responsiveAlignContent?.sm}`]: responsiveAlignContent?.sm !== undefined },
    { [`align-content-md-${responsiveAlignContent?.md}`]: responsiveAlignContent?.md !== undefined },
    { [`align-content-lg-${responsiveAlignContent?.lg}`]: responsiveAlignContent?.lg !== undefined },
    { [`align-content-xl-${responsiveAlignContent?.xl}`]: responsiveAlignContent?.xl !== undefined },
    { [`align-content-xxl-${responsiveAlignContent?.xxl}`]: responsiveAlignContent?.xxl !== undefined }
  );
};

export const buildAlignItemsClassNames = (alignItems: ResponsiveAlignItems) => {
  const responsiveAlignItems = typeof alignItems === 'object' ? alignItems : undefined;
  return classnames(
    { [`align-items-${alignItems}`]: typeof alignItems !== 'object' },
    { [`align-items-${responsiveAlignItems?.xs}`]: responsiveAlignItems?.xs !== undefined },
    { [`align-items-sm-${responsiveAlignItems?.sm}`]: responsiveAlignItems?.sm !== undefined },
    { [`align-items-md-${responsiveAlignItems?.md}`]: responsiveAlignItems?.md !== undefined },
    { [`align-items-lg-${responsiveAlignItems?.lg}`]: responsiveAlignItems?.lg !== undefined },
    { [`align-items-xl-${responsiveAlignItems?.xl}`]: responsiveAlignItems?.xl !== undefined },
    { [`align-items-xxl-${responsiveAlignItems?.xxl}`]: responsiveAlignItems?.xxl !== undefined }
  );
};

export const buildAlignSelfClassNames = (alignSelf: ResponsiveAlignSelf) => {
  const responsiveAlignSelf = typeof alignSelf === 'object' ? alignSelf : undefined;
  return classnames(
    { [`align-self-${alignSelf}`]: typeof alignSelf !== 'object' },
    { [`align-self-${responsiveAlignSelf?.xs}`]: responsiveAlignSelf?.xs !== undefined },
    { [`align-self-sm-${responsiveAlignSelf?.sm}`]: responsiveAlignSelf?.sm !== undefined },
    { [`align-self-md-${responsiveAlignSelf?.md}`]: responsiveAlignSelf?.md !== undefined },
    { [`align-self-lg-${responsiveAlignSelf?.lg}`]: responsiveAlignSelf?.lg !== undefined },
    { [`align-self-xl-${responsiveAlignSelf?.xl}`]: responsiveAlignSelf?.xl !== undefined },
    { [`align-self-xxl-${responsiveAlignSelf?.xxl}`]: responsiveAlignSelf?.xxl !== undefined }
  );
};

export const buildFlexDirectionClassNames = (flexDirection: ResponsiveFlexDirection) => {
  const responsiveFlexDirection = typeof flexDirection === 'object' ? flexDirection : undefined;
  return classnames(
    { [`flex-${flexDirection}`]: typeof flexDirection !== 'object' },
    { [`flex-${responsiveFlexDirection?.xs}`]: responsiveFlexDirection?.xs !== undefined },
    { [`flex-sm-${responsiveFlexDirection?.sm}`]: responsiveFlexDirection?.sm !== undefined },
    { [`flex-md-${responsiveFlexDirection?.md}`]: responsiveFlexDirection?.md !== undefined },
    { [`flex-lg-${responsiveFlexDirection?.lg}`]: responsiveFlexDirection?.lg !== undefined },
    { [`flex-xl-${responsiveFlexDirection?.xl}`]: responsiveFlexDirection?.xl !== undefined },
    { [`flex-xxl-${responsiveFlexDirection?.xxl}`]: responsiveFlexDirection?.xxl !== undefined }
  );
};

export const buildFlexWrapClassNames = (flexWrap: ResponsiveFlexWrap) => {
  const responsiveFlexWrap = typeof flexWrap === 'object' ? flexWrap : undefined;
  return classnames(
    { [`flex-${flexWrap}`]: typeof flexWrap !== 'object' },
    { [`flex-${responsiveFlexWrap?.xs}`]: responsiveFlexWrap?.xs !== undefined },
    { [`flex-sm-${responsiveFlexWrap?.sm}`]: responsiveFlexWrap?.sm !== undefined },
    { [`flex-md-${responsiveFlexWrap?.md}`]: responsiveFlexWrap?.md !== undefined },
    { [`flex-lg-${responsiveFlexWrap?.lg}`]: responsiveFlexWrap?.lg !== undefined },
    { [`flex-xl-${responsiveFlexWrap?.xl}`]: responsiveFlexWrap?.xl !== undefined },
    { [`flex-xxl-${responsiveFlexWrap?.xxl}`]: responsiveFlexWrap?.xxl !== undefined }
  );
};

export const documentHeadHasScript = (id: string) => document.head.getElementsByTagName('script').namedItem(id);
export const elementHasScript = (element: HTMLElement, id: string) =>
  element.getElementsByTagName('script').namedItem(id);

export const addScript = (
  attributes: {
    async?: boolean;
    charset?: string;
    dataAttributes?: Record<string, string | undefined>;
    defer?: boolean;
    id: string;
    nomodule?: boolean;
    src?: string;
    text?: string;
    type?: string;
  },
  options?: {
    appendTo?: React.RefObject<HTMLElement>;
    appendToBody?: boolean;
  }
): Promise<void> => {
  const promise = new Promise<void>((resolve) => {
    const { async, charset, dataAttributes, defer, id, nomodule, src, text, type } = attributes;
    const { appendTo, appendToBody } = options ?? {};

    // Se l'ho gia messo evito di rimetterlo.
    if (
      (appendTo === undefined && documentHeadHasScript(id)) ||
      (appendTo !== undefined && appendTo.current !== null && elementHasScript(appendTo.current, id)) ||
      (appendToBody !== undefined && elementHasScript(document.body, id))
    ) {
      resolve();
    } else {
      const script = document.createElement('script');
      if (async) {
        script.async = async;
      }
      if (defer) {
        script.defer = defer;
      }
      script.id = id;
      if (src) {
        script.src = src;
      }
      if (type) {
        script.type = type;
      }
      if (text) {
        script.text = text;
      }
      if (charset) {
        script.charset = charset;
      }
      script.onload = () => {
        resolve();
      };

      dataAttributes &&
        Object.entries(dataAttributes).forEach(([name, value]) => {
          if (value !== undefined) {
            script.setAttribute(`data-${name}`, value);
          }
        });

      nomodule && script.setAttribute('nomodule', 'true');

      if (appendTo !== undefined && appendTo.current !== null) {
        appendTo.current.appendChild(script);
      } else if (appendToBody) {
        document.body.append(script);
      } else {
        document.head.insertBefore(script, document.head.firstChild);
      }
    }
  });
  return promise;
};

export const uiClassName = (props: UiClassName) => props[appConfig.ui] ?? '';

export const capitalizeFirstLetter = (str: string) => {
  // converting first letter to uppercase
  const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

  return capitalized;
};

export const getFirstAvailableSkuIndex = (skus: Array<SkuApiDto>) => skus.findIndex((sku) => sku.state === 'AVAILABLE');

export const findLast = <T,>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: Array<T>) => boolean
): T | undefined => {
  for (let i = array.length - 1; i >= 0; i--) {
    const value = array[i];
    if (predicate(value, i, array)) {
      return value;
    }
  }
  return undefined;
};

export const findLastIndex = <T,>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: Array<T>) => unknown
): number => {
  for (let i = array.length - 1; i >= 0; i--) {
    const value = array[i];
    if (predicate(value, i, array)) {
      return i;
    }
  }
  return -1;
};

export const isElementInViewport = (element: HTMLElement, partiallyVisible = false) => {
  const { bottom, left, right, top } = element.getBoundingClientRect();
  const { innerHeight, innerWidth } = window;
  return partiallyVisible
    ? ((top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)) &&
        ((left > 0 && left < innerWidth) || (right > 0 && right < innerWidth))
    : top >= 0 && left >= 0 && bottom <= innerHeight && right <= innerWidth;
};

export const getDistanceFromTwoPositions = (
  latitude1: number,
  longitute1: number,
  latitude2: number,
  longitude2: number
) => {
  const deg2rad = (deg: number) => {
    return deg * (Math.PI / 180);
  };
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(latitude2 - latitude1);
  const dLon = deg2rad(longitude2 - longitute1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(latitude1)) * Math.cos(deg2rad(latitude2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export const sortShopsByDistance = (shops: Array<ShopApiDto>, currentPosition: GeolocationPosition) => {
  return shops
    .map((shop) => ({
      ...shop,
      distance:
        shop.address?.latitude &&
        shop.address.longitude &&
        currentPosition.coords.latitude &&
        currentPosition.coords.longitude
          ? getDistanceFromTwoPositions(
              shop.address.latitude,
              shop.address.longitude,
              currentPosition.coords.latitude,
              currentPosition.coords.longitude
            )
          : undefined,
    }))
    .sort((a, b) => (a.distance && b.distance ? a.distance - b.distance : 0));
};

export const stopEventPropagation = (event: React.MouseEvent) => {
  event.stopPropagation();
};

export const deepFilterMenu = (menu: MenuApiDto, filter: (menu: MenuApiDto) => boolean): MenuApiDto => {
  const result = { ...menu };
  result.children = result.children.filter(filter);
  result.children = result.children.map((child) => deepFilterMenu(child, filter));
  return result;
};

export const getDefaultLocale = (info: InfoApiDto): LocaleApiDto => {
  if (!info.shippingCountriesLocales) {
    throw new Error("Cannot detect default locale, missing 'info.shippingCountriesLocales'");
  }
  if (!info.defaultShippingCountry) {
    throw new Error("Cannot detect default locale, missing 'info.defaultShippingCountry'");
  }
  const defaultShippingCountryLocales = info.shippingCountriesLocales[info.defaultShippingCountry.code];
  if (!defaultShippingCountryLocales) {
    throw new Error("Cannot detect default locale, missing 'defaultShippingCountryLocales'");
  }
  const defaultLocale = defaultShippingCountryLocales.at(0);
  if (!defaultLocale) {
    throw new Error("Cannot detect default locale, missing 'defaultLocale'");
  }
  return defaultLocale;
};

export const fromCustomerApiDtoToCustomerRequestApiDto = (customer: CustomerApiDto): CustomerRequestApiDto => ({
  ...customer,
  additionalProperties: customer.additionalPropertiesExt
    ? _.mapValues(customer.additionalPropertiesExt, (value) => value?.value)
    : undefined,
});

export const iosDetection = () =>
  ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

export const formatShopAddress = (address: AddressApiDto) => {
  return (
    <>
      {address.addressLine1 && ` ${address.addressLine1}`}
      {address.civicNumber && ` ${address.civicNumber}`}
      <br />
      {address.zipCode && address.zipCode.trim() !== '' && `${address.zipCode}, `}
      {address.city && `${address.city}`}
      {address.province?.name && address.province.name.trim() !== '' && ` (${address.province.name})`}
    </>
  );
};

export const downloadFileFromUrl = (fileUrl: string, fileName: string) => {
  // Creates a hidden 'a' element
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;

  // Add element 'a' to the page
  document.body.appendChild(link);

  // Simulates clicking on element 'a' to start downloading
  link.click();

  // Remove element 'a' from the page after downloading
  document.body.removeChild(link);
};

export const downloadFileFromBlob = (blobPart: BlobPart, fileName: string, type?: string) => {
  const blob = new Blob([blobPart], { type: type });
  const url = window.URL.createObjectURL(blob);
  downloadFileFromUrl(url, fileName);
  window.URL.revokeObjectURL(url);
};

export const objectToBlob = (object: object) => {
  const jsonString = JSON.stringify(object, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  return blob;
};

export const getContentSectionByShippingCountry = <T,>(
  contentSections?: Array<{
    itemForThisCountry?: T;
    visibility?: string;
  }>,
  shippingCountry?: CountryApiDto
) =>
  shippingCountry
    ? contentSections?.find(({ visibility }) => {
        const shippingCountries = visibility?.toUpperCase().split(',');
        return shippingCountries?.includes(shippingCountry.code);
      })?.itemForThisCountry ?? contentSections?.find(({ visibility }) => visibility === 'all')?.itemForThisCountry
    : undefined;
