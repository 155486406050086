import {
  // eslint-disable-next-line no-restricted-imports
  api,
  ChangePasswordByTokenRequestApiDto,
  CreateUpdateAddressRequestApiDto,
  CustomerRequestApiDto,
  ForgotPasswordRequestApiDto,
  PasswordChangeRequestApiDto,
  ProductApiDto,
  RegisterCustomerRequestApiDto,
  RemoveFromWishlistRequestApiDto,
  SkuApiDto,
  SubscribeNewsletterRequestApiDto,
} from '@b2x/storefront-api-js-client/src';
import { GetCustomerOptions, GetWishlistOptions } from '@b2x/storefront-api-js-client/src/customer';
import { GetOrderOptions } from '@b2x/storefront-api-js-client/src/orders';
import React from 'react';

import { analytics } from '../analytics/analytics';
import { useAppStaticContext } from '../AppContext';
import { appConfig } from '../config';
import { ApiRequestConfig, useApiRequest } from './useApiRequest';
import { useSessionApi } from './useSessionApi';

export const useCustomerApi = () => {
  const { apiRequest } = useApiRequest();
  const { getSession } = useSessionApi();
  const { setSession } = useAppStaticContext();

  const getCustomer = React.useCallback(
    (options?: GetCustomerOptions, config?: ApiRequestConfig) =>
      apiRequest(api.customer.get(options), { silent: false, ...config }),
    [apiRequest]
  );

  const updateSessionCustomer = React.useCallback(() => {
    getSession(
      {
        ...appConfig.api?.sessionOptions,
        populate: {
          customer: appConfig.api?.sessionOptions?.populate?.customer,
        },
      },
      { silent: false }
    ).then((response) => {
      setSession((prevState) => (prevState ? { ...prevState, customer: response.data.customer } : undefined));
    });
  }, [getSession, setSession]);

  const register = React.useCallback(
    (data: RegisterCustomerRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.register(data), { silent: false, ...config }).then((response) => {
        analytics.events.completeRegistration(response.eventId, 'sign-up');
        getSession(undefined, { silent: false }).then((sessionResponse) => {
          setSession(sessionResponse.data);
        });
        return response;
      }),
    [apiRequest, getSession, setSession]
  );

  const updateCustomer = React.useCallback(
    (data: CustomerRequestApiDto, options?: GetCustomerOptions, config?: ApiRequestConfig) =>
      apiRequest(api.customer.update(data, options), { silent: false, ...config }).then((response) => {
        updateSessionCustomer();
        return response;
      }),
    [apiRequest, updateSessionCustomer]
  );

  const getAddresses = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.customer.getaddresses(), { ...config }),
    [apiRequest]
  );

  const createAddress = React.useCallback(
    (data: CreateUpdateAddressRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.createAddress(data), { silent: false, ...config }).then((response) => {
        updateSessionCustomer();
        return response;
      }),
    [apiRequest, updateSessionCustomer]
  );

  const updateAddress = React.useCallback(
    (data: CreateUpdateAddressRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.updateAddress(data), { silent: false, ...config }).then((response) => {
        updateSessionCustomer();
        return response;
      }),
    [apiRequest, updateSessionCustomer]
  );

  const deleteAddress = React.useCallback(
    (id: string, config?: ApiRequestConfig) =>
      apiRequest(api.customer.deleteAddress(id), { silent: false, ...config }).then((response) => {
        updateSessionCustomer();
        return response;
      }),
    [apiRequest, updateSessionCustomer]
  );

  const getWishlist = React.useCallback(
    (options?: GetWishlistOptions, config?: ApiRequestConfig) =>
      apiRequest(api.customer.getWishlist(options), { ...config }),
    [apiRequest]
  );

  const updateSessionWishlist = React.useCallback(() => {
    getSession({
      ...appConfig.api?.sessionOptions,
      populate: {
        wishlist: appConfig.api?.sessionOptions?.populate?.wishlist,
      },
    }).then((response) => {
      setSession((prevState) => (prevState ? { ...prevState, wishlist: response.data.wishlist } : undefined));
    });
  }, [getSession, setSession]);

  const addProductToWishlist = React.useCallback(
    ({ product, sku }: { product: ProductApiDto; sku?: SkuApiDto }, config?: ApiRequestConfig) =>
      apiRequest(
        api.customer.addProductToWishlist({
          productId: product.id,
          skuId: sku?.id,
          wishlistId: undefined,
        }),
        { silent: false, ...config }
      ).then((response) => {
        if (sku !== undefined) {
          // Per ora chiamo analytics solo quando metto prodotti in wishlist specificando lo sku.
          analytics.events.addToWishlist(response.eventId, product, sku);
        }
        updateSessionWishlist();
        return response;
      }),
    [apiRequest, updateSessionWishlist]
  );

  const removeProductFromWishlist = React.useCallback(
    (data: RemoveFromWishlistRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.removeProductFromWishlist(data), { silent: false, ...config }).then((response) => {
        updateSessionWishlist();
        return response;
      }),
    [apiRequest, updateSessionWishlist]
  );

  const subscribeToNewsletter = React.useCallback(
    (data: SubscribeNewsletterRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.subscribeToNewsletter(data), { silent: false, ...config }).then((response) => {
        analytics.events.lead(response.eventId, 'newsletter');
        return response;
      }),
    [apiRequest]
  );

  const getOrders = React.useCallback(
    (options?: GetOrderOptions, config?: ApiRequestConfig) =>
      apiRequest(api.customer.getOrders(options), { ...config }),
    [apiRequest]
  );

  const getCoupons = React.useCallback(
    (config?: ApiRequestConfig) => apiRequest(api.customer.getCoupons(), { ...config }),
    [apiRequest]
  );

  const forgotPassword = React.useCallback(
    (data: ForgotPasswordRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.forgotPassword(data), { silent: false, ...config }),
    [apiRequest]
  );

  const changePasswordByToken = React.useCallback(
    (data: ChangePasswordByTokenRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.changePasswordByToken(data), { silent: false, ...config }),
    [apiRequest]
  );

  const changePassword = React.useCallback(
    (data: PasswordChangeRequestApiDto, config?: ApiRequestConfig) =>
      apiRequest(api.customer.changePassword(data), { silent: false, ...config }),
    [apiRequest]
  );

  const unsubscribeNewsletterByToken = React.useCallback(
    (token: string, config?: ApiRequestConfig) =>
      apiRequest(api.customer.unsubscribeNewsletterByToken(token), { silent: false, ...config }),
    [apiRequest]
  );

  return {
    addProductToWishlist,
    changePassword,
    changePasswordByToken,
    createAddress,
    deleteAddress,
    forgotPassword,
    getAddresses,
    getCoupons,
    getCustomer,
    getOrders,
    getWishlist,
    register,
    removeProductFromWishlist,
    subscribeToNewsletter,
    unsubscribeNewsletterByToken,
    updateAddress,
    updateCustomer,
    updateSessionWishlist,
  };
};
