import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { UseFormModalProps } from '../Modal';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { PasswordInput, PasswordInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { ChangePasswordFormA } from './ChangePasswordFormA';

export interface ChangePasswordFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {}

interface FormValues {
  confirmNewPassword: formikString;
  currentPassword: formikString;
  newPassword: formikString;
}

type ValidationSchema = {
  confirmNewPassword: yup.StringSchema;
  currentPassword: yup.StringSchema;
  newPassword: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  confirmNewPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  currentPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
  newPassword: { formGroup: FormGroupProps; passwordInput: PasswordInputProps };
}

export const ChangePasswordFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: ChangePasswordFormProps) => {
  const { showModal } = useModals();

  const { changePassword } = useCustomerApi();

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      confirmNewPassword: getInitialString(),
      currentPassword: getInitialString(),
      newPassword: getInitialString(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      confirmNewPassword: yup
        .string()
        .required()
        .oneOf([yup.ref('newPassword'), null], t('yup.custom.passwordConfirmationNotMatch')),
      currentPassword: yup.string().required(),
      newPassword: yup.string().required(),
    }),
    []
  );

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      changePassword(values).then(() => {
        onSuccess
          ? onSuccess()
          : showModal({
              children: t('form.changePasswordForm.success.body'),
              title: t('form.changePasswordForm.success.title'),
            });
      }),
    [changePassword, onSuccess, showModal]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('change-password-form', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.changePasswordForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.changePasswordForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.changePasswordForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          confirmNewPassword: {
            formGroup: {
              label: t('form.changePasswordForm.confirmNewPassword.label'),
              names: ['confirmNewPassword'],
            },
            passwordInput: {
              autoComplete: 'new-password',
              name: 'confirmNewPassword',
              placeholder: undefined,
            },
          },
          currentPassword: {
            formGroup: {
              label: t('form.changePasswordForm.currentPassword.label'),
              names: ['currentPassword'],
            },
            passwordInput: {
              autoComplete: 'current-password',
              name: 'currentPassword',
              placeholder: undefined,
            },
          },
          newPassword: {
            formGroup: {
              label: t('form.changePasswordForm.newPassword.label'),
              names: ['newPassword'],
            },
            passwordInput: {
              autoComplete: 'new-password',
              name: 'newPassword',
              placeholder: undefined,
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.currentPassword.formGroup}>
              <PasswordInput {...fieldsHelper.currentPassword.passwordInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.newPassword.formGroup}>
              <PasswordInput {...fieldsHelper.newPassword.passwordInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.confirmNewPassword.formGroup}>
              <PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export const useChangePasswordFormModal = (modalProps?: UseFormModalProps) => {
  const { showModal } = useModals();
  return React.useCallback(
    (formProps: ChangePasswordFormProps) => {
      showModal({
        children: <ChangePasswordFormController {...formProps} />,
        size: 'default',
        title: t('form.changePasswordForm.modal.title'),
        ...modalProps,
      });
    },
    [modalProps, showModal]
  );
};

export type ChangePasswordFormVariants = 'A';

const ChangePasswordFormController = (props: PropsWithCustomComponentWithoutChildren<ChangePasswordFormProps>) => (
  <VariantsController<ChangePasswordFormProps, ChangePasswordFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: ChangePasswordFormHelper,
      name: 'ChangePasswordForm',
      componentVariants: {
        A: ChangePasswordFormA,
      },
    }}
  />
);
export { ChangePasswordFormController as ChangePasswordForm };
