import {
  AttributeApiDto,
  ImageApiDto,
  ProductApiDto,
  SkuApiDto,
  SkuStateApiType,
} from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { AddToCartFormButton } from '../AddToCartFormButton';
import { analytics } from '../analytics/analytics';
import { useCartApi } from '../api/useCartApi';
import { useProductsApi } from '../api/useProductsApi';
import { useAppStaticContext } from '../AppContext';
// import { Button } from '../Button';
import { appConfig } from '../config';
import { useEqualHeightContextStrict } from '../EqualHeightContext';
import { ResourceKeys, t } from '../i18n/i18n';
import { useListingContextStrict } from '../ListingContext';
import { useProductContextStrict } from '../ProductContext';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { usePrice, UsePriceResult } from '../usePrice';
import { useWishlistButtonHelper } from '../useWishlistButtonHelper';
import { formatCurrency, prettyStringify } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { NumberInputProps, TextInput, TextInputProps } from './fields/Input';
import { OptionProps } from './fields/Option';
import { Radio, RadioProps } from './fields/RadioCheck';
import { Select, SelectProps } from './fields/Select';
import { TextArea, TextAreaProps } from './fields/TextArea';
import {
  FormButtonProps,
  FormFieldsChangeHandlerProps,
  formikNumber,
  formikString,
  getFormikNumberValue,
  getInitialNumber,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface AddToCartFormProps
  extends Omit<
    BaseHelpedFormProps<
      FormValues,
      FieldsHelper,
      ValidationSchemaSelector,
      {
        image?: ImageApiDto;
        priceHelper: UsePriceResult;
        selectedProductVariant: ProductApiDto;
        selectedSku: SkuApiDto | undefined;
        selectedSkuAttributes: Record<string, AttributeApiDto | undefined>;
      }
    >,
    'onSuccess'
  > {
  imageSkuVariantAttributeTypeCode?: string;
  preselectedSkuId?: string;
  product: ProductApiDto;
  scope: 'product' | 'tile';
}

interface FormValues {
  giftCard?: GiftCardFormValues;
  price: formikString;
  productVariantId: formikString;
  quantity: formikNumber;
  skuAttributes: Record<string, formikString>;
  skuId: formikString;
}

interface GiftCardFormValues {
  message: formikString;
  receiverEmail: formikString;
  receiverName: formikString;
  senderName: formikString;
}

export type AddToCartFormValues = FormValues;

type ValidationSchema = {
  giftCard?: yup.ObjectSchema<{
    message: yup.StringSchema;
    receiverEmail: yup.StringSchema;
    receiverName: yup.StringSchema;
    senderName: yup.StringSchema;
  }>;
  price: yup.StringSchema;
  productVariantId: yup.StringSchema;
  quantity: yup.NumberSchema;
  skuAttributes: yup.AnyObjectSchema;
  skuId: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    availabilityEmailNotification: FormButtonProps;
    cancel?: FormButtonProps;
    quantity: {
      decrease: FormButtonProps;
      increase: FormButtonProps;
    };
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  giftCard?: {
    message: { formGroup: FormGroupProps; textArea: TextAreaProps };
    receiverEmail: { formGroup: FormGroupProps; textInput: TextInputProps };
    receiverName: { formGroup: FormGroupProps; textInput: TextInputProps };
    senderName: { formGroup: FormGroupProps; textInput: TextInputProps };
  };
  productVariants: {
    formFields: Array<{ productVariant: ProductApiDto; radio: RadioProps }>;
    formGroup: FormGroupProps;
  };
  quantity: { formGroup: FormGroupProps; numberInput: NumberInputProps; select: SelectProps };
  skuAttributes: Array<{
    attribute: {
      title: string;
      typeCode: string;
      value?: string;
    };
    formGroup: FormGroupProps;
    // radios: RadiosProps;
    radios: Array<{ radio: RadioProps; skus: Array<SkuApiDto> }>;
    select: SelectProps;
  }>;
  skus: {
    formFields: Array<{ option: OptionProps; radio: RadioProps; sku: SkuApiDto }>;
    formGroup: FormGroupProps;
    select: SelectProps;
  };
}

export type AddToCartFieldsHelper = FieldsHelper;

export const AddToCartFormHelper = React.memo(
  ({
    children,
    className,
    imageSkuVariantAttributeTypeCode,
    initialValues,
    onCancel,
    preselectedSkuId,
    product,
    scope = 'tile',
    ...otherProps
  }: AddToCartFormProps) => {
    const productVariants = React.useMemo<Array<ProductApiDto>>(
      () => [product, ...(product.variantProducts ?? [])],
      [product]
    );

    const productContext = useProductContextStrict();

    const { getProduct } = useProductsApi();

    const [selectedSkuId, setSelectedSkuId] = React.useState<string>('');

    const [selectedProductVariant, setSelectedProductVariant] = React.useState<ProductApiDto>(product);
    const [selectedSku, setSelectedSku] = React.useState<SkuApiDto>();

    const [selectedSkuAttributesValues, setSelectedSkuAttributesValues] = React.useState<Record<string, string>>({});

    const priceHelper = usePrice(selectedProductVariant, selectedSku?.id);

    React.useEffect(() => {
      setSelectedProductVariant(product);
    }, [product]);

    // Al cambiare della variante, se ho un solo sku, lo scelgo
    React.useEffect(() => {
      if (selectedProductVariant.skus?.length === 1) {
        setSelectedSkuId(selectedProductVariant.skus[0].id);
      }
    }, [selectedProductVariant.skus]);

    React.useEffect(() => {
      const _selectedSku = selectedProductVariant.skus?.find((sku) => sku.id === selectedSkuId);
      _selectedSku && setSelectedSku(_selectedSku);
    }, [selectedProductVariant.skus, selectedSkuId]);

    React.useEffect(() => {
      if (selectedSkuId === '') {
        setSelectedSku(undefined);
      }
    }, [selectedSkuId]);

    // const getSelectedSku = React.useCallback(
    //   (_selectedSkuId?: string) => {
    //     return selectedProductVariant.skus?.find((sku) => sku.id === _selectedSkuId);
    //   },
    //   [selectedProductVariant.skus]
    // );

    // const selectedSku = React.useMemo(() => {
    //   return getSelectedSku(selectedSkuId);
    // }, [getSelectedSku, selectedSkuId]);

    // React.useEffect(() => {
    //   const x = selectedProductVariant.skus?.find((sku) => sku.id === selectedSkuId);
    //   setSelectedSku(x);
    // }, [selectedProductVariant.skus, selectedSkuId]);

    /*
  Metodo per avere tutti i possibili sku in base all'attuale selezione di attributi,
  con la possibilità di escludere un certo attributo, per gestire i fratelli.
  */
    const getPossibleSkuChoices = React.useCallback(
      (attributeTypeCodeToExclude: string | undefined, skuAttributesValues: Record<string, string>) =>
        selectedProductVariant.skus?.filter((sku) => {
          return (
            Object.entries(skuAttributesValues)
              // filtro gli attributi per cui non ho ancora fatto una scelta.
              // filtro inoltre l'eventuale attributo da escludere
              .filter(
                ([attributeTypeCode, attributeValueCode]) =>
                  attributeValueCode !== '' && attributeTypeCode !== (attributeTypeCodeToExclude ?? '')
              )
              .every(
                ([attributeTypeCode, attributeValueCode]) =>
                  sku.attributes?.some(
                    (attribute) =>
                      attribute.typeCode &&
                      attribute.typeCode === attributeTypeCode &&
                      attribute.valueCode === attributeValueCode
                  ) ?? false
              )
          );
        }) ?? [],
      [selectedProductVariant.skus]
    );

    const getSkusByAttribute = React.useCallback(
      (attribute: AttributeApiDto) =>
        selectedProductVariant.skus
          ? selectedProductVariant.skus.filter((sku) =>
              sku.attributes?.some(
                (_attribute) =>
                  attribute.typeCode === _attribute.typeCode && attribute.valueCode === _attribute.valueCode
              )
            )
          : [],
      [selectedProductVariant.skus]
    );

    const skuVariantAttributesMap = React.useMemo<
      Record<string, Array<{ attribute: AttributeApiDto; skus: Array<SkuApiDto> }>>
    >(() => {
      const _skuVariantAttributesMap = selectedProductVariant.skus
        ? selectedProductVariant.skus.reduce<
            Record<string, Array<{ attribute: AttributeApiDto; skus: Array<SkuApiDto> }>>
          >((acc, currentValue) => {
            currentValue.attributes?.forEach((attribute) => {
              if (attribute.typeCode && attribute.valueCode) {
                if (!Object.keys(acc).includes(attribute.typeCode)) {
                  acc[attribute.typeCode] = [];
                }
                if (!acc[attribute.typeCode].some((item) => attribute.valueCode === item.attribute.valueCode)) {
                  acc[attribute.typeCode].push({ attribute: attribute, skus: getSkusByAttribute(attribute) });
                }
              }
            });
            return acc;
          }, {})
        : {};
      Object.values(_skuVariantAttributesMap).forEach((attributes) => {
        attributes.sort((a, b) => a.attribute.ordinal - b.attribute.ordinal);
      });
      return _skuVariantAttributesMap;
    }, [getSkusByAttribute, selectedProductVariant.skus]);

    const skuVariantAttributesMapByValueCode = React.useMemo<Record<string, AttributeApiDto>>(
      () =>
        selectedProductVariant.skus
          ? selectedProductVariant.skus.reduce<Record<string, AttributeApiDto>>((acc, _sku) => {
              _sku.attributes?.forEach((attribute) => {
                if (attribute.valueCode && !acc.hasOwnProperty(attribute.valueCode)) {
                  acc[attribute.valueCode] = attribute;
                }
              });
              return acc;
            }, {})
          : {},
      [selectedProductVariant.skus]
    );

    const selectedSkuAttributes = React.useMemo<Record<string, AttributeApiDto | undefined>>(
      () =>
        Object.entries(selectedSkuAttributesValues).reduce<Record<string, AttributeApiDto>>(
          (acc, [attributeTypeCode, attributeValueCode]) => {
            acc[attributeTypeCode] = skuVariantAttributesMapByValueCode[attributeValueCode];
            return acc;
          },
          {}
        ),
      [selectedSkuAttributesValues, skuVariantAttributesMapByValueCode]
    );

    const image = React.useMemo(
      () =>
        selectedSku
          ? selectedSku.image
          : imageSkuVariantAttributeTypeCode && selectedSkuAttributes[imageSkuVariantAttributeTypeCode] !== undefined
          ? selectedProductVariant.skus?.filter((sku) =>
              sku.attributes?.some(
                (attribute) =>
                  attribute.valueCode === selectedSkuAttributes[imageSkuVariantAttributeTypeCode]?.valueCode
              )
            )[0].image
          : product.image,
      [imageSkuVariantAttributeTypeCode, product.image, selectedProductVariant.skus, selectedSku, selectedSkuAttributes]
    );

    const isAtLeastOneSkuAvailable = React.useMemo(() => {
      return selectedProductVariant.skus?.some((sku) => sku.state === 'AVAILABLE');
    }, [selectedProductVariant.skus]);

    const _initialValues = React.useMemo<FormValues>(
      () => ({
        giftCard: product.giftCard
          ? {
              message: getInitialString(),
              receiverEmail: getInitialString(),
              receiverName: getInitialString(),
              senderName: getInitialString(),
            }
          : undefined,
        price: getInitialString(undefined),
        productVariantId: getInitialString(selectedProductVariant.id),
        quantity: getInitialNumber(1),
        skuAttributes: Object.keys(skuVariantAttributesMap).reduce<Record<string, string>>((acc, key) => {
          acc[key] = getInitialString();
          return acc;
        }, {}),
        skuId: getInitialString(undefined),
        ...initialValues,
      }),
      [initialValues, product.giftCard, selectedProductVariant.id, skuVariantAttributesMap]
    );

    const validationSchema = React.useMemo<ValidationSchema>(
      () => ({
        giftCard: product.giftCard
          ? yup.object({
              message: yup.string(),
              receiverEmail: yup.string().required(),
              receiverName: yup.string().required(),
              senderName: yup.string().required(),
            })
          : undefined,
        price: yup.string().required(),
        productVariantId: yup.string().required(),
        quantity: yup.number().required().min(1),
        skuAttributes: yup.object(
          Object.keys(skuVariantAttributesMap).reduce(
            (acc, key) => ({
              ...acc,
              [key]: yup.string().required(),
            }),
            {}
          )
        ),
        skuId: yup.string().required(),
      }),
      [product.giftCard, skuVariantAttributesMap]
    );

    const { addSku } = useCartApi();

    const { onAddToCartSuccess } = useAppStaticContext();

    const listingContext = useListingContextStrict();

    const handleSubmit = React.useCallback(
      (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        if (selectedSku === undefined) {
          throw new Error('Form - addToCart - missing selectedSku');
        }
        return addSku({
          giftCard: values.giftCard
            ? {
                message: values.giftCard.message,
                receiverEmail: values.giftCard.receiverEmail,
                receiverName: values.giftCard.receiverName,
                senderName: values.giftCard.senderName,
              }
            : undefined,
          product: selectedProductVariant,
          quantity: getFormikNumberValue(values.quantity) ?? 1,
          sku: selectedSku,
        }).then((response) => {
          analytics.events.addToCart(
            response.eventId,
            selectedProductVariant,
            getFormikNumberValue(values.quantity) ?? 1,
            selectedSku,
            listingContext
              ? {
                  index: listingContext.getProductIndex(selectedProductVariant.id),
                  name: listingContext.name,
                }
              : undefined
          );
          onAddToCartSuccess && onAddToCartSuccess();
        });
      },
      [addSku, listingContext, onAddToCartSuccess, selectedProductVariant, selectedSku]
    );

    const resetSkuIdSelection = React.useCallback((formikHelpers: FormikHelpers<FormValues>) => {
      formikHelpers.setFieldValue('skuId', '');
      formikHelpers.setFieldTouched('skuId', false);
    }, []);

    const resetSkuAttributesSelection = React.useCallback(
      (formikHelpers: FormikHelpers<FormValues>) => {
        Object.keys(skuVariantAttributesMap).forEach((attributeTypeCode) => {
          formikHelpers.setFieldValue(`skuAttributes.${attributeTypeCode}`, '');
          formikHelpers.setFieldTouched(`skuAttributes.${attributeTypeCode}`, false);
        });
      },
      [skuVariantAttributesMap]
    );

    const handleProductVariantIdChange = React.useCallback(
      (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        // const _selectedProductVariant = getSelectedProductVariant(values.productVariantId);
        // if (_selectedProductVariant.skus?.length === 1) {
        //   formikHelpers.setFieldValue('skuId', _selectedProductVariant.skus[0].id);
        // } else {

        resetSkuIdSelection(formikHelpers);
        resetSkuAttributesSelection(formikHelpers);
        // }
        // setSelectedProductVariantId(values.productVariantId);

        // if (selectedProductVariantId !== product.id) {
        getProduct(values.productVariantId, {
          populate:
            scope === 'product'
              ? { ...appConfig.api?.productPopulate, variantProducts: false }
              : { ...appConfig.api?.productTilePopulate, variantProducts: false },
        }).then((response) => {
          setSelectedProductVariant(response.data);
        });
        // } else {
        //   setSelectedProductVariant(product);
        // }
      },
      [getProduct, resetSkuAttributesSelection, resetSkuIdSelection, scope]
    );

    const handleSkuIdChange = React.useCallback(
      (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        const _selectedSku = selectedProductVariant.skus?.find((sku) => sku.id === values.skuId);
        formikHelpers.setFieldValue('price', _selectedSku?.price?.value ?? '');
        if (_selectedSku) {
          _selectedSku.attributes
            ?.filter((attribute) => attribute.variant)
            .forEach((attribute) => {
              attribute.typeCode &&
                formikHelpers.setFieldValue(`skuAttributes.${attribute.typeCode}`, attribute.valueCode);
            });
        } else {
          resetSkuAttributesSelection(formikHelpers);
        }
        setSelectedSkuId(values.skuId);
      },
      [resetSkuAttributesSelection, selectedProductVariant.skus]
    );

    const handleSkuAttributesChange = React.useCallback(
      (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        const possibleSkuChoices = getPossibleSkuChoices(undefined, values.skuAttributes);
        if (possibleSkuChoices.length === 1) {
          if (selectedProductVariant.skus && selectedProductVariant.skus.length > 1) {
            formikHelpers.setFieldValue('skuId', possibleSkuChoices[0].id);
          }
        } else {
          resetSkuIdSelection(formikHelpers);
        }
        setSelectedSkuAttributesValues(values.skuAttributes);
      },
      [getPossibleSkuChoices, resetSkuIdSelection, selectedProductVariant.skus]
    );

    // const handleMount = React.useCallback(
    //   (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    //     handleProductVariantIdChange(values, formikHelpers);
    //   },
    //   [handleProductVariantIdChange]
    // );

    const getSkuAttributeAvailability = React.useCallback(
      (attribute: AttributeApiDto, skuAttributesValues: Record<string, string>): SkuStateApiType => {
        const possibleSkuChoices = getPossibleSkuChoices(attribute.typeCode, skuAttributesValues);
        const possibleSkuChoiceWithThisAttribute = possibleSkuChoices.filter((possibleSkuChoice) =>
          possibleSkuChoice.attributes?.some(
            (possibleSkuChoiceAttribute) =>
              possibleSkuChoiceAttribute.typeCode === attribute.typeCode &&
              possibleSkuChoiceAttribute.valueCode === attribute.valueCode
          )
        );
        return possibleSkuChoiceWithThisAttribute.length === 0
          ? 'NOT_AVAILABLE'
          : possibleSkuChoiceWithThisAttribute.length === 1
          ? possibleSkuChoiceWithThisAttribute[0].state
          : possibleSkuChoiceWithThisAttribute.some((sku) => {
              return sku.state === 'AVAILABLE';
            })
          ? 'AVAILABLE'
          : possibleSkuChoiceWithThisAttribute.some((sku) => {
              return sku.state === 'AVAILABLE_SOON';
            })
          ? 'AVAILABLE_SOON'
          : 'NOT_AVAILABLE';
      },
      [getPossibleSkuChoices]
    );

    const handleFieldsChange = React.useMemo<FormFieldsChangeHandlerProps<FormValues>>(
      () => ({
        productVariantId: handleProductVariantIdChange,
        skuAttributes: handleSkuAttributesChange,
        skuId: handleSkuIdChange,
      }),
      [handleProductVariantIdChange, handleSkuAttributesChange, handleSkuIdChange]
    );

    const handleMount = React.useCallback(
      (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
        let idToPreselect = undefined;

        if (
          selectedProductVariant.skus?.length === 1 &&
          (selectedProductVariant.skus[0].state === 'AVAILABLE' || appConfig.enableProductAvailabilityEmailNotification)
        ) {
          idToPreselect = selectedProductVariant.skus[0].id;
        }

        if (idToPreselect === undefined && preselectedSkuId !== undefined) {
          const preselectedSku = selectedProductVariant.skus?.find((sku) => sku.id === preselectedSkuId);
          if (
            preselectedSku &&
            (preselectedSku.state === 'AVAILABLE' || appConfig.enableProductAvailabilityEmailNotification)
          ) {
            idToPreselect = preselectedSku.id;
          }
        }

        if (idToPreselect === undefined && appConfig.preselectPreferredSku) {
          const preferredSku = selectedProductVariant.skus?.find((sku) => sku.preferred === true);
          if (
            preferredSku &&
            (preferredSku.state === 'AVAILABLE' || appConfig.enableProductAvailabilityEmailNotification)
          ) {
            idToPreselect = preferredSku.id;
          }
        }

        if (idToPreselect === undefined && appConfig.preselectFirstAvailableSku) {
          const firstAvailableSku = selectedProductVariant.skus?.find((sku) => sku.state === 'AVAILABLE');
          if (firstAvailableSku) {
            idToPreselect = firstAvailableSku.id;
          }
        }

        if (idToPreselect !== undefined) {
          formikHelpers.setFieldValue('skuId', idToPreselect);
        }
      },
      [preselectedSkuId, selectedProductVariant.skus]
    );

    const ref = React.useRef<HTMLFormElement>(null);

    const { insideModal } = useInsideModalDetector();

    const closeModal = useModalCloser();

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    // useEffect che impostano il prodotto / variante / sku selezionati nel contesto del prodotto
    ////////////////////////////////////////////////////////////////////////////////////////////////////

    // React.useEffect(() => {}, [product]);

    // React.useEffect(() => {
    //   onSelectedProductVariantChange && onSelectedProductVariantChange(selectedProductVariant);
    // }, [onSelectedProductVariantChange, selectedProductVariant]);

    // React.useEffect(() => {
    //   onSelectedSkuChange && onSelectedSkuChange(selectedSku);
    // }, [onSelectedSkuChange, selectedSku]);

    React.useEffect(() => {
      if (scope === 'product') {
        productContext?.setSelectedProductVariant(selectedProductVariant);
      }
    }, [productContext, scope, selectedProductVariant]);

    React.useEffect(() => {
      if (scope === 'product') {
        productContext?.setSelectedSku(selectedSku);
      }
    }, [productContext, scope, selectedSku]);

    const equalHeightContextStrict = useEqualHeightContextStrict();

    // FIXME Capire perchè parte 2 volte
    const handleChange = React.useCallback(() => {
      if (equalHeightContextStrict) {
        equalHeightContextStrict.updateTrigger();
      }
    }, [equalHeightContextStrict]);

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    const { handleWishlistButtonClick, inWishlist } = useWishlistButtonHelper({
      product: selectedProductVariant,
      sku: selectedSku,
    });

    return (
      <HelpedForm<FormValues>
        className={classnames('AddToCartForm', className)}
        enableReinitialize
        initialValues={_initialValues}
        innerRef={ref}
        onChange={handleChange}
        onFieldsChange={handleFieldsChange}
        onMount={handleMount}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
        {...otherProps}
      >
        {({ formik }) => {
          const fieldsHelper: FieldsHelper = {
            buttons: {
              availabilityEmailNotification: {
                label: inWishlist
                  ? t('form.addToCartForm.buttons.availabilityEmailNotification.remove')
                  : t('form.addToCartForm.buttons.availabilityEmailNotification.addLongLabel'),
                onClick: handleWishlistButtonClick,
              },
              cancel: onCancel
                ? {
                    label: t('form.addToCartForm.buttons.cancel'),
                    onClick: onCancel,
                    type: 'button',
                    variant: appConfig.form?.buttons.cancel?.defaultVariant,
                  }
                : undefined,
              quantity: {
                decrease: {
                  disabled: formik.values.quantity === 1,
                  label: '-',
                  onClick: () => {
                    const previousQuantity = getFormikNumberValue(formik.values.quantity);
                    if (previousQuantity) {
                      formik.setFieldValue('quantity', previousQuantity - 1);
                    }
                  },
                },
                increase: {
                  disabled: formik.values.quantity === appConfig.productMaxQuantity,
                  label: '+',
                  onClick: () => {
                    const previousQuantity = getFormikNumberValue(formik.values.quantity);
                    if (previousQuantity) {
                      formik.setFieldValue('quantity', previousQuantity + 1);
                    }
                  },
                },
              },
              reset: {
                disabled: isResetButtonDisabled(formik),
                label: t('form.addToCartForm.buttons.reset'),
                type: 'reset',
                variant: appConfig.form?.buttons.cancel?.defaultVariant,
              },
              submit: {
                disabled: isSubmitButtonDisabled(formik) || !isAtLeastOneSkuAvailable,
                label: isAtLeastOneSkuAvailable
                  ? t('form.addToCartForm.buttons.submit')
                  : t('form.addToCartForm.buttons.submitButNotAvailable'),
                type: 'submit',
                variant: appConfig.form?.buttons.submit?.defaultVariant,
              },
            },
            giftCard: product.giftCard
              ? {
                  message: {
                    formGroup: { label: t('form.addToCartForm.giftCard.message.label'), names: ['giftCard.message'] },
                    textArea: { name: 'giftCard.message', rows: 3 },
                  },
                  receiverEmail: {
                    formGroup: {
                      label: t('form.addToCartForm.giftCard.receiverEmail.label'),
                      names: ['giftCard.receiverEmail'],
                    },
                    textInput: { name: 'giftCard.receiverEmail', type: 'email' },
                  },
                  receiverName: {
                    formGroup: {
                      label: t('form.addToCartForm.giftCard.receiverName.label'),
                      names: ['giftCard.receiverName'],
                    },
                    textInput: { name: 'giftCard.receiverName' },
                  },
                  senderName: {
                    formGroup: {
                      label: t('form.addToCartForm.giftCard.senderName.label'),
                      names: ['giftCard.senderName'],
                    },
                    textInput: { name: 'giftCard.senderName' },
                  },
                }
              : undefined,
            productVariants: {
              formFields: productVariants.map((productVariant) => ({
                productVariant: productVariant,
                radio: {
                  id: `productvariant-${productVariant.id}`,
                  label: `${productVariant.name} (${productVariant.id})`,
                  name: 'productVariantId',
                  value: productVariant.id,
                },
              })),
              formGroup: {
                label: t('form.addToCartForm.productVariants'),
                names: ['productVariantId'],
                omitForAttribute: true,
              },
            },
            quantity: {
              formGroup: { label: t('form.addToCartForm.quantity.label'), names: ['quantity'] },
              numberInput: {
                max: appConfig.productMaxQuantity,
                min: 1,
                name: 'quantity',
                placeholder: t('form.addToCartForm.quantity.placeholder'),
              },
              select: {
                name: 'quantity',
                options: [...Array(appConfig.productMaxQuantity)].map((quantity, index) => ({
                  label: (index + 1).toString(),
                  value: (index + 1).toString(),
                })),
                placeholder: t('form.addToCartForm.quantity.placeholder'),
              },
            },
            skuAttributes: Object.entries(skuVariantAttributesMap).map(([attributeTypeCode, items]) => ({
              attribute: {
                title: items[0].attribute.title ?? '',
                typeCode: items[0].attribute.typeCode,
                value:
                  formik.values.skuAttributes[attributeTypeCode] !== ''
                    ? formik.values.skuAttributes[attributeTypeCode]
                    : undefined,
              },
              formGroup: { label: items[0].attribute.title, names: [] },
              radios: items.map((item) => ({
                radio: {
                  className: getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes),
                  disabled:
                    !appConfig.enableProductAvailabilityEmailNotification &&
                    (getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'NOT_AVAILABLE' ||
                      getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'AVAILABLE_SOON'),
                  id: `sku-attribute-${item.attribute.valueCode}`,
                  label: item.attribute.value ?? '',
                  name: `skuAttributes.${attributeTypeCode}`,
                  value: item.attribute.valueCode ?? '',
                },
                skus: item.skus,
              })),
              // radios: {
              //   radios: attributes.map((attribute) => ({
              //     disabled:
              //       getSkuAttributeAvailability(attribute, formik.values.skuAttributes) === 'NOT_AVAILABLE' ||
              //       getSkuAttributeAvailability(attribute, formik.values.skuAttributes) === 'AVAILABLE_SOON',
              //     id: `sku-attribute-${attribute.value}`,
              //     label: attribute.value ?? '',
              //     name: `skuAttributes.${slugify(attributeName)}`,
              //     value: attribute.value ?? '',
              //   })),
              // },
              select: {
                includeEmptyOption: false,
                name: `skuAttributes.${attributeTypeCode}`,
                options: items.map((item) => ({
                  className: getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes),
                  disabled:
                    !appConfig.enableProductAvailabilityEmailNotification &&
                    (getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'NOT_AVAILABLE' ||
                      getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'AVAILABLE_SOON'),
                  id: `sku-attribute-${item.attribute.valueCode}`,
                  label:
                    attributeTypeCode === 'Gift_card_valore' && item.attribute.value
                      ? formatCurrency(parseInt(item.attribute.value))
                      : item.attribute.value ?? '',
                  value: item.attribute.valueCode ?? '',
                })),
                placeholder: t(`form.addToCartForm.skuAttributes.${attributeTypeCode}.placeholder` as ResourceKeys),
              },
            })),
            skus: {
              formFields: selectedProductVariant.skus
                ? selectedProductVariant.skus.map((sku) => ({
                    option: {
                      className: sku.state,
                      disabled:
                        !appConfig.enableProductAvailabilityEmailNotification &&
                        (sku.state === 'NOT_AVAILABLE' || sku.state === 'AVAILABLE_SOON'),
                      label: sku.name,
                      value: sku.id,
                    },
                    radio: {
                      className: sku.state,
                      disabled:
                        !appConfig.enableProductAvailabilityEmailNotification &&
                        (sku.state === 'NOT_AVAILABLE' || sku.state === 'AVAILABLE_SOON'),
                      id: `sku-${sku.id}`,
                      label: sku.name,
                      name: 'skuId',
                      value: sku.id,
                    },
                    sku: sku,
                  }))
                : [],
              formGroup: { label: t('form.addToCartForm.skus.label'), names: ['skuId'], omitForAttribute: true },
              select: {
                includeEmptyOption: false,
                name: 'skuId',
                placeholder: t('form.addToCartForm.skus.placeholder'),
              },
            },
          };
          return children ? (
            children({
              closeModal,
              fieldsHelper,
              formik,
              image,
              insideModal,
              priceHelper,
              selectedProductVariant,
              selectedSku,
              selectedSkuAttributes,
            })
          ) : (
            <>
              {fieldsHelper.productVariants.formFields.length > 1 && (
                <FormGroup {...fieldsHelper.productVariants.formGroup}>
                  {fieldsHelper.productVariants.formFields.map((formField) => (
                    <Radio key={formField.productVariant.id} {...formField.radio} />
                  ))}
                </FormGroup>
              )}
              {Object.entries(fieldsHelper.skuAttributes).map(([name, { formGroup, radios }]) => (
                <FormGroup key={name} {...formGroup}>
                  {radios.map(({ radio }) => (
                    <Radio key={radio.id} {...radio} />
                  ))}
                </FormGroup>
              ))}
              <FormGroup {...fieldsHelper.skus.formGroup}>
                {fieldsHelper.skus.formFields.map((formField) => (
                  <Radio key={formField.sku.id} {...formField.radio} />
                ))}
              </FormGroup>
              <FormGroup {...fieldsHelper.quantity.formGroup}>
                <Select {...fieldsHelper.quantity.select} />
              </FormGroup>
              {fieldsHelper.giftCard && (
                <>
                  <FormGroup {...fieldsHelper.giftCard.senderName.formGroup}>
                    <TextInput {...fieldsHelper.giftCard.senderName.textInput} />
                  </FormGroup>
                  <FormGroup {...fieldsHelper.giftCard.receiverName.formGroup}>
                    <TextInput {...fieldsHelper.giftCard.receiverName.textInput} />
                  </FormGroup>
                  <FormGroup {...fieldsHelper.giftCard.receiverEmail.formGroup}>
                    <TextInput {...fieldsHelper.giftCard.receiverEmail.textInput} />
                  </FormGroup>
                  <FormGroup {...fieldsHelper.giftCard.message.formGroup}>
                    <TextArea {...fieldsHelper.giftCard.message.textArea} />
                  </FormGroup>
                </>
              )}
              <AddToCartFormButton fieldsHelper={fieldsHelper} selectedSku={selectedSku} />
              {/* <Button {...fieldsHelper.buttons.submit} /> */}
              {prettyStringify(formik.values)}
            </>
          );
        }}
      </HelpedForm>
    );
  }
);
AddToCartFormHelper.displayName = 'AddToCartFormHelper';

export type AddToCartFormVariants = '';

const AddToCartFormController = (props: PropsWithCustomComponentWithoutChildren<AddToCartFormProps>) => (
  <VariantsController<AddToCartFormProps, AddToCartFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: AddToCartFormHelper,
      name: 'AddToCartForm',
    }}
  />
);
export { AddToCartFormController as AddToCartForm };
