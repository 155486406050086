import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { UseFormModalProps } from '../Modal';
import { useNavigate, useSearchParams } from '../router/router';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { formatHtml } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBoolean,
  formikString,
  getInitialBoolean,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { InputGroupProps } from './InputGroup';

export type NewsletterSource = 'website-footer' | 'website-modal' | 'website-section' | 'totem';

export interface NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>
  extends BaseHelpedFormProps<FormValues<AdditionalPropertiesFormValues>, FieldsHelper, ValidationSchemaSelector> {
  additionalProperties?: {
    formValues: Record<string, (values: FormValues<AdditionalPropertiesFormValues>) => string>;
    initialValues: AdditionalPropertiesFormValues;
    validationSchema: AdditionalPropertiesValidationSchema;
  };
  ctaContainerClassName?: string;
  email?: string;
  emailFieldStyle?: 'textInput' | 'inputGroup' | 'inputGroupFromLg';
  redirectToHomeOnSuccessModalClose?: boolean;
  showAdditionalProperties?: boolean;
  source: NewsletterSource;
}

interface FormValues<AdditionalPropertiesFormValues> {
  additionalProperties?: AdditionalPropertiesFormValues;
  email: formikString;
  name: formikString;
  newsletterConsent: formikBoolean;
  source: formikString;
  surname: formikString;
}

export type NewsletterFormValues<AdditionalPropertiesFormValues> = FormValues<AdditionalPropertiesFormValues>;

type ValidationSchema<AdditionalPropertiesValidationSchema> = {
  additionalProperties?: AdditionalPropertiesValidationSchema;
  email: yup.StringSchema;
  name: yup.StringSchema;
  newsletterConsent: yup.BooleanSchema;
  source: yup.StringSchema;
  surname: yup.StringSchema;
};

interface ValidationSchemaSelector {
  name: boolean;
  surname: boolean;
}

export type NewsletterFormValidationSchemaSelector = ValidationSchemaSelector;

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  email: { formGroup: FormGroupProps; inputGroup: InputGroupProps; textInput: TextInputProps };
  name: { formGroup: FormGroupProps; textInput: TextInputProps };
  newsletterConsent: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  surname: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const NewsletterFormHelper = <
  AdditionalPropertiesFormValues extends Record<string, unknown>,
  AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema
>({
  additionalProperties,
  children,
  className,
  email,
  emailFieldStyle = 'textInput',
  initialValues,
  onCancel,
  onSuccess,
  redirectToHomeOnSuccessModalClose,
  showAdditionalProperties,
  source,
  validationSchemaSelector = { name: true, surname: true },
  ...otherProps
}: NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>) => {
  const [searchParams] = useSearchParams();
  const campoEmail = searchParams.get('email') ?? undefined;
  const _initialValues = React.useMemo<FormValues<AdditionalPropertiesFormValues>>(
    () => ({
      additionalProperties: additionalProperties?.initialValues,
      email: getInitialString(campoEmail),
      name: getInitialString(),
      newsletterConsent: getInitialBoolean(),
      source: getInitialString(source),
      surname: getInitialString(),
      ...initialValues,
    }),
    [additionalProperties?.initialValues, campoEmail, initialValues, source]
  );

  const validationSchema = React.useMemo<ValidationSchema<AdditionalPropertiesValidationSchema>>(
    () => ({
      additionalProperties: additionalProperties?.validationSchema,
      email: yup.string().required().email(),
      name: validationSchemaSelector.name ? yup.string().required() : yup.string(),
      newsletterConsent: yup.boolean().required().oneOf([true]),
      source: yup.string().required(),
      surname: validationSchemaSelector.surname ? yup.string().required() : yup.string(),
    }),
    [additionalProperties?.validationSchema, validationSchemaSelector.name, validationSchemaSelector.surname]
  );

  const { subscribeToNewsletter } = useCustomerApi();
  const { showModal } = useModals();
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    (
      values: FormValues<AdditionalPropertiesFormValues>,
      formikHelpers: FormikHelpers<FormValues<AdditionalPropertiesFormValues>>
    ) =>
      subscribeToNewsletter({
        additionalProperties: additionalProperties?.formValues
          ? Object.entries(additionalProperties.formValues).reduce<Record<string, string>>((acc, [key, transform]) => {
              acc[key] = transform(values);
              return acc;
            }, {})
          : undefined,
        email: values.email,
        name: values.name,
        registrationSource: values.source,
        surname: values.surname,
      }).then(() => {
        onSuccess
          ? onSuccess()
          : showModal({
              buttons: [
                {
                  close: true,
                  label: t('form.newsletterForm.success.buttons.ok.label'),
                },
              ],
              children: t('form.newsletterForm.success.body'),
              className: 'modal-newsletter-form',
              onClose: () => {
                if (redirectToHomeOnSuccessModalClose) {
                  navigate('/');
                }
              },
              title: t('form.newsletterForm.success.title'),
            });
      }),
    [additionalProperties, navigate, onSuccess, redirectToHomeOnSuccessModalClose, showModal, subscribeToNewsletter]
  );

  const ref = React.useRef<HTMLFormElement>(null);
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues<AdditionalPropertiesFormValues>>
      className={classnames('NewsletterForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      resetOnSuccess
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.newsletterForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.newsletterForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          email: {
            formGroup: { label: t('form.newsletterForm.email.label'), names: ['email'] },
            inputGroup: { names: ['email'] },
            textInput: {
              name: 'email',
              placeholder: t('form.newsletterForm.email.placeholder'),
              type: 'email',
            },
          },
          name: {
            formGroup: { label: t('form.newsletterForm.name.label'), names: ['name'] },
            textInput: {
              name: 'name',
              placeholder: t('form.newsletterForm.name.placeholder'),
            },
          },
          newsletterConsent: {
            checkbox: {
              id: 'newsletterConsent',
              label: formatHtml(consentsContent?.body.privacyNewsletter),
              name: 'newsletterConsent',
            },
            formGroup: { label: t('form.newsletterForm.newsletterConsent.label'), names: ['newsletterConsent'] },
          },
          surname: {
            formGroup: { label: t('form.newsletterForm.surname.label'), names: ['surname'] },
            textInput: {
              name: 'surname',
              placeholder: t('form.newsletterForm.surname.placeholder'),
            },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.name.formGroup}>
              <TextInput {...fieldsHelper.name.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.surname.formGroup}>
              <TextInput {...fieldsHelper.surname.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.newsletterConsent.formGroup}>
              <Checkbox {...fieldsHelper.newsletterConsent.checkbox} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type NewsletterFormVariants = '';

const NewsletterFormController = <
  AdditionalPropertiesFormValues extends Record<string, unknown>,
  AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema
>(
  props: PropsWithCustomComponentWithoutChildren<
    NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>
  >
) => (
  <VariantsController<
    NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>,
    NewsletterFormVariants
  >
    {...props}
    variantsControllerConfig={{
      defaultComponent: NewsletterFormHelper,
      name: 'NewsletterForm',
    }}
  />
);
export { NewsletterFormController as NewsletterForm };

export const useNewsletterFormModal = <
  AdditionalPropertiesFormValues extends Record<string, unknown>,
  AdditionalPropertiesValidationSchema extends yup.AnyObjectSchema
>(
  modalProps?: UseFormModalProps
) => {
  const { showModal } = useModals();
  return React.useCallback(
    (formProps: NewsletterFormProps<AdditionalPropertiesFormValues, AdditionalPropertiesValidationSchema>) => {
      showModal({
        children: <NewsletterFormController {...formProps} />,
        fullscreen: 'sm',
        size: 'extra-large',
        title: t('form.newsletterForm.modal.title'),
        ...modalProps,
      });
    },
    [modalProps, showModal]
  );
};
