import { ImageContentSection } from './contentTypes';
import { ImageAsBackground, ImageAsBackgroundProps } from './ImageAsBackground';

export interface ImageAsBackgroundFromContentProps extends ImageContentSection, Omit<ImageAsBackgroundProps, 'src'> {}

export const ImageAsBackgroundFromContent = ({
  backgroundSize = 'cover',
  className,
  src,
  ...otherProps
}: ImageAsBackgroundFromContentProps) => {
  return (
    <>
      {src?.xs?.url && (
        <ImageAsBackground
          {...otherProps}
          backgroundSize={backgroundSize}
          className={className}
          src={{
            lg: src.lg?.url,
            md: src.md?.url,
            sm: src.sm?.url,
            xl: src.xl?.url,
            xs: src.xs.url,
            xxl: src.xxl?.url,
          }}
        />
      )}
    </>
  );
};
