import { b2x } from '@b2x/react/src';
import { SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { CardRowSectionLandingPageAContentType } from '../contentTypes';
import { Slider } from './Slider';

export interface SliderCardRowSectionLandingPageAProps extends CardRowSectionLandingPageAContentType {}

export const SliderCardRowSectionLandingPageA = ({
  cardItemList,
  cta,
  subtitle,
  title,
}: SliderCardRowSectionLandingPageAProps) => {
  return (
    <div>
      <div className="my-5">
        {(title || subtitle) && (
          <Container>
            <div className="mb-4">
              <b2x.Row className="justify-content-center">
                <b2x.Col size={{ lg: 10, xl: 8, xs: 12 }}>
                  {title && <h2 className="mb-3 text-blue text-center">{b2x.formatHtml(title)}</h2>}
                  {subtitle && <p className="mb-3 text-center">{b2x.formatHtml(subtitle)}</p>}
                </b2x.Col>
              </b2x.Row>
            </div>
          </Container>
        )}
        {cardItemList && (
          <Container className="pe-0 px-lg-3">
            <b2x.EqualHeight>
              <Slider
                responsive={{
                  lg: {
                    slidesPerView: 3,
                  },
                  md: {
                    slidesPerView: 2.2,
                  },
                }}
                slidesPerView={1.2}
                spaceBetween={30}
              >
                {cardItemList.map((item) => (
                  <SwiperSlide key={item.contentSectionId}>
                    <div className="position-relative h-100 border border-gray-200 rounded-1">
                      <b2x.ImageFromContent {...item.img} className="w-100" fluid />
                      <div className="px-3 mt-5">
                        <b2x.EqualHeightElement name="sliderCardTitle">
                          {item.title && <h5 className="text-blue fw-bold">{item.title}</h5>}
                        </b2x.EqualHeightElement>
                        <b2x.EqualHeightElement name="sliderCardDescription">
                          {item.description && <p className="mt-2">{b2x.formatHtml(item.description)}</p>}
                        </b2x.EqualHeightElement>
                        <b2x.EqualHeightElement name="sliderCardCta">
                          {item.cta?.label && (
                            <div className="my-3">
                              <b2x.DeprecatedCta className="text-decoration-none small text-primary" cta={item.cta}>
                                {b2x.formatHtml(item.cta.label)}
                                <b2x.Icon className="ps-1" name="small-arrow-right" size={16} />
                              </b2x.DeprecatedCta>
                            </div>
                          )}
                        </b2x.EqualHeightElement>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Slider>
            </b2x.EqualHeight>
          </Container>
        )}
        {cta?.label && (
          <div className="text-center mt-4">
            <b2x.DeprecatedCta className="btn btn-outline-primary" cta={cta}>
              {cta.label}
            </b2x.DeprecatedCta>
          </div>
        )}
      </div>
    </div>
  );
};
