import React from 'react';

import { useModals } from './useModals';

export interface ModalContextInterface {
  addEventListener(
    event: 'show' | 'shown' | 'hide' | 'hidden' | 'hidePrevented',
    type: 'modal' | 'offcanvas',
    callback: () => void,
    alsoWhenKeepingForLater?: boolean
  ): void;
  close(): void;
  id: string;
}

// Purtroppo per un motivo a me sconosciuto, bisogna ricreare questa funzione qui, piuttosto che importala da util,
// altrimenti si rompe tutto.
const createContext = <T extends unknown | null>(displayName: string) => {
  const Context = React.createContext<T | undefined>(undefined);
  Context.displayName = displayName;
  const useContext = () => {
    const context = React.useContext(Context);
    if (context === undefined)
      throw new Error(`useContext must be inside a Provider with a value (missing: ${displayName})`);
    return context;
  };
  const useContextStrict = () => {
    const context = React.useContext(Context);
    return context;
  };
  return [Context.Provider, useContext, useContextStrict] as const;
};

export const [ModalContextProvider, useModalContext, useModalContextStrict] =
  createContext<ModalContextInterface>('ModalContext');

interface UseModalContextInitializerProps {
  id: string;
}

export const useModalContextInitializer = ({ id }: UseModalContextInitializerProps) => {
  const { addEventListener: _addEventListener, close: _close } = useModals();

  const close = React.useCallback(() => {
    _close(id);
  }, [_close, id]);

  const addEventListener = React.useCallback(
    (
      event: 'show' | 'shown' | 'hide' | 'hidden' | 'hidePrevented',
      type: 'modal' | 'offcanvas',
      callback: () => void,
      alsoWhenKeepingForLater: boolean
    ) => {
      _addEventListener(id, event, type, callback, alsoWhenKeepingForLater);
    },
    [_addEventListener, id]
  );

  const modalContext: ModalContextInterface = React.useMemo(
    () => ({
      addEventListener,
      close,
      id,
    }),
    [addEventListener, close, id]
  );

  return {
    ModalContextProvider,
    modalContext,
  };
};

export interface ModalContextProps extends UseModalContextInitializerProps {
  children: React.ReactNode | ((modalContext: ModalContextInterface) => React.ReactNode);
}

export const ModalContext = ({ children, ...otherProps }: ModalContextProps) => {
  const modalContextInitializer = useModalContextInitializer(otherProps);
  return (
    <modalContextInitializer.ModalContextProvider value={modalContextInitializer.modalContext}>
      {typeof children === 'function' ? children(modalContextInitializer.modalContext) : children}
    </modalContextInitializer.ModalContextProvider>
  );
};
