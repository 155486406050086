import { PageApiDto } from '@b2x/storefront-api-js-client/src';
import classnames from 'classnames';
import React from 'react';

import { b2x } from '.';
import { useContentsApi } from './api/useContentsApi';
import { usePagesApi } from './api/usePagesApi';
import { useProductsApi } from './api/useProductsApi';
import { useAppContext } from './AppContext';
import { ResourceKeys, t } from './i18n/i18n';
import { useSearchParams } from './router/router';
import { getRouterBasename, isFriendlySearch } from './util';

export interface BreadcrumbProps {
  classNameObject?: {
    a?: string;
    component?: string;
    li?: string;
    ol?: string;
  };
  contentCodeListToHide?: Array<string>;
  hiddenLastItem?: boolean;
  lastItemLinkable?: boolean;
}

export const Breadcrumb = ({
  classNameObject,
  contentCodeListToHide,
  hiddenLastItem,
  lastItemLinkable,
}: BreadcrumbProps) => {
  const { locale, routeInfo, shippingCountry } = useAppContext();
  // const location = useLocation();
  const { getPage, getPageByPath } = usePagesApi();
  const { getProductByPath } = useProductsApi();
  const { getContentByPath } = useContentsApi();
  const [searchParams] = useSearchParams();

  const [bredcrumbItems, setBredcrumbItems] = React.useState<Array<PageApiDto<unknown>>>();

  const setFixedBreadcrumbItems = React.useCallback(() => {
    // Questa callback serve per impostare la home e la pagina corrente
    if (routeInfo) {
      setBredcrumbItems([
        { code: 'SITEMAP', fullPath: '/', id: 'home', name: t('fixedPagesName.home'), path: '/' },
        {
          code: routeInfo.pathname,
          fullPath: routeInfo.pathname,
          id: routeInfo.pathname,
          name: t(`fixedPagesName.${routeInfo.pathname.substring(1)}` as ResourceKeys),
          path: routeInfo.pathname,
        },
      ]);
    }
  }, [routeInfo]);

  React.useEffect(() => {
    if (routeInfo?.type !== undefined) {
      /*
        Faccio questo controllo (startsWith) in quanto a volte questa useEffect parte troppo presto,
        ovvero prima che routeInfo si sia aggiornato
      */
      // if (location.pathname.startsWith(routeInfo.pathname)) {
      if (
        routeInfo.type === 'catalog' ||
        (routeInfo.type === 'fixed' && routeInfo.pathname === '/search') ||
        routeInfo.location.pathname.startsWith('/search')
      ) {
        // Search
        if (isFriendlySearch(routeInfo.location)) {
          getPageByPath(routeInfo.location.pathname, { populate: { breadcrumb: true } }).then((response) => {
            setBredcrumbItems(response.data.breadcrumb);
          });
        } else if (searchParams.getAll('categories').length === 1) {
          const singleCategoryId = searchParams.get('categories');
          singleCategoryId &&
            getPage(singleCategoryId, { populate: { breadcrumb: true } }).then((response) => {
              setBredcrumbItems(response.data.breadcrumb);
            });
        } else {
          setFixedBreadcrumbItems();
        }
      } else if (routeInfo.type === 'fixed') {
        // Fixed
        setFixedBreadcrumbItems();
      } else if (routeInfo.type === 'product') {
        // Prodotto
        routeInfo.pathParam &&
          getProductByPath(routeInfo.pathParam, { populate: { breadcrumbs: true } }).then((response) => {
            response.data.breadcrumbs && setBredcrumbItems(response.data.breadcrumbs[0]);
          });
      } else if (routeInfo.type === 'content') {
        // Content
        routeInfo.pathParam &&
          getContentByPath(routeInfo.pathParam, { populate: { pages: { breadcrumb: true } } }).then((response) => {
            response.data.pages &&
              setBredcrumbItems(
                response.data.pages[0].breadcrumb?.filter((item) => !contentCodeListToHide?.includes(item.code))
              );
          });
      } else {
        // Il resto dei casi non undefined
        getPageByPath(routeInfo.location.pathname, { populate: { breadcrumb: true } }).then((response) => {
          setBredcrumbItems(response.data.breadcrumb?.filter((item) => !contentCodeListToHide?.includes(item.code)));
        });
      }
      // }
    }
  }, [
    getPage,
    getPageByPath,
    getProductByPath,
    getContentByPath,
    routeInfo?.location,
    routeInfo?.pathname,
    routeInfo?.type,
    searchParams,
    setFixedBreadcrumbItems,
    contentCodeListToHide,
    routeInfo?.pathParam,
  ]);

  return (
    <div className={classnames('Breadcrumb', classNameObject?.component)}>
      <nav aria-label="breadcrumb">
        <ol
          className={classnames('breadcrumb', classNameObject?.ol)}
          itemScope
          itemType="https://schema.org/BreadcrumbList"
        >
          {bredcrumbItems?.map((item, index) => (
            <li
              aria-current={index + 1 === bredcrumbItems.length ? 'page' : undefined}
              className={classnames('breadcrumb-item', classNameObject?.li, {
                active: index + 1 === bredcrumbItems.length,
              })}
              itemProp="itemListElement"
              itemScope
              itemType="https://schema.org/ListItem"
              key={item.id}
              style={{ display: index + 1 === bredcrumbItems.length && hiddenLastItem ? 'none' : undefined }}
            >
              {index + 1 < bredcrumbItems.length || (index + 1 === bredcrumbItems.length && lastItemLinkable) ? (
                <b2x.router.Link
                  className={classNameObject?.a}
                  itemID={getRouterBasename(locale, shippingCountry) + item.fullPath}
                  itemProp="item"
                  to={item.fullPath}
                >
                  <span itemProp="name">{b2x.formatHtml(item.name)}</span>
                </b2x.router.Link>
              ) : (
                <span className={classNameObject?.a} itemProp="name">
                  {b2x.formatHtml(item.name)}
                </span>
              )}
              <meta content={`${index + 1}`} itemProp="position" />
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
};
