import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useMessagesApi } from '../api/useMessagesApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { formatHtml } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import { TextArea, TextAreaProps } from './fields/TextArea';
import {
  FormButtonProps,
  formikBooleanAsString,
  formikString,
  getInitialBooleanAsString,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface CustomerCareFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  subject?: string;
}

interface FormValues {
  email: formikString;
  message: formikString;
  name: formikString;
  phoneNumber: formikString;
  privacy: formikBooleanAsString;
  surname: formikString;
}

type ValidationSchema = {
  email: yup.StringSchema;
  message: yup.StringSchema;
  name: yup.StringSchema;
  phoneNumber: yup.StringSchema;
  privacy: yup.BooleanSchema;
  surname: yup.StringSchema;
};

interface ValidationSchemaSelector {
  name: boolean;
  phoneNumber: boolean;
  surname: boolean;
}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
  message: { formGroup: FormGroupProps; textArea: TextAreaProps };
  name: { formGroup: FormGroupProps; textInput: TextInputProps };
  phoneNumber: { formGroup: FormGroupProps; textInput: TextInputProps };
  privacy: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  surname: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const CustomerCareFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  subject,
  ...otherProps
}: CustomerCareFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      email: getInitialString(),
      message: getInitialString(),
      name: getInitialString(),
      phoneNumber: getInitialString(),
      privacy: getInitialBooleanAsString(),
      surname: getInitialString(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      email: yup.string().required().email(),
      message: yup.string().required(),
      name: yup.string().required(),
      phoneNumber: yup.string().required(),
      privacy: yup.boolean().required().oneOf([true]),
      surname: yup.string().required(),
    }),
    []
  );

  const { sendCustomerCareMessage } = useMessagesApi();
  const { showModal } = useModals();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      sendCustomerCareMessage({
        email: values.email,
        message: values.message,
        name: values.name,
        phoneNumber: values.phoneNumber,
        subject: subject,
        surname: values.surname,
      }).then(() => {
        onSuccess
          ? onSuccess()
          : showModal({
              children: t('form.customerCareForm.success.body'),
              title: t('form.customerCareForm.success.title'),
            });
      }),
    [onSuccess, sendCustomerCareMessage, showModal, subject]
  );

  const ref = React.useRef<HTMLFormElement>(null);
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('CustomerCareForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      resetOnSuccess
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.customerCareForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.customerCareForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          email: {
            formGroup: { label: t('form.customerCareForm.email.label'), names: ['email'] },
            textInput: { name: 'email', type: 'email' },
          },
          message: {
            formGroup: { label: t('form.customerCareForm.message.label'), names: ['message'] },
            textArea: { name: 'message', rows: 4 },
          },
          name: {
            formGroup: { label: t('form.customerCareForm.name.label'), names: ['name'] },
            textInput: { name: 'name', type: 'text' },
          },
          phoneNumber: {
            formGroup: { label: t('form.customerCareForm.phoneNumber.label'), names: ['phoneNumber'] },
            textInput: { name: 'phoneNumber', type: 'tel' },
          },
          privacy: {
            checkbox: {
              id: 'privacy',
              label: formatHtml(consentsContent?.body.privacyCustomerCare),
              name: 'privacy',
            },
            formGroup: { names: ['privacy'] },
          },
          surname: {
            formGroup: { label: t('form.customerCareForm.surname.label'), names: ['surname'] },
            textInput: { name: 'surname', type: 'text' },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.name.formGroup}>
              <TextInput {...fieldsHelper.name.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.surname.formGroup}>
              <TextInput {...fieldsHelper.surname.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.phoneNumber.formGroup}>
              <TextInput {...fieldsHelper.phoneNumber.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.message.formGroup}>
              <TextArea {...fieldsHelper.message.textArea} />
            </FormGroup>
            <FormGroup {...fieldsHelper.privacy.formGroup}>
              <Checkbox {...fieldsHelper.privacy.checkbox} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type CustomerCareFormVariants = '';

const CustomerCareFormController = (props: PropsWithCustomComponentWithoutChildren<CustomerCareFormProps>) => (
  <VariantsController<CustomerCareFormProps, CustomerCareFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CustomerCareFormHelper,
      name: 'CustomerCareForm',
    }}
  />
);
export { CustomerCareFormController as CustomerCareForm };
