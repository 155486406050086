import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCustomerApi } from '../api/useCustomerApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { UseFormModalProps } from '../Modal';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { useModals } from '../useModals';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { PasswordInput, PasswordInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { ChangePasswordFormHelper, ChangePasswordFormProps } from './ChangePasswordForm';
import { Div } from '../HTMLElement';

export const ChangePasswordFormA = (props: ChangePasswordFormProps) => (
  <ChangePasswordFormHelper {...props}>
    {({ fieldsHelper }) => (
      <>
        <FormGroup {...fieldsHelper.currentPassword.formGroup}>
          <PasswordInput {...fieldsHelper.currentPassword.passwordInput} />
        </FormGroup>
        <FormGroup {...fieldsHelper.newPassword.formGroup}>
          <PasswordInput {...fieldsHelper.newPassword.passwordInput} />
        </FormGroup>
        <FormGroup {...fieldsHelper.confirmNewPassword.formGroup}>
          <PasswordInput {...fieldsHelper.confirmNewPassword.passwordInput} />
        </FormGroup>
        <Div display={'flex'} gap={3} justifyContent={'center'}>
          <Button {...fieldsHelper.buttons.submit} />
        </Div>
      </>
    )}
  </ChangePasswordFormHelper>
);
