import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCartApi } from '../api/useCartApi';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { InputGroup, InputGroupProps } from './InputGroup';

export interface GenericCouponFormProps
  extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {}

interface FormValues {
  code: formikString;
}

type ValidationSchema = {
  code: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  code: { formGroup: FormGroupProps; inputGroup: InputGroupProps; textInput: TextInputProps };
}

export const GenericCouponFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  ...otherProps
}: GenericCouponFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      code: getInitialString(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      code: yup.string().required(),
    }),
    []
  );

  const { applyCoupon } = useCartApi();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      applyCoupon({ couponCode: values.code }).then(() => {
        onSuccess && onSuccess();
      }),
    [applyCoupon, onSuccess]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('generic-coupon-form', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.cartGenericCouponForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.cartGenericCouponForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          code: {
            formGroup: { label: t('form.cartGenericCouponForm.code.label'), names: ['code'] },
            inputGroup: { names: ['code'] },
            textInput: { name: 'code', placeholder: t('form.cartGenericCouponForm.code.placeholder') },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.code.formGroup}>
              <InputGroup {...fieldsHelper.code.inputGroup}>
                <TextInput {...fieldsHelper.code.textInput} />
                <Button {...fieldsHelper.buttons.submit} />
              </InputGroup>
            </FormGroup>
          </>
        );
      }}
    </HelpedForm>
  );
};

export type GenericCouponFormVariants = '';

const GenericCouponFormController = (props: PropsWithCustomComponentWithoutChildren<GenericCouponFormProps>) => (
  <VariantsController<GenericCouponFormProps, GenericCouponFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: GenericCouponFormHelper,
      name: 'GenericCouponForm',
    }}
  />
);
export { GenericCouponFormController as GenericCouponForm };
