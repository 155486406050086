import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { useCartApi } from '../api/useCartApi';
import { useAppContext } from '../AppContext';
import { Button } from '../Button';
import { useCheckoutContextStrict } from '../CheckoutContext';
import { appConfig } from '../config';
import { ConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';
import { useNavigate } from '../router/useNavigate';
import { useContent } from '../useContent';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { formatHtml } from '../util';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { Checkbox, CheckboxProps } from './fields/RadioCheck';
import {
  FormButtonProps,
  formikBoolean,
  formikString,
  getInitialBoolean,
  getInitialString,
  isResetButtonDisabled,
  isSubmitButtonDisabled,
} from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';

export interface CartEmailFormProps extends BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector> {
  variant: 'whois' | 'checkout';
}

interface FormValues {
  checkbox: formikBoolean;
  email: formikString;
}

type ValidationSchema = {
  checkbox: yup.BooleanSchema;
  email: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  checkbox: { checkbox: CheckboxProps; formGroup: FormGroupProps };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const CartEmailFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  variant,
  ...otherProps
}: CartEmailFormProps) => {
  const { session } = useAppContext();

  const _initialValues = React.useMemo<FormValues>(
    () => ({
      checkbox: getInitialBoolean(),
      email: getInitialString(session?.cart?.email),
      ...initialValues,
    }),
    [initialValues, session?.cart?.email]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      checkbox: yup.boolean().required().oneOf([true]),
      email: yup.string().required(),
    }),
    []
  );

  const { setEmail } = useCartApi();

  const navigate = useNavigate();

  const checkoutContext = useCheckoutContextStrict();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      setEmail({ email: values.email }).then(() => {
        onSuccess && onSuccess();
        checkoutContext ? checkoutContext.deleteForcedStep() : navigate('/checkout');
      }),
    [checkoutContext, navigate, onSuccess, setEmail]
  );

  const ref = React.useRef<HTMLFormElement>(null);
  const consentsContent = useContent<ConsentsContentType>('CONSENTS_CONTENT');

  const { insideModal } = useInsideModalDetector();

  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('CartEmailForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.cartEmailForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label:
                variant === 'whois'
                  ? t('form.cartEmailForm.buttons.submit.whoIsLabel')
                  : t('form.cartEmailForm.buttons.submit.checkoutLabel'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          checkbox: {
            checkbox: {
              id: 'checkbox',
              label: formatHtml(consentsContent?.body.privacyGuestOrder),
              name: 'checkbox',
            },
            formGroup: { names: ['checkbox'] },
          },
          email: {
            formGroup: { label: t('form.cartEmailForm.email.label'), names: ['email'] },
            textInput: { name: 'email', placeholder: t('form.cartEmailForm.email.placeholder') },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.checkbox.formGroup}>
              <Checkbox {...fieldsHelper.checkbox.checkbox} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type CartEmailFormVariants = '';

const CartEmailFormController = (props: PropsWithCustomComponentWithoutChildren<CartEmailFormProps>) => (
  <VariantsController<CartEmailFormProps, CartEmailFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: CartEmailFormHelper,
      name: 'CartEmailForm',
    }}
  />
);
export { CartEmailFormController as CartEmailForm };
