import { ProductApiDto } from '@b2x/storefront-api-js-client/src/';
import React from 'react';

export interface UsePriceOptions {}

export interface UsePriceResult {
  bestPriceDiscountPercentage?: number;
  bestPriceDiscountPercentageDecimal?: number;
  bestPriceValue?: number;
  crossedOutPrice?: number;
  discountPercentage?: number;
  discountPercentageDecimal?: number;
  isPriceInRange: boolean;
  perUnit?: {
    crossedOutPrice?: number;
    measurement?: string;
    price?: number;
  };
  price: number;
  vat?: number;
}

export const usePrice = (product: ProductApiDto, selectedSkuId?: string, options?: UsePriceOptions): UsePriceResult => {
  const selectedSku = React.useMemo(
    () => product.skus?.find((sku) => sku.id === selectedSkuId),
    [product.skus, selectedSkuId]
  );

  const isPriceInRange = React.useMemo(
    () => selectedSkuId === undefined && product.priceRange?.from.value !== product.priceRange?.to.value,
    [product.priceRange?.from.value, product.priceRange?.to.value, selectedSkuId]
  );

  const crossedOutPrice = React.useMemo(
    () => (selectedSku ? selectedSku.price?.crossedOutValue : product.priceRange?.from.crossedOutValue),
    [product.priceRange?.from.crossedOutValue, selectedSku]
  );

  const discountPercentage = React.useMemo(
    () => (selectedSku ? selectedSku.price?.discountPercentage : product.priceRange?.from.discountPercentage),
    [product.priceRange?.from.discountPercentage, selectedSku]
  );

  const discountPercentageDecimal = React.useMemo(
    () => discountPercentage && Math.round(discountPercentage * 100),
    [discountPercentage]
  );

  const price = React.useMemo(
    () => (selectedSku ? selectedSku.price?.value : product.priceRange?.from.value) || 0,
    [product.priceRange?.from.value, selectedSku]
  );

  const perUnit = React.useMemo(
    () =>
      selectedSku
        ? {
            crossedOutPrice: selectedSku.price?.crossedOutValuePerUnit,
            measurement: selectedSku.price?.measurementUnit,
            price: selectedSku.price?.valuePerUnit,
          }
        : undefined,
    [selectedSku]
  );

  const vat = React.useMemo(
    () => (selectedSku ? selectedSku.price?.vat : product.priceRange?.from.vat) || 0,
    [product.priceRange?.from.vat, selectedSku]
  );

  const bestPriceValue = React.useMemo(() => selectedSku?.price?.bestPriceValue, [selectedSku?.price?.bestPriceValue]);
  const bestPriceDiscountPercentage = React.useMemo(
    () => selectedSku?.price?.bestPriceDiscountPercentage,
    [selectedSku?.price?.bestPriceDiscountPercentage]
  );

  const bestPriceDiscountPercentageDecimal = React.useMemo(
    () => bestPriceDiscountPercentage && Math.round(bestPriceDiscountPercentage * 100),
    [bestPriceDiscountPercentage]
  );

  return {
    bestPriceDiscountPercentage,
    bestPriceDiscountPercentageDecimal,
    bestPriceValue,
    crossedOutPrice,
    discountPercentage,
    discountPercentageDecimal,
    isPriceInRange,
    perUnit,
    price,
    vat,
  };
};

export const usePriceCallback = () => {
  const getPrice = React.useCallback((product: ProductApiDto, selectedSkuId?: string) => {
    const selectedSku = product.skus?.find((sku) => sku.id === selectedSkuId);

    const isPriceInRange =
      selectedSkuId === undefined && product.priceRange?.from.value !== product.priceRange?.to.value;

    const crossedOutPrice = selectedSku ? selectedSku.price?.crossedOutValue : product.priceRange?.from.crossedOutValue;

    const discountPercentage = selectedSku
      ? selectedSku.price?.discountPercentage
      : product.priceRange?.from.discountPercentage;

    const price = (selectedSku ? selectedSku.price?.value : product.priceRange?.from.value) || 0;

    const bestPriceValue = selectedSku?.price?.bestPriceValue;
    const bestPriceDiscountPercentage = selectedSku?.price?.bestPriceDiscountPercentage;

    return { bestPriceDiscountPercentage, bestPriceValue, crossedOutPrice, discountPercentage, isPriceInRange, price };
  }, []);
  return { getPrice };
};
