import { OrderApiDto } from '@b2x/storefront-api-js-client/src';
import { OrderPopulate } from '@b2x/storefront-api-js-client/src/orders';
import classnames from 'classnames';
import { FormikHelpers } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { b2x } from '..';
import { Button } from '../Button';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useInsideModalDetector } from '../useInsideModalDetector';
import { useModalCloser } from '../useModalCloser';
import { PropsWithCustomComponentWithoutChildren, VariantsController } from '../VariantsController';
import { TextInput, TextInputProps } from './fields/Input';
import { FormButtonProps, formikString, getInitialString, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroup, FormGroupProps } from './FormGroup';
import { BaseHelpedFormProps, HelpedForm } from './HelpedForm';
import { OrderGuestFormA } from './OrderGuestFormA';

export interface OrderGuestFormProps
  extends Omit<BaseHelpedFormProps<FormValues, FieldsHelper, ValidationSchemaSelector>, 'onSuccess'> {
  onSuccess?(foundOrder: OrderApiDto): void;
  orderPopulate?: OrderPopulate;
}

interface FormValues {
  code: formikString;
  email: formikString;
}

type ValidationSchema = {
  code: yup.StringSchema;
  email: yup.StringSchema;
};

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    cancel?: FormButtonProps;
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  code: { formGroup: FormGroupProps; textInput: TextInputProps };
  email: { formGroup: FormGroupProps; textInput: TextInputProps };
}

export const OrderGuestFormHelper = ({
  children,
  className,
  initialValues,
  onCancel,
  onSuccess,
  orderPopulate,
  ...otherProps
}: OrderGuestFormProps) => {
  const _initialValues = React.useMemo<FormValues>(
    () => ({
      code: getInitialString(),
      email: getInitialString(),
      ...initialValues,
    }),
    [initialValues]
  );

  const validationSchema = React.useMemo<ValidationSchema>(
    () => ({
      code: yup.string().required(),
      email: yup.string().required().email(),
    }),
    []
  );

  const { getOrderByCodeAndEmail } = b2x.useOrdersApi();

  const handleSubmit = React.useCallback(
    (values: FormValues, formikHelpers: FormikHelpers<FormValues>) =>
      getOrderByCodeAndEmail(values.code, values.email, {
        populate: orderPopulate ?? {
          billingAddress: true,
          paymentMethod: true,
          shippingAddress: true,
          warehouseOrders: { carrierTracking: true, orderedSkus: { sku: { product: true } } },
        },
      }).then((response) => {
        onSuccess && onSuccess(response.data);
      }),
    [getOrderByCodeAndEmail, onSuccess, orderPopulate]
  );

  const ref = React.useRef<HTMLFormElement>(null);

  const { insideModal } = useInsideModalDetector();
  const closeModal = useModalCloser();

  return (
    <HelpedForm<FormValues>
      className={classnames('OrderGuestForm', className)}
      initialValues={_initialValues}
      innerRef={ref}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      {...otherProps}
    >
      {({ formik }) => {
        const fieldsHelper: FieldsHelper = {
          buttons: {
            cancel: onCancel
              ? {
                  label: t('form.loginForm.buttons.cancel.label'),
                  onClick: onCancel,
                  type: 'button',
                  variant: appConfig.form?.buttons.cancel?.defaultVariant,
                }
              : undefined,
            reset: {
              disabled: isResetButtonDisabled(formik),
              label: t('form.orderGuestForm.buttons.reset.label'),
              type: 'reset',
              variant: appConfig.form?.buttons.cancel?.defaultVariant,
            },
            submit: {
              disabled: isSubmitButtonDisabled(formik),
              label: t('form.orderGuestForm.buttons.submit.label'),
              type: 'submit',
              variant: appConfig.form?.buttons.submit?.defaultVariant,
            },
          },
          code: {
            formGroup: { label: t('form.orderGuestForm.orderCode.label'), names: ['code'] },
            textInput: { name: 'code', placeholder: t('form.orderGuestForm.orderCode.placeholder') },
          },
          email: {
            formGroup: { label: t('form.orderGuestForm.email.label'), names: ['email'] },
            textInput: { name: 'email', placeholder: t('form.orderGuestForm.email.placeholder'), type: 'email' },
          },
        };
        return children ? (
          children({ closeModal, fieldsHelper, formik, insideModal })
        ) : (
          <>
            <FormGroup {...fieldsHelper.code.formGroup}>
              <TextInput {...fieldsHelper.code.textInput} />
            </FormGroup>
            <FormGroup {...fieldsHelper.email.formGroup}>
              <TextInput {...fieldsHelper.email.textInput} />
            </FormGroup>
            <Button {...fieldsHelper.buttons.submit} />
          </>
        );
      }}
    </HelpedForm>
  );
};

export type OrderGuestFormVariants = 'A';

const OrderGuestFormController = (props: PropsWithCustomComponentWithoutChildren<OrderGuestFormProps>) => (
  <VariantsController<OrderGuestFormProps, OrderGuestFormVariants>
    {...props}
    variantsControllerConfig={{
      defaultComponent: OrderGuestFormHelper,
      name: 'OrderGuestForm',
      componentVariants: {
        A: OrderGuestFormA,
      },
    }}
  />
);
export { OrderGuestFormController as OrderGuestForm };
