import { RouteApiDto, RouteApiType } from '@b2x/storefront-api-js-client/src';
import React from 'react';

import { useMenusApi } from '../api/useMenusApi';
import { useAppContext, useAppStaticContext } from '../AppContext';
import { BcomAutologin } from '../BcomAutologin';
import { isTypeFromValuesUnion, useStable } from '../util';
import { Location, Route, Routes, useLocation, useParams } from './router';

export interface MainRouterProps {
  // Uso codeMapping quando voglio mappare un componente a una singola pagina, scelta per codice
  codeMappings?: Record<
    string,
    JSX.Element | { component: JSX.Element; dynamicSegment?: boolean; separateRouter?: boolean }
  >;
  error404Page: JSX.Element;
  // Uso fixedMapping quando voglio mappare un componente a un percorso prestabilito e non traducibile
  fixedMappings?: Partial<
    Record<FixedPath, JSX.Element | { component: JSX.Element; dynamicSegment?: boolean; separateRouter?: boolean }>
  >;
  scrollTopOnPageChange?: boolean;
  // Uso templateMappings quando voglio mappare un componente a un'insieme di pagine che condivisono lo stesso template, utilizzando come chiave il codice del TIPO DI CONTENUTO anziché quello della DIRECTORY.
  templateMappings?: Record<string, JSX.Element>;
  // Uso typeMappings quando voglio mappare un componente a un tipo di pagina, come quella prodotto e quella catalogo
  typeMappings?: Partial<Record<RouteApiType, JSX.Element>>;
}

const fixedPaths = [
  'login',
  'register',
  'sitemap', // FIXME
  'template',
  'whois',
  'cart',
  'checkout',
  'search',
  'account',
  'order-ok',
  'order-ko',
  'newsletter',
  'forgot-password',
  'change-password',
  'unsubscribe-newsletter',
  'test',
  'error',
  'double-opt-in-ok',
  'double-opt-in-ko',
  'update-customer-required-fields',
] as const;
export type FixedPath = (typeof fixedPaths)[number];
export const isFixedPath = (path: string): path is FixedPath => isTypeFromValuesUnion<FixedPath>(path, fixedPaths);

const taggedPageTags = ['page-home', 'page-account-orders'] as const;
export type TaggedPageTag = (typeof taggedPageTags)[number];
export const isTaggedPageTag = (tag: string): tag is TaggedPageTag =>
  isTypeFromValuesUnion<TaggedPageTag>(tag, taggedPageTags);

export const MainRouter = ({
  codeMappings,
  error404Page,
  fixedMappings,
  templateMappings,
  typeMappings,
}: MainRouterProps) => {
  codeMappings = useStable(codeMappings);
  error404Page = useStable(error404Page);
  fixedMappings = useStable(fixedMappings);
  templateMappings = useStable(templateMappings);
  typeMappings = useStable(typeMappings);

  const [router, setRouter] = React.useState<RouteApiDto>();
  const [sitemapRoutes, setSitemapRoutes] = React.useState<Array<React.ReactElement>>([]);
  const [fixedRoutes, setFixedRoutes] = React.useState<Array<React.ReactElement>>([]);

  const { getRouter } = useMenusApi();

  // Chiedo il router alle API
  React.useEffect(() => {
    getRouter().then((response) => {
      setRouter(response.data);
    });
  }, [getRouter]);

  // Funzione che analizza il router e crea le varie strutture
  const scan = React.useCallback(
    (
      route: RouteApiDto,
      _routesMapByCode: Record<string, RouteApiDto>,
      _routesMapByTaggedPageTag: Partial<Record<TaggedPageTag, RouteApiDto>>
    ): Array<React.ReactElement> => {
      let element:
        | {
            component: JSX.Element | undefined;
            dynamicSegment: boolean;
            separateRouter: boolean;
            type: PageMappingType;
          }
        | undefined;
      if (codeMappings && codeMappings.hasOwnProperty(route.code)) {
        if (codeMappings[route.code].hasOwnProperty('component')) {
          element = {
            component: (
              codeMappings[route.code] as { component: JSX.Element; dynamicSegment?: boolean; separateRouter?: boolean }
            ).component,
            dynamicSegment:
              (
                codeMappings[route.code] as {
                  component: JSX.Element;
                  dynamicSegment?: boolean;
                  separateRouter?: boolean;
                }
              ).dynamicSegment ?? false,
            separateRouter:
              (
                codeMappings[route.code] as {
                  component: JSX.Element;
                  dynamicSegment?: boolean;
                  separateRouter?: boolean;
                }
              ).separateRouter ?? false,
            type: 'code',
          };
        } else {
          element = {
            component: codeMappings[route.code] as JSX.Element,
            dynamicSegment: false,
            separateRouter: false,
            type: 'code',
          };
        }
      } else if (templateMappings && route.template && templateMappings.hasOwnProperty(route.template)) {
        element = {
          component: templateMappings[route.template],
          dynamicSegment: false,
          separateRouter: false,
          type: 'template',
        };
      } else if (typeMappings && typeMappings[route.type]) {
        element = {
          component: typeMappings[route.type],
          dynamicSegment: false,
          separateRouter: false,
          type:
            route.type === 'PRODUCT'
              ? 'product'
              : route.type === 'CATALOG'
              ? 'catalog'
              : route.type === 'LISTING'
              ? 'listing'
              : route.type === 'CONTENT'
              ? 'content'
              : 'route',
        };
      } else {
        element = undefined;
      }

      const item =
        element &&
        (route.indexRoute ? (
          <Route
            element={
              <RouteInfoUpdater
                dynamicSegment={false}
                pathname={route.fullPath}
                separateRouter={element.separateRouter}
                type={element.type}
              >
                {element.component}
              </RouteInfoUpdater>
            }
            index
            key={route.id}
          />
        ) : (
          <Route
            element={
              <RouteInfoUpdater
                dynamicSegment={false}
                pathname={route.fullPath}
                separateRouter={element.separateRouter}
                type={element.type}
              >
                {element.component}
              </RouteInfoUpdater>
            }
            key={route.id}
            path={`${route.fullPath}${
              route.type === 'PRODUCT' || route.type === 'CONTENT' ? '/:path' : element.separateRouter ? '/*' : ''
            }`}
          >
            {route.nestedRoutes && route.children ? (
              <>
                {route.children.map((child) => (
                  <React.Fragment key={child.id}>
                    {scan(child, _routesMapByCode, _routesMapByTaggedPageTag)}
                  </React.Fragment>
                ))}
              </>
            ) : undefined}
          </Route>
        ));

      const dynamicSegmentItem = element && element.dynamicSegment && (
        <Route
          element={
            <RouteInfoUpdater
              dynamicSegment
              pathname={route.fullPath}
              separateRouter={element.separateRouter}
              type={element.type}
            >
              {element.component}
            </RouteInfoUpdater>
          }
          key={`${route.id}-dynamic-segment`}
          path={`${route.fullPath}/:path`}
        />
      );

      const unnestedChildren =
        route.children && !route.nestedRoutes
          ? route.children.map((child) => (
              <React.Fragment key={child.id}>{scan(child, _routesMapByCode, _routesMapByTaggedPageTag)}</React.Fragment>
            ))
          : [];

      _routesMapByCode[route.code] = route;

      route.tags?.forEach((tag) => {
        if (isTaggedPageTag(tag)) {
          if (_routesMapByTaggedPageTag[tag] !== undefined) {
            console.error(`Tag '${tag}' assegnato a 2 route: ${_routesMapByTaggedPageTag[tag]?.code}, ${route.code}.`);
          } else {
            _routesMapByTaggedPageTag[tag] = route;
          }
        }
      });

      return [...(item && dynamicSegmentItem ? [item, dynamicSegmentItem] : item ? [item] : []), ...unnestedChildren];
    },
    [codeMappings, templateMappings, typeMappings]
  );

  const { dynamicSegment404, routesMapByCode } = useAppContext();
  const { setDynamicSegment404, setRoutesMapByCode, setRoutesMapByTaggedPageTag } = useAppStaticContext();

  //Ottenuto il router, lo analizzo e costruisco le varie strutture.
  React.useEffect(() => {
    if (router) {
      const _routesMapByCode: Record<string, RouteApiDto> = {};
      const _routesMapByTaggedPageTag: Partial<Record<TaggedPageTag, RouteApiDto>> = {};
      const _sitemapRoutes = scan(router, _routesMapByCode, _routesMapByTaggedPageTag);
      const _fixedRoutes = fixedMappings
        ? Object.entries(fixedMappings).map(([path, mapping]) => {
            let element:
              | { component: JSX.Element | undefined; dynamicSegment: boolean; separateRouter: boolean }
              | undefined;
            if (mapping.hasOwnProperty('component')) {
              element = {
                component: (mapping as { component: JSX.Element; dynamicSegment?: boolean; separateRouter?: boolean })
                  .component,
                dynamicSegment:
                  (mapping as { component: JSX.Element; dynamicSegment?: boolean; separateRouter?: boolean })
                    .dynamicSegment ?? false,
                separateRouter:
                  (mapping as { component: JSX.Element; dynamicSegment?: boolean; separateRouter?: boolean })
                    .separateRouter ?? false,
              };
            } else {
              element = { component: mapping as JSX.Element, dynamicSegment: false, separateRouter: false };
            }
            return (
              <Route
                element={
                  <RouteInfoUpdater
                    dynamicSegment={element.dynamicSegment}
                    pathname={`/${path}`}
                    separateRouter={element.separateRouter}
                    type="fixed"
                  >
                    {element.component}
                  </RouteInfoUpdater>
                }
                key={path}
                path={`${path}${element.dynamicSegment ? '/:path' : element.separateRouter ? '/*' : ''}`}
              />
            );
          })
        : [];
      setSitemapRoutes(_sitemapRoutes);
      setFixedRoutes(_fixedRoutes);
      setRoutesMapByCode(_routesMapByCode);
      setRoutesMapByTaggedPageTag(_routesMapByTaggedPageTag);

      taggedPageTags.forEach((tag) => {
        if (_routesMapByTaggedPageTag[tag] === undefined) {
          console.info(`Tag '${tag}' non assegnato.`);
        }
      });
    }
  }, [scan, router, fixedMappings, setRoutesMapByCode, setRoutesMapByTaggedPageTag]);

  const _Routes = React.useMemo(
    () => (
      <Routes>
        {dynamicSegment404 ? (
          <Route element={error404Page} path="*" />
        ) : (
          <>
            {sitemapRoutes}
            {fixedRoutes}
            <Route element={<BcomAutologin />} path="/bcom-autologin" />
            <Route element={error404Page} path="*" />
          </>
        )}
      </Routes>
    ),
    [dynamicSegment404, error404Page, fixedRoutes, sitemapRoutes]
  );

  const location = useLocation();

  React.useEffect(() => {
    setDynamicSegment404(false);
  }, [setDynamicSegment404, location.pathname]);

  return <>{router && routesMapByCode && _Routes}</>;
};

// interface PageMappingProps {
//   children: React.ReactNode;
//   type: PageMappingType;
// }

// const PageMapping = ({ children, type }: PageMappingProps) => {
//   const { setPageMappingType } = useAppContext();
//   React.useEffect(() => {
//     setPageMappingType(type);
//   }, [setPageMappingType, type]);
//   return <>{children}</>;
// };

export type PageMappingType = 'code' | 'template' | 'product' | 'catalog' | 'route' | 'fixed' | 'listing' | 'content';
export interface RouteInfo {
  dynamicSegment: boolean;
  location: Location;
  pathParam?: string;
  pathname: string;
  separateRouter: boolean;
  type: PageMappingType;
}

interface RouteInfoUpdaterProps {
  children: React.ReactNode;
  dynamicSegment: boolean;
  pathname: string;
  separateRouter: boolean;
  type: PageMappingType;
}

const RouteInfoUpdater = ({ children, dynamicSegment, pathname, separateRouter, type }: RouteInfoUpdaterProps) => {
  const { setRouteInfo } = useAppStaticContext();
  const location = useLocation();
  const { path } = useParams<'path'>();
  React.useEffect(() => {
    setRouteInfo({
      dynamicSegment: dynamicSegment,
      location: location,
      pathParam: path,
      pathname: pathname,
      separateRouter: separateRouter,
      type: type,
    });
  }, [dynamicSegment, location, path, pathname, separateRouter, setRouteInfo, type]);
  return <>{children}</>;
};
