import './BannerSlider.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import { SwiperSlide } from 'swiper/react';

import { SliderContentSection } from '../contentTypes';
import { Slider } from '../slider/Slider';

export interface BannerSliderProps extends SliderContentSection {}

export const BannerSlider = ({ fullHeight, sliderList }: BannerSliderProps) => {
  return (
    <div className="banner-slider">
      <Slider
        autoplay={{ delay: 4000 }}
        className={classnames({ 'slider-full-height': fullHeight })}
        navigation
        pagination
        slidesPerView={1}
        spaceBetween={0}
        watchSlidesProgress
      >
        {sliderList &&
          sliderList.map((slide, index) => (
            <SwiperSlide key={slide.contentSectionId}>
              {(slideData) => (
                <>
                  <div className="background position-relative h-100 text-center">
                    {slide.video?.src && slide.video.src.length > 1 ? (
                      <b2x.DeprecatedVideoAsBackgroundFromContent {...slide.video} />
                    ) : (
                      <>
                        {/* eslint-disable-next-line react/jsx-no-bind */}
                        <b2x.ImageFromContent {...slide.img} fluid />
                      </>
                    )}
                    {slide.copy?.cta && <b2x.DeprecatedCta className="stretched-link" cta={slide.copy.cta} />}
                  </div>
                  <div
                    className={classnames(
                      'message d-md-flex h-100 align-items-center',
                      // padding
                      'px-3 py-4',
                      // alignment
                      { 'text-start': slide.copy?.alignment === 'left' },
                      { 'text-center': slide.copy?.alignment === 'center' },
                      { 'text-end': slide.copy?.alignment === 'right' }
                    )}
                  >
                    <div className="container-xxl">
                      <div className="col-lg-5 col-md-6 ps-md-4 ps-lg-4 ps-xl-3">
                        <div className={classnames(`text-${slide.copy?.color}`)}>
                          {slide.copy?.title && (
                            <h3 className="h1 fw-bold title mb-3">{b2x.formatHtml(slide.copy.title)}</h3>
                          )}
                          {slide.copy?.content && (
                            <div className="description h4 fw-normal mb-3">{b2x.formatHtml(slide.copy.content)}</div>
                          )}
                          {slide.copy?.disclaimer && (
                            <div className="extra-small disclaimer mb-3"> {b2x.formatHtml(slide.copy.disclaimer)}</div>
                          )}
                          {slide.copy?.cta && slide.copy.cta.label && (
                            <div>
                              <b2x.DeprecatedCta
                                className={classnames(
                                  'btn',
                                  `btn-${slide.copy.cta.variant}`,
                                  'd-none d-md-inline-block '
                                )}
                                cta={slide.copy.cta}
                              >
                                {b2x.formatHtml(slide.copy.cta.label)}
                              </b2x.DeprecatedCta>
                              <b2x.DeprecatedCta
                                className={classnames('btn btn-primary d-md-none')}
                                cta={slide.copy.cta}
                              >
                                {b2x.formatHtml(slide.copy.cta.label)}
                              </b2x.DeprecatedCta>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </SwiperSlide>
          ))}
      </Slider>
    </div>
  );
};
