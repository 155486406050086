import classNames from 'classnames';

import { renderUI } from './util';

export interface BadgeProps {
  backgroundColor: string;
  children?: React.ReactNode;
  className?: string;
  pill?: boolean;
  textColor: string;
}

export const Badge = ({ backgroundColor, children, className, pill, textColor }: BadgeProps) => {
  return renderUI({
    bs5: (
      <span
        className={classNames(
          'badge',
          className,
          { [`bg-${backgroundColor}`]: backgroundColor },
          { [`text-${textColor}`]: textColor },
          { 'rounded-pill': pill }
        )}
      >
        {children}
      </span>
    ),
  });
};
