import './RowLanding.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { RowContentSection } from './contentTypes';

export interface RowLandingProps extends RowContentSection {
  className?: string;
}

export const RowLanding = ({ className, sectionContentRowList }: RowLandingProps) => {
  return (
    <div className={classnames('row-landing', className)}>
      {sectionContentRowList?.map((sectionRow) => (
        <b2x.Row className="justify-content-center mt-3 mb-0 mb-lg-5" key={sectionRow.contentSectionId}>
          {sectionRow.contentColList?.map((listCol) => {
            return (
              <>
                <b2x.Col
                  className={classnames(
                    listCol.imgOrder === 'before' ? 'order-lg-1' : 'order-lg-2',
                    `col-12 col-lg-${listCol.imgSize}`
                  )}
                >
                  {listCol.mobileCopyPosition === 'top' && (
                    <>
                      <div className="d-block d-lg-none text-center text-blue ">{b2x.formatHtml(listCol.preTitle)}</div>
                      <div className="d-block d-lg-none h2 text-center text-blue ">{b2x.formatHtml(listCol.title)}</div>
                    </>
                  )}
                  {listCol.cta && listCol.img ? (
                    <b2x.DeprecatedCta cta={listCol.cta}>
                      <b2x.ImageFromContent className="img-fluid my-3 my-lg-0" {...listCol.img} fluid />
                    </b2x.DeprecatedCta>
                  ) : (
                    listCol.img && <b2x.ImageFromContent className="img-fluid my-3 my-lg-0" {...listCol.img} fluid />
                  )}
                  {listCol.mobileCopyPosition === 'bottom' && (
                    <>
                      <div className="d-block d-lg-none text-center text-blue ">{b2x.formatHtml(listCol.preTitle)}</div>
                      <div
                        className={classnames(`text-${listCol.mobileCopyAlignment}`, 'd-block d-lg-none h2 text-blue')}
                      >
                        {b2x.formatHtml(listCol.title)}
                      </div>
                    </>
                  )}
                  {listCol.content && (
                    <div className="d-block d-lg-none text-left mb-4 mb-lg-0">{b2x.formatHtml(listCol.content)}</div>
                  )}

                  {listCol.cta && (
                    <b2x.Div className="d-grid d-block d-lg-none">
                      <b2x.CtaFromContent ctaProps={{ button: { className: 'mt-lg-5 mt-4 px-5' } }} {...listCol.cta} />
                    </b2x.Div>
                  )}
                </b2x.Col>
                <b2x.Col
                  className={classnames(listCol.imgOrder === 'before' ? 'order-lg-2' : 'order-lg-1', 'cta-landing')}
                >
                  <div className={classnames(listCol.imgOrder === 'before' ? 'p-lg-4' : 'mx-lg-3')}>
                    <div className="d-none d-lg-block text-blue">{b2x.formatHtml(listCol.preTitle)}</div>
                    <div className="d-none d-lg-block h2 text-blue">{b2x.formatHtml(listCol.title)}</div>
                    <div className="d-none d-lg-block mt-3">{b2x.formatHtml(listCol.content)}</div>
                    {listCol.note && (
                      <div className="d-none d-lg-block small fst-italic mt-5">{b2x.formatHtml(listCol.note)}</div>
                    )}
                    {listCol.cta && (
                      <b2x.Div className="d-grid d-none d-lg-block">
                        <b2x.CtaFromContent
                          ctaProps={{ button: { className: 'mt-lg-5 mt-4 px-5' } }}
                          {...listCol.cta}
                        />
                      </b2x.Div>
                    )}
                  </div>
                </b2x.Col>
              </>
            );
          })}
        </b2x.Row>
      ))}
    </div>
  );
};
