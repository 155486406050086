import './ProductStickyFooterA.scss';

import classnames from 'classnames';
import React from 'react';

import { AddToCartFormButton } from './AddToCartFormButton';
import { Button } from './Button';
import { appConfig } from './config';
import { Container } from './Container';
import { useFloatingActionButtonContext } from './FloatingActionButtonContext';
import { Div, H6, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { Image } from './Image';
import { PriceBlock } from './PriceBlock';
import { ProductStickyFooterProps } from './ProductStickyFooter';

export const ProductStickyFooterA = ({
  fieldsHelper,
  formik,
  isVisible,
  priceHelper,
  product,
  scrollToElement,
  selectedProductAttributes,
  selectedProductImage,
  selectedSku,
}: ProductStickyFooterProps) => {
  const handleSelectSizeButtonClick = React.useCallback(() => {
    scrollToElement && scrollToElement();
    formik.submitForm();
  }, [formik, scrollToElement]);

  const stickyFooterRef = React.useRef<HTMLDivElement>(null);
  const { addOffset, removeOffset } = useFloatingActionButtonContext();

  React.useEffect(() => {
    if (isVisible && stickyFooterRef.current) {
      addOffset('productStickyFooter', stickyFooterRef.current.offsetHeight);
    } else {
      removeOffset('productStickyFooter');
    }
    return () => {
      removeOffset('productStickyFooter');
    };
  }, [addOffset, isVisible, removeOffset]);

  return (
    <div ref={stickyFooterRef}>
      <Div
        className={classnames('product-sticky-footer', {
          show: isVisible,
        })}
        style={{ position: 'fixed', width: '100%', zIndex: 1000 }}
        uiClassName={{ bs5: 'shadow-lg' }}
      >
        <Div className="product-sticky-footer-mobile" display={{ lg: 'none' }}>
          <Div
            alignItems={'center'}
            display={'flex'}
            justifyContent={'center'}
            textAlign={'center'}
            uiClassName={{ bs5: 'extra-small' }}
          >
            {selectedProductAttributes?.map((attribute) => (
              <Div key={attribute.typeCode} paddingX={3} paddingY={2} uiClassName={{ bs5: 'border-end' }}>
                {attribute.value}
              </Div>
            ))}
            <Div paddingX={3} paddingY={2} uiClassName={{ bs5: 'extra-small' }}>
              {!selectedSku ? (
                <Button label={t('product.selectSize')} onClick={scrollToElement} variant="blank" />
              ) : (
                selectedSku.name
              )}
            </Div>
          </Div>
          <Div
            alignItems={'center'}
            display={'flex'}
            justifyContent={'spaceBetween'}
            padding={2}
            uiClassName={{ bs5: 'border-top' }}
          >
            <Div uiClassName={{ bs5: 'flex-grow-1 lh-1 small' }}>
              <Div className="product-name" style={{ maxWidth: 150 }}>
                {product.name}
              </Div>
            </Div>
            <Div dynamicMargin={{ end: 2 }} uiClassName={{ bs5: 'flex-grow-0 small' }}>
              <PriceBlock gap={2} priceHelper={priceHelper} />
            </Div>
            <Div uiClassName={{ bs5: 'flex-grow-0' }}>
              <AddToCartFormButton
                availabilityEmailNotificationButton={{
                  label: t('form.addToCartForm.buttons.availabilityEmailNotification.addShortLabel'),
                }}
                fieldsHelper={fieldsHelper}
                selectedSku={selectedSku}
                submitButton={{ iconStart: { name: 'cart', size: 25 }, label: undefined, onClick: formik.submitForm }}
              />
            </Div>
          </Div>
        </Div>
        <Div display={{ lg: 'block', xs: 'none' }}>
          <Container>
            <Div paddingY={2}>
              <Div alignContent={'center'} display={'flex'} justifyContent={'spaceBetween'}>
                <Div alignItems={'center'} display={'flex'}>
                  <Div marginEnd={3} uiClassName={{ bs5: 'image-wrapper rounded border border-gray-200' }}>
                    <Image aspectRatio={appConfig.productImageAspectRatio} fluid src={selectedProductImage?.src} />
                  </Div>
                  <H6 className="product-name" fontWeight={500} margin={{ xs: 0 }} style={{ maxWidth: '225px' }}>
                    {product.name}
                  </H6>
                </Div>
                <Div style={{ marginBlock: 'auto' }}>
                  {selectedProductAttributes &&
                    selectedProductAttributes.map((attribute) => (
                      <Div key={attribute.typeCode}>
                        <Span uiClassName={{ bs5: 'me-2' }}>{attribute.title}:</Span>
                        <b>{attribute.value}</b>
                      </Div>
                    ))}
                </Div>
                <Div style={{ marginBlock: 'auto' }}>
                  <Span uiClassName={{ bs5: 'me-2' }}>
                    {selectedSku ? (
                      `${t('product.size', { count: 1 })}:`
                    ) : (
                      <Button label={t('product.selectSize')} onClick={handleSelectSizeButtonClick} variant="blank" />
                    )}
                  </Span>
                  <b>{selectedSku?.name}</b>
                </Div>
                <Div style={{ marginBlock: 'auto' }}>
                  <PriceBlock gap={2} priceHelper={priceHelper} />
                </Div>
                <Div style={{ marginBlock: 'auto' }} uiClassName={{ bs5: 'text-uppercase' }}>
                  <AddToCartFormButton
                    fieldsHelper={fieldsHelper}
                    selectedSku={selectedSku}
                    submitButton={{ onClick: formik.submitForm }}
                  />
                </Div>
              </Div>
            </Div>
          </Container>
        </Div>
      </Div>
    </div>
  );
};
