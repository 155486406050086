import {
  AddressApiDto,
  AddToWishlistRequestApiDto,
  ChangePasswordByTokenRequestApiDto,
  CouponApiDto,
  CreateUpdateAddressRequestApiDto,
  CustomerApiDto,
  CustomerRequestApiDto,
  ForgotPasswordRequestApiDto,
  OrderApiDto,
  PasswordChangeRequestApiDto,
  RegisterCustomerRequestApiDto,
  RemoveFromWishlistRequestApiDto,
  SubscribeNewsletterRequestApiDto,
  WishlistApiDto,
} from './dto';
import { GetOrderOptions, OrderPopulate } from './orders';
import { ProductPopulate, SkuPopulate } from './products';
import { apiRequest, BaseOptions, getBaseOptions, Populate } from './utils/util';

export interface CustomerPopulate {
  additionalPropertiesExt?: boolean;
  addresses?: boolean;
  children?: Populate<ChildPopulate>;
  defaultBillingAddress?: boolean;
  defaultShippingAddress?: boolean;
  documents?: boolean;
  fidelityCards?: boolean;
  qualifiers?: boolean;
}

export interface ChildPopulate {
  additionalProperties?: boolean;
}

export interface WishlistPopulate {
  products?: Populate<ProductPopulate>;
  skus?: Populate<SkuPopulate>;
}

const rootPath = '/v2/customer';

export interface GetCustomerOptions extends BaseOptions {
  populate: Populate<CustomerPopulate>;
}

export const get = async (options?: GetCustomerOptions) =>
  apiRequest<CustomerApiDto, void, CustomerPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${rootPath}`,
  });

export const register = async (data: RegisterCustomerRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, RegisterCustomerRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}`,
  });

export interface UpdateCustomerOptions {
  populate: Populate<CustomerPopulate>;
}

export const update = async (data: CustomerRequestApiDto, options?: GetCustomerOptions) =>
  apiRequest<void, CustomerRequestApiDto, CustomerPopulate>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    populate: options?.populate,
    url: `${rootPath}`,
  });

const addressesPath = `${rootPath}/addresses`;

export const getaddresses = async (options?: BaseOptions) =>
  apiRequest<Array<AddressApiDto>, void, void>({
    ...getBaseOptions(options),
    url: `${addressesPath}`,
  });

export const createAddress = async (data: CreateUpdateAddressRequestApiDto, options?: BaseOptions) =>
  apiRequest<number, CreateUpdateAddressRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${addressesPath}`,
  });

export const updateAddress = async (data: CreateUpdateAddressRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, CreateUpdateAddressRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${addressesPath}`,
  });

export const deleteAddress = async (id: string, options?: BaseOptions) =>
  apiRequest<void, void, void>({
    ...getBaseOptions(options),
    method: 'DELETE',
    url: `${addressesPath}/${id}`,
  });

const wishlistPath = `${rootPath}/wishlist`;

export interface GetWishlistOptions extends BaseOptions {
  populate?: Populate<WishlistPopulate>;
}

export const getWishlist = async (options?: GetWishlistOptions) =>
  apiRequest<WishlistApiDto, void, WishlistPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${wishlistPath}`,
  });

export const addProductToWishlist = async (data: AddToWishlistRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, AddToWishlistRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${wishlistPath}/product`,
  });

export const removeProductFromWishlist = async (data: RemoveFromWishlistRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, RemoveFromWishlistRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'DELETE',
    url: `${wishlistPath}/product`,
  });

export const subscribeToNewsletter = async (data: SubscribeNewsletterRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, SubscribeNewsletterRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${rootPath}/newsletter`,
  });

const ordersPath = `${rootPath}/orders`;

export const getOrders = async (options?: GetOrderOptions) =>
  apiRequest<Array<OrderApiDto>, void, OrderPopulate>({
    ...getBaseOptions(options),
    populate: options?.populate,
    url: `${ordersPath}`,
  });

const couponsPath = `${rootPath}/coupons`;

export const getCoupons = async (options?: BaseOptions) =>
  apiRequest<Array<CouponApiDto>, void, void>({
    ...getBaseOptions(options),
    url: `${couponsPath}`,
  });

const passwordPath = `${rootPath}/password`;

export const forgotPassword = async (data: ForgotPasswordRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, ForgotPasswordRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${passwordPath}/forgot`,
  });

export const changePasswordByToken = async (data: ChangePasswordByTokenRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, ChangePasswordByTokenRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'POST',
    url: `${passwordPath}/change-by-token`,
  });

export const changePassword = async (data: PasswordChangeRequestApiDto, options?: BaseOptions) =>
  apiRequest<void, PasswordChangeRequestApiDto, void>({
    ...getBaseOptions(options),
    data: data,
    method: 'PUT',
    url: `${passwordPath}/change`,
  });

const newsletterPath = `${rootPath}/newsletter`;

export const unsubscribeNewsletterByToken = async (token: string, options?: BaseOptions) =>
  apiRequest<void, void, void>({
    ...getBaseOptions(options),
    method: 'POST',
    params: {
      token: token,
    },
    url: `${newsletterPath}/unsubscribe`,
  });
