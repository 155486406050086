import classnames from 'classnames';
import React from 'react';

import { Div, Span } from './HTMLElement';
import { t } from './i18n/i18n';
import { ResponsiveAlignItems, ResponsiveFlexDirection, ResponsiveGap } from './interfaces';
import { PriceBlockA } from './PriceBlockA';
import { UsePriceResult } from './usePrice';
import { formatCurrency, formatFloatPercentage } from './util';
import { PropsWithCustomComponent, VariantsController } from './VariantsController';

export interface PriceBlockProps {
  alignItems?: ResponsiveAlignItems;
  children?: React.ReactNode;
  classNames?: {
    crossedOutPrice?: string;
    discountPercentage?: string;
    price?: string;
    specialPrice?: string;
  };
  direction?: ResponsiveFlexDirection;
  gap?: ResponsiveGap;
  hiddenDiscountLabel?: boolean;
  hiddenDiscountPercentage?: boolean;
  priceHelper: UsePriceResult;
}

const PriceBlock = ({
  alignItems,
  children,
  classNames,
  direction,
  gap,
  hiddenDiscountLabel,
  hiddenDiscountPercentage,
  priceHelper,
}: PriceBlockProps) => {
  return (
    <Div className="price-block">
      <Div alignItems={alignItems} display={'flex'} flexDirection={direction} gap={gap}>
        {children ? (
          children
        ) : (
          <React.Fragment>
            {priceHelper.crossedOutPrice && (
              <Span className={classnames('crossed-out-price', classNames?.crossedOutPrice)}>
                <del>
                  {t(priceHelper.isPriceInRange ? 'misc.fromPrice' : 'misc.price', {
                    value: formatCurrency(priceHelper.crossedOutPrice),
                  })}
                </del>
              </Span>
            )}
            {!hiddenDiscountPercentage && priceHelper.discountPercentage && (
              <Span className={classnames('discount-percentage', classNames?.discountPercentage)}>
                {`-${formatFloatPercentage(priceHelper.discountPercentage)}%`}
              </Span>
            )}
            <Span
              className={classnames(
                'price',
                { 'special-price': priceHelper.crossedOutPrice },
                priceHelper.crossedOutPrice ? classNames?.specialPrice : classNames?.price
              )}
            >
              {t(priceHelper.isPriceInRange ? 'misc.fromPrice' : 'misc.price', {
                value: formatCurrency(priceHelper.price),
              })}
            </Span>
          </React.Fragment>
        )}
      </Div>
    </Div>
  );
};

export type PriceBlockVariants = 'A';

const PriceBlockController = (props: PropsWithCustomComponent<PriceBlockProps>) => (
  <VariantsController<PriceBlockProps, PriceBlockVariants>
    {...props}
    variantsControllerConfig={{
      componentVariants: { A: PriceBlockA },
      defaultComponent: PriceBlock,
      name: 'PriceBlock',
    }}
  />
);
export { PriceBlockController as PriceBlock };
