import React from 'react';
// eslint-disable-next-line no-restricted-imports
import { NavigateOptions as _NavigateOptions, To, useNavigate as _useNavigate } from 'react-router-dom-6';

import { useAppStaticContext } from '../AppContext';
import { scrollTop as _scrollTop } from '../util';

interface NavigateOptions extends _NavigateOptions {
  scrollTop?: boolean;
  silent?: boolean;
}

export const useNavigate = () => {
  const navigate = _useNavigate();

  /*
    Rendo stabile la useNavigate().
    In caso di problemi togliere questa cosa ma ricordasi di togliere useNavigate() in useApiRequest()
    (redirect in home se le API mi rispondono con api.error.usernotlogged)
  */
  const navigateRef = React.useRef(navigate);
  navigateRef.current = navigate;
  const navigateStable = React.useCallback((to: To, options?: NavigateOptions) => navigateRef.current(to, options), []);

  const { setPageReady } = useAppStaticContext();

  const navigateFunction = React.useCallback(
    (to: To, options?: NavigateOptions) => {
      const { scrollTop = true, silent } = options ?? {};
      navigateStable(to, options);
      !silent && setPageReady(false);
      scrollTop && _scrollTop();
    },
    [navigateStable, setPageReady]
  );

  return navigateFunction;
};
