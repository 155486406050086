import { Resource } from '../i18n';

export const it: Resource = {
  form: {
    addToCartForm: {
      skus: {
        label: 'Taglia',
        placeholder: 'Seleziona una taglia',
      },
    },
    personalDataForm: {
      phoneNumbers: { label: 'Telefono' },
    },
  },
  misc: {
    backToHomeBlog: 'Torna alla home Magazine AmioAgio',
    doubleOptionAccess: 'Accedi o registrati subito',
    moreArticles: 'Altri articoli',
    noItemFound: 'Nessun elemento trovato',
    storeName: 'Hartmann',
  },
  product: {
    promo: 'Promo',
    size: 'Misura ',
  },
  welcome: 'Benvenuto su store-hartmann!',
};
