import React from 'react';

import { ModalProps } from './Modal';
import { Portal } from './Portal';
import { useModals } from './useModals';

export const useStaticModal = <T extends ModalProps>(
  Modal: React.FunctionComponent<T>,
  type: 'modal' | 'offcanvas',
  modalProps?: T
) => {
  const ref = React.useRef(null);
  const { showByRef } = useModals();

  const x = (modalProps ?? {}) as T;

  // const ModalWithRef = <Portal>{React.createElement(Modal, { ...x, innerRef: ref })}</Portal>;
  const ModalWithRef = (
    <Portal>
      <Modal {...x} innerRef={ref} />
    </Portal>
  );

  const showModalWithRef = React.useCallback(() => showByRef(ref, type), [showByRef, type]);

  return [ModalWithRef, showModalWithRef] as const;
};

export const useDynamicModal = <T extends ModalProps>(
  Modal: React.FunctionComponent<T>,
  type: 'modal' | 'offcanvas'
) => {
  const [innerProps, setInnerProps] = React.useState<T>();

  const ref = React.useRef(null);
  const { showByRef } = useModals();

  const ModalWithRef = <Portal>{innerProps && <Modal {...innerProps} innerRef={ref} />}</Portal>;

  const showModalWithRef = React.useCallback((props: T) => {
    setInnerProps(props);
  }, []);

  React.useEffect(() => {
    if (innerProps) {
      showByRef(ref, type);
    }
  }, [innerProps, showByRef, type]);

  return [ModalWithRef, showModalWithRef] as const;
};

// export const useModal = (props: PackedModalProps) => {
//   const { showModal: _showModal } = useModals();

//   const showModal = React.useCallback(() => {
//     _showModal(props);
//   }, [_showModal, props]);

//   return showModal;
// };

// export interface UseFormModalProps<FormProps> {
//   formProps?: FormProps;
//   modalProps?: Partial<PackedModalProps>;
// }

// // eslint-disable-next-line @typescript-eslint/naming-convention
// interface _UseFormModalProps<FormProps> {
//   Form: React.FunctionComponent<FormProps>;
//   formProps: FormProps;
//   modalProps: Omit<PackedModalProps, 'id'>;
// }

// // eslint-disable-next-line @typescript-eslint/ban-types
// export const useFormModal = <FormProps extends object>({
//   Form,
//   formProps,
//   modalProps,
// }: _UseFormModalProps<FormProps>) => {
//   const { showModal } = useModals();
//   return React.useCallback(() => {
//     showModal({
//       children: <Form {...formProps} />,
//       ...modalProps,
//     });
//   }, [Form, formProps, modalProps, showModal]);
// };
